var Vo = Object.defineProperty;
var Po = (e, n, i) => n in e ? Vo(e, n, { enumerable: !0, configurable: !0, writable: !0, value: i }) : e[n] = i;
var oi = (e, n, i) => (Po(e, typeof n != "symbol" ? n + "" : n, i), i);
import { createEvent as h, sample as w, createStore as A, createEffect as B, attach as nn, combine as _e, withFactory as Lo, split as Uo, restore as $o } from "effector";
import { ApolloError as ua, useQuery as Yn, useMutation as Mn } from "@apollo/client";
import { makeUniqueId as vi } from "@apollo/client/utilities";
import { hasData as Kn, initialized as an, failure as ka, success as be, maybeData as pe, remoteMap as Ye, RemoteDataStatus as va, fold as qt, pending as Bo } from "@dmitry.olyenyov/remote-data";
import { t as x } from "i18next";
import { getApolloClient as ne, RtcMediaAudioMode as $, Permission as Z, restartGraphqlConnection as Ho, RtcMediaAudio as Si, RtcMediaVideo as De, ChatEmbedType as yt, makeCallRoomLink as Xt, makeGravatarUri as gi, MeetMemberPresenceState as zo } from "@sign/shared/api";
import { isNotNull as Cn, formatBackendError as jo, makeStyleSheet as Wo, postpone as Go, throttle as qo, retryEffect as Xo, waitFor as Yt, makeThumbnails as Sa, formatUserName as tn, opaqueTimeToDate as Kt, formatAvatarShortName as pa, formatRoomIdAsText as Yo, createNoParamsInfiniteLoaderModel as Ko, formatMaybeEmptyTime as Jo, enumToList as Qo, formatMyUserName as Zo } from "@sign/shared/lib";
import { $myUserInfo as Jt, $anonymousNickname as Na, persistSES as es, $accessToken as ns, useMyInfoOld as hi, useMyInfo as is } from "@sign/shared/model";
import { configure as wn } from "effector-logger";
import { View as E, useTheme as me, useMediaQuery as as, useBreakpointValue as Tt, theme as ze, Heading as yn, Text as y, Image as bi, Box as mn, Tooltip as fa, Pressable as le, Progress as ts, Avatar as Fa, FlatList as ga, Center as yi, HStack as Qe, VStack as zn, CloseIcon as Qt, FormControl as He, ScrollView as ha, Button as ba, Spinner as jn, Switch as ds, TextArea as ls, Select as Bn, Popover as he, Radio as hn, Stack as Mt, Alert as Ct, IconButton as os, useClipboard as ss, useToast as rs, SectionList as cs } from "native-base";
import Zt, { memo as g, useCallback as _, useMemo as L, useState as G, useEffect as ee, useRef as je, forwardRef as ms } from "react";
import { jsx as d, jsxs as f, Fragment as ye } from "react/jsx-runtime";
import { CustomIconButton as Jn, useBreakpoint as kn, FullScreenMessage as ya, FormPasswordInput as us, SettingsItemRow as ks, ActionButton as We, Button32 as Ti, EmojiModal as vs, EMOJI_OFTEN_USED as ed, EmojiItem as Ss, FormTextInput as cn, FormDatePicker as wt, FormSwitch as qe, FormErrorMessage as ps, DisplayGraphQLError as Qn, CopyRoomNumber as nd, HAND_RAISED as Tn, MenuItem as un, CustomRTCView as Mi, Menu as Ns, SettingsContainer as fs, SettingsContainerTitle as Fs, FormSettingsRadio as gs, BigAvatarWithName as id } from "@sign/uikit";
import { IconPhone as hs, IconMicro as Ta, IconCameraRound as ad, IconCameraChange as bs, IconScreenShare as ys, IconFolder as Ts, IconCloseCircle as Ci, IconEdit as Ms, IconReply as Cs, IconChat as td, IconPlus as ws, IconClose as Es, IconRaisedHand as Ma, IconWifi as _s, IconMicroDisabled as dd, IconCamera as Rs, IconCameraDisabled as xs, IconChatWithSign as Ds, IconSmile as As, IconSettings as Is, IconUserGroup as Os, IconCameraRoundOff as Vs } from "@sign/uikit/icons";
import { useUnit as D } from "effector-react";
import { useWindowDimensions as En, View as ld, Pressable as od, NativeModules as Ps, Linking as sd, Platform as Ze, StyleSheet as _n, Modal as rd, ScrollView as Ls, Switch as Us } from "react-native";
import { zodResolver as Zn } from "@hookform/resolvers/zod";
import { useForm as Rn, Controller as de, useFieldArray as cd } from "react-hook-form";
import W, { z as H } from "zod";
import { mediaDevices as Oe, RTCPeerConnection as $s } from "react-native-webrtc";
import { useTranslation as te } from "react-i18next";
import * as Et from "@sentry/react";
import { captureException as Ca, captureMessage as Bs } from "@sentry/react";
import { UnreachableCaseError as K } from "ts-essentials";
import { interval as Hs } from "patronum";
import { parse as zs } from "@rocket.chat/message-parser";
import js, { AxiosError as Ws } from "axios";
import { IconPaperClipWeb as Gs, IconSmileWeb as qs, IconSendWeb as Xs, PortalContainer as Ys, IconCloseWeb as _t, IconCheckWeb as Ks } from "@sign/uikit/web";
import Ie from "classnames";
import { useDropzone as Js } from "react-dropzone";
import { isToday as Qs, isYesterday as Zs, format as Rt } from "date-fns";
import er, { Path as nr } from "react-native-svg";
import md from "@emoji-mart/data";
const Ov = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "EmbeddedChatMessage"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "channel_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "member_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "text"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "updated"
        }
      }]
    }
  }]
}, Vv = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "ChatMessageEmbedPreview"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageEmbedPreviewType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "audio"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "url"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "image"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "height"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "url"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "width"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "meta"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "description"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "site_name"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "title"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "video"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "height"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "url"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "width"
            }
          }]
        }
      }]
    }
  }]
}, Pv = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "ChatMessageEmbed"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageEmbedType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "file"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "author"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "filename"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "mime"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "size"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "updated"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "message"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "EmbeddedChatMessage"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "preview"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "ChatMessageEmbedPreview"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "type"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "value"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "EmbeddedChatMessage"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "channel_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "member_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "text"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "updated"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "ChatMessageEmbedPreview"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageEmbedPreviewType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "audio"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "url"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "image"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "height"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "url"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "width"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "meta"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "description"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "site_name"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "title"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "video"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "height"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "url"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "width"
            }
          }]
        }
      }]
    }
  }]
}, Lv = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetEventChatMessageEdit"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageEditType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "channel"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "embeds"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "ChatMessageEmbed"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "space"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "text"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "updated"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "EmbeddedChatMessage"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "channel_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "member_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "text"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "updated"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "ChatMessageEmbedPreview"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageEmbedPreviewType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "audio"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "url"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "image"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "height"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "url"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "width"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "meta"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "description"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "site_name"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "title"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "video"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "height"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "url"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "width"
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "ChatMessageEmbed"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageEmbedType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "file"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "author"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "filename"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "mime"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "size"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "updated"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "message"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "EmbeddedChatMessage"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "preview"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "ChatMessageEmbedPreview"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "type"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "value"
        }
      }]
    }
  }]
}, Uv = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "ChatMessage"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "channel_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "member_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "embeds"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "ChatMessageEmbed"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "reactions"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "count"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "me"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "name"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "space_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "text"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "updated"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "EmbeddedChatMessage"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "channel_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "member_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "text"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "updated"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "ChatMessageEmbedPreview"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageEmbedPreviewType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "audio"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "url"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "image"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "height"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "url"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "width"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "meta"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "description"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "site_name"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "title"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "video"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "height"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "url"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "width"
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "ChatMessageEmbed"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageEmbedType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "file"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "author"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "filename"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "mime"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "size"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "updated"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "message"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "EmbeddedChatMessage"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "preview"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "ChatMessageEmbedPreview"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "type"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "value"
        }
      }]
    }
  }]
}, $v = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetEventChat"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "EventChatType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "edit"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "messages"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "MeetEventChatMessageEdit"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "sent"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "messages"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "ChatMessage"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "remove"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "messages"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "reaction"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "count"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "messages"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "channel"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "id"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "reactions"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "count"
                        }
                      }, {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "name"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "self"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "messages"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "channel"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "id"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "reactions"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "space"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "EmbeddedChatMessage"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "channel_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "member_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "text"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "updated"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "ChatMessageEmbedPreview"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageEmbedPreviewType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "audio"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "url"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "image"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "height"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "url"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "width"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "meta"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "description"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "site_name"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "title"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "video"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "height"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "url"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "width"
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "ChatMessageEmbed"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageEmbedType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "file"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "author"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "filename"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "mime"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "size"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "updated"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "message"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "EmbeddedChatMessage"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "preview"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "ChatMessageEmbedPreview"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "type"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "value"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetEventChatMessageEdit"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageEditType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "channel"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "embeds"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "ChatMessageEmbed"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "space"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "text"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "updated"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "ChatMessage"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "channel_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "member_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "embeds"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "ChatMessageEmbed"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "reactions"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "count"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "me"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "name"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "space_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "text"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "updated"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }]
    }
  }]
}, ir = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "query",
    name: {
      kind: "Name",
      value: "RequestChatHistory"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "cursor"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "limit"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "Int"
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "chat"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "history"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "cursor"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "cursor"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "limit"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "limit"
                    }
                  }
                }],
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "cursor"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "data"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "ChatMessage"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "EmbeddedChatMessage"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "channel_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "member_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "text"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "updated"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "ChatMessageEmbedPreview"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageEmbedPreviewType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "audio"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "url"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "image"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "height"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "url"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "width"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "meta"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "description"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "site_name"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "title"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "video"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "height"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "url"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "width"
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "ChatMessageEmbed"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageEmbedType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "file"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "author"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "filename"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "mime"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "size"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "updated"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "message"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "EmbeddedChatMessage"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "preview"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "ChatMessageEmbedPreview"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "type"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "value"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "ChatMessage"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "channel_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "member_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "embeds"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "ChatMessageEmbed"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "reactions"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "count"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "me"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "name"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "space_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "text"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "updated"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }]
    }
  }]
}, ar = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "subscription",
    name: {
      kind: "Name",
      value: "Meet"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "phone"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "nickname"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "space"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "ID"
          }
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "password"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "email"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        arguments: [{
          kind: "Argument",
          name: {
            kind: "Name",
            value: "space"
          },
          value: {
            kind: "Variable",
            name: {
              kind: "Name",
              value: "space"
            }
          }
        }, {
          kind: "Argument",
          name: {
            kind: "Name",
            value: "email"
          },
          value: {
            kind: "Variable",
            name: {
              kind: "Name",
              value: "email"
            }
          }
        }, {
          kind: "Argument",
          name: {
            kind: "Name",
            value: "nickname"
          },
          value: {
            kind: "Variable",
            name: {
              kind: "Name",
              value: "nickname"
            }
          }
        }, {
          kind: "Argument",
          name: {
            kind: "Name",
            value: "password"
          },
          value: {
            kind: "Variable",
            name: {
              kind: "Name",
              value: "password"
            }
          }
        }, {
          kind: "Argument",
          name: {
            kind: "Name",
            value: "phone"
          },
          value: {
            kind: "Variable",
            name: {
              kind: "Name",
              value: "phone"
            }
          }
        }],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "chat"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "MeetEventChat"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "member"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "MeetEventMember"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "rtc"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "MeetEventRtc"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "self"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "MeetEventSelf"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "space"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "MeeetEventSpace"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "user"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "MeetEventUser"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "vote"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "MeetEventVote"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "EmbeddedChatMessage"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "channel_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "member_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "text"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "updated"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "ChatMessageEmbedPreview"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageEmbedPreviewType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "audio"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "url"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "image"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "height"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "url"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "width"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "meta"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "description"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "site_name"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "title"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "video"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "height"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "url"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "width"
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "ChatMessageEmbed"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageEmbedType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "file"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "author"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "filename"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "mime"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "size"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "updated"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "message"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "EmbeddedChatMessage"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "preview"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "ChatMessageEmbedPreview"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "type"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "value"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetEventChatMessageEdit"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageEditType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "channel"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "embeds"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "ChatMessageEmbed"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "space"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "text"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "updated"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "ChatMessage"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "channel_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "member_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "embeds"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "ChatMessageEmbed"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "reactions"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "count"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "me"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "name"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "space_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "text"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "updated"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "User"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "UserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "attributes"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "settings"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatar"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "premium"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetMember"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetMemberType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions_deny"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions_grant"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "presence_state"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "RTCMember"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "RTCMemberType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "media"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "audio"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "audio_mode"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "video"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "reaction"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "emotion"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "gesture"
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "IceServers"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "RTCICEServerType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "credential"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "urls"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpaceConditionsContacts"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceConditionsContactsType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "password"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpace"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "description"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "owner"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "rtc_shard"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "schedule_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "started"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "state"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "terminated"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "title"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "transient"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_audio"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_video"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "vanity_name"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "conditions"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "draw"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "anyone"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "authenticated"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "join"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "link"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "recording"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "enabled"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "default"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "start"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "always"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "terminate"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "empty"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "manual"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "vote"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "cast"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "anyone"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "start"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetMemberSpace"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetMemberSpaceType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "connected_since"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions_deny"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "presence_state"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions_grant"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "space"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "MeetSpace"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetVote"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "DataVoteType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "cases"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "count"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "me"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "name"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "conditions"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "terminate"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "completion"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "majority"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "multiple"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "title"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "terminated"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "member"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetEventChat"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "EventChatType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "edit"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "messages"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "MeetEventChatMessageEdit"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "sent"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "messages"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "ChatMessage"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "remove"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "messages"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "reaction"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "count"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "messages"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "channel"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "id"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "reactions"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "count"
                        }
                      }, {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "name"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "self"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "messages"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "channel"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "id"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "reactions"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "space"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetEventMember"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "EventMeetMemberType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "add"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "members"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "MeetMember"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "remove"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "members"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "id"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "source"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "ban"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "reason"
                        }
                      }]
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "conflict"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "device_id"
                        }
                      }, {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "user_id"
                        }
                      }]
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "disconnect"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "kick"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "reason"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "data"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "members"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "MeetMember"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "change"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "members"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "id"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "connected_since"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "permissions_deny"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "permissions_grant"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "nickname"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "presence_state"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetEventRtc"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "EventRTCType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "draw"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "actor"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "primitives"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "line"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "color"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "points"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "thickness"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "target"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "member"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "add"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "RTCMember"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "change"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "RTCMember"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "remove"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "data"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "RTCMember"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "ice"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "candidates"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "init"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "codecs"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "ice_servers"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "IceServers"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "midi"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "actor"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "events"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "note"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "on"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "velocity"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "sdp"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "answer"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "offer"
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetEventSelf"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "EventMeetSelfType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "init"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "membership"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "MeetMemberSpace"
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeeetEventSpace"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "EventMeetSpaceType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "add"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "spaces"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "MeetSpace"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "remove"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "space_ids"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "change"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "spaces"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "MeetSpace"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetEventUser"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "EventMeetUserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "change"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "users"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "User"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetEventVote"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "EventVoteType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "tally"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "count"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "votes"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "cases"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "channel"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "id"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "space"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "self"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "votes"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "cases"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "channel"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "id"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "space"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "terminate"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "channel"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "space"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "start"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "MeetVote"
            }
          }]
        }
      }]
    }
  }]
}, Bv = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetMember"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetMemberType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions_deny"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions_grant"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "presence_state"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "User"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "UserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "attributes"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "settings"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatar"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "premium"
        }
      }]
    }
  }]
}, Hv = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetEventMember"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "EventMeetMemberType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "add"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "members"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "MeetMember"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "remove"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "members"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "id"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "source"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "ban"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "reason"
                        }
                      }]
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "conflict"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "device_id"
                        }
                      }, {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "user_id"
                        }
                      }]
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "disconnect"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "kick"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "reason"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "data"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "members"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "MeetMember"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "change"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "members"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "id"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "connected_since"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "permissions_deny"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "permissions_grant"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "nickname"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "presence_state"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "User"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "UserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "attributes"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "settings"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatar"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "premium"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetMember"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetMemberType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions_deny"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions_grant"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "presence_state"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }]
    }
  }]
}, tr = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "MeetSpaceMemberControl"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "id"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "ID"
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "nickname"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "permissions_deny"
        }
      },
      type: {
        kind: "ListType",
        type: {
          kind: "NonNullType",
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Permission"
            }
          }
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "permissions_grant"
        }
      },
      type: {
        kind: "ListType",
        type: {
          kind: "NonNullType",
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Permission"
            }
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "member"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "control"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "id"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "id"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "nickname"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "nickname"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "permissions_deny"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "permissions_deny"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "permissions_grant"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "permissions_grant"
                    }
                  }
                }]
              }]
            }
          }]
        }
      }]
    }
  }]
}, zv = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "RTCMember"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "RTCMemberType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "media"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "audio"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "audio_mode"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "video"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "reaction"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "emotion"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "gesture"
            }
          }]
        }
      }]
    }
  }]
}, jv = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "IceServers"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "RTCICEServerType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "credential"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "urls"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }]
    }
  }]
}, Wv = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetEventRtc"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "EventRTCType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "draw"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "actor"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "primitives"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "line"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "color"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "points"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "thickness"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "target"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "member"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "add"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "RTCMember"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "change"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "RTCMember"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "remove"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "data"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "RTCMember"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "ice"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "candidates"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "init"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "codecs"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "ice_servers"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "IceServers"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "midi"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "actor"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "events"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "note"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "on"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "velocity"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "sdp"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "answer"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "offer"
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "RTCMember"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "RTCMemberType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "media"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "audio"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "audio_mode"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "video"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "reaction"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "emotion"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "gesture"
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "IceServers"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "RTCICEServerType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "credential"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "urls"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }]
    }
  }]
}, dr = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "MeetUpdateRtcReaction"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "emotion"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "gesture"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "rtc"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "reaction"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "emotion"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "emotion"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "gesture"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "gesture"
                    }
                  }
                }]
              }]
            }
          }]
        }
      }]
    }
  }]
}, Gv = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetMemberSpace"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetMemberSpaceType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "connected_since"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions_deny"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "presence_state"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions_grant"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "space"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "MeetSpace"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "User"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "UserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "attributes"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "settings"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatar"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "premium"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpaceConditionsContacts"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceConditionsContactsType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "password"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpace"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "description"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "owner"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "rtc_shard"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "schedule_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "started"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "state"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "terminated"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "title"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "transient"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_audio"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_video"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "vanity_name"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "conditions"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "draw"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "anyone"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "authenticated"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "join"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "link"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "recording"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "enabled"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "default"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "start"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "always"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "terminate"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "empty"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "manual"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "vote"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "cast"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "anyone"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "start"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
}, qv = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetEventSelf"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "EventMeetSelfType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "init"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "membership"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "MeetMemberSpace"
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "User"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "UserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "attributes"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "settings"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatar"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "premium"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpaceConditionsContacts"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceConditionsContactsType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "password"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpace"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "description"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "owner"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "rtc_shard"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "schedule_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "started"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "state"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "terminated"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "title"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "transient"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_audio"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_video"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "vanity_name"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "conditions"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "draw"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "anyone"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "authenticated"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "join"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "link"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "recording"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "enabled"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "default"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "start"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "always"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "terminate"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "empty"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "manual"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "vote"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "cast"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "anyone"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "start"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetMemberSpace"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetMemberSpaceType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "connected_since"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions_deny"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "presence_state"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions_grant"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "space"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "MeetSpace"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }]
    }
  }]
}, Xv = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpaceConditionsContacts"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceConditionsContactsType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "password"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }]
    }
  }]
}, Yv = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpace"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "description"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "owner"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "rtc_shard"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "schedule_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "started"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "state"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "terminated"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "title"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "transient"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_audio"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_video"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "vanity_name"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "conditions"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "draw"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "anyone"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "authenticated"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "join"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "link"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "recording"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "enabled"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "default"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "start"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "always"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "terminate"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "empty"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "manual"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "vote"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "cast"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "anyone"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "start"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "User"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "UserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "attributes"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "settings"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatar"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "premium"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpaceConditionsContacts"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceConditionsContactsType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "password"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }]
    }
  }]
}, Kv = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeeetEventSpace"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "EventMeetSpaceType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "add"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "spaces"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "MeetSpace"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "remove"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "space_ids"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "change"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "spaces"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "MeetSpace"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "User"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "UserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "attributes"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "settings"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatar"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "premium"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpaceConditionsContacts"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceConditionsContactsType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "password"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpace"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "description"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "owner"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "rtc_shard"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "schedule_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "started"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "state"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "terminated"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "title"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "transient"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_audio"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_video"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "vanity_name"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "conditions"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "draw"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "anyone"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "authenticated"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "join"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "link"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "recording"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "enabled"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "default"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "start"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "always"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "terminate"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "empty"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "manual"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "vote"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "cast"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "anyone"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "start"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
}, Jv = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "VanityName"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "VanityNameType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "name"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "owner"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "space_id"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "User"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "UserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "attributes"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "settings"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatar"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "premium"
        }
      }]
    }
  }]
}, ud = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "query",
    name: {
      kind: "Name",
      value: "MeetMySpacesList"
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "user"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "meet"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "list"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "spaces"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "joined"
                        }
                      }, {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "space"
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{
                            kind: "FragmentSpread",
                            name: {
                              kind: "Name",
                              value: "MeetSpace"
                            }
                          }]
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "User"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "UserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "attributes"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "settings"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatar"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "premium"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpaceConditionsContacts"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceConditionsContactsType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "password"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpace"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "description"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "owner"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "rtc_shard"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "schedule_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "started"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "state"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "terminated"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "title"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "transient"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_audio"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_video"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "vanity_name"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "conditions"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "draw"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "anyone"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "authenticated"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "join"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "link"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "recording"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "enabled"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "default"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "start"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "always"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "terminate"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "empty"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "manual"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "vote"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "cast"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "anyone"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "start"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
}, lr = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "query",
    name: {
      kind: "Name",
      value: "MyMeetCalendar"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "states"
        }
      },
      type: {
        kind: "ListType",
        type: {
          kind: "NonNullType",
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "MeetSpaceState"
            }
          }
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "start"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "Time"
          }
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "end"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "Time"
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "user"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "meet"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "list"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "calendar"
                    },
                    arguments: [{
                      kind: "Argument",
                      name: {
                        kind: "Name",
                        value: "start"
                      },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "start"
                        }
                      }
                    }, {
                      kind: "Argument",
                      name: {
                        kind: "Name",
                        value: "end"
                      },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "end"
                        }
                      }
                    }, {
                      kind: "Argument",
                      name: {
                        kind: "Name",
                        value: "states"
                      },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "states"
                        }
                      }
                    }],
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpace"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "User"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "UserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "attributes"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "settings"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatar"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "premium"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpaceConditionsContacts"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceConditionsContactsType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "password"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpace"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "description"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "owner"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "rtc_shard"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "schedule_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "started"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "state"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "terminated"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "title"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "transient"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_audio"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_video"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "vanity_name"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "conditions"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "draw"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "anyone"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "authenticated"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "join"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "link"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "recording"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "enabled"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "default"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "start"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "always"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "terminate"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "empty"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "manual"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "vote"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "cast"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "anyone"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "start"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
}, kd = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "query",
    name: {
      kind: "Name",
      value: "MeetSpaceByIdOrVanityName"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "vanity_name"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "id"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "ID"
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "user"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "meet"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "get"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "space"
                    },
                    arguments: [{
                      kind: "Argument",
                      name: {
                        kind: "Name",
                        value: "id"
                      },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "id"
                        }
                      }
                    }, {
                      kind: "Argument",
                      name: {
                        kind: "Name",
                        value: "vanity_name"
                      },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "vanity_name"
                        }
                      }
                    }],
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpace"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "User"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "UserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "attributes"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "settings"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatar"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "premium"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpaceConditionsContacts"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceConditionsContactsType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "password"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpace"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "description"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "owner"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "rtc_shard"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "schedule_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "started"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "state"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "terminated"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "title"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "transient"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_audio"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_video"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "vanity_name"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "conditions"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "draw"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "anyone"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "authenticated"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "join"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "link"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "recording"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "enabled"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "default"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "start"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "always"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "terminate"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "empty"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "manual"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "vote"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "cast"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "anyone"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "start"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
}, vd = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "MeetSpaceSave"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "vanity_name"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "conditions"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "MeetSpaceConditionsInput"
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "permissions"
        }
      },
      type: {
        kind: "ListType",
        type: {
          kind: "NonNullType",
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Permission"
            }
          }
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "id"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "ID"
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "title"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "description"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "space"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "save"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "vanity_name"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "vanity_name"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "conditions"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "conditions"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "permissions"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "permissions"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "id"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "id"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "title"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "title"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "description"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "description"
                    }
                  }
                }],
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpace"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "User"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "UserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "attributes"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "settings"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatar"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "premium"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpaceConditionsContacts"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceConditionsContactsType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "password"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpace"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "description"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "owner"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "rtc_shard"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "schedule_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "started"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "state"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "terminated"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "title"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "transient"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_audio"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_video"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "vanity_name"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "conditions"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "draw"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "anyone"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "authenticated"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "join"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "link"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "recording"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "enabled"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "default"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "start"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "always"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "terminate"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "empty"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "manual"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "vote"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "cast"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "anyone"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "start"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
}, Qv = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "MeetSpaceRemove"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "id"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "ID"
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "space"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "remove"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "id"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "id"
                    }
                  }
                }]
              }]
            }
          }]
        }
      }]
    }
  }]
}, Zv = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "MeetSpaceLeave"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "id"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "ID"
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "space"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "leave"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "id"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "id"
                    }
                  }
                }]
              }]
            }
          }]
        }
      }]
    }
  }]
}, or = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "MeetSpaceTerminate"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "id"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "ID"
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "space"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "terminate"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "id"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "id"
                    }
                  }
                }]
              }]
            }
          }]
        }
      }]
    }
  }]
}, eS = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetEventUser"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "EventMeetUserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "change"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "users"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "User"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "User"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "UserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "attributes"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "settings"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatar"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "premium"
        }
      }]
    }
  }]
}, wi = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "query",
    name: {
      kind: "Name",
      value: "FetchMemberById"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "id"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "ID"
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "member"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "get"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "id"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "id"
                    }
                  }
                }],
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetMember"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "User"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "UserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "attributes"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "settings"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatar"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "premium"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetMember"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetMemberType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions_deny"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions_grant"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "presence_state"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }]
    }
  }]
}, nS = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetVote"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "DataVoteType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "cases"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "count"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "me"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "name"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "conditions"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "terminate"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "completion"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "majority"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "multiple"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "title"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "terminated"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "member"
        }
      }]
    }
  }]
}, iS = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetEventVote"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "EventVoteType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "tally"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "count"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "votes"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "cases"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "channel"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "id"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "space"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "self"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "votes"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "cases"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "channel"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "id"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "space"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "terminate"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "channel"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "space"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "start"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "MeetVote"
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetVote"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "DataVoteType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "cases"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "count"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "me"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "name"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "conditions"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "terminate"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "completion"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "majority"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "multiple"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "title"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "terminated"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "member"
        }
      }]
    }
  }]
}, sr = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "query",
    name: {
      kind: "Name",
      value: "MeetFetchVotes"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "limit"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "Int"
          }
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "cursor"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "vote"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "list"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "cursor"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "cursor"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "limit"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "limit"
                    }
                  }
                }],
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "cursor"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "data"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetVote"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetVote"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "DataVoteType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "cases"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "count"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "me"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "name"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "conditions"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "terminate"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "completion"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "majority"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "multiple"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "title"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "terminated"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "member"
        }
      }]
    }
  }]
}, Sd = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "MeetVoteStart"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "title"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "String"
          }
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "cases"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "ListType",
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: {
                kind: "Name",
                value: "String"
              }
            }
          }
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "multiple"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "Boolean"
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "conditions"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "MeetVoteConditionsInput"
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "vote"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "start"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "cases"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "cases"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "conditions"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "conditions"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "multiple"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "multiple"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "title"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "title"
                    }
                  }
                }]
              }]
            }
          }]
        }
      }]
    }
  }]
}, rr = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "MeetVoteCast"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "id"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "ID"
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "cases"
        }
      },
      type: {
        kind: "ListType",
        type: {
          kind: "NonNullType",
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Int"
            }
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "vote"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "cast"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "id"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "id"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "cases"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "cases"
                    }
                  }
                }]
              }]
            }
          }]
        }
      }]
    }
  }]
}, aS = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "MeetVoteTerminate"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "id"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "ID"
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "vote"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "terminate"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "id"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "id"
                    }
                  }
                }]
              }]
            }
          }]
        }
      }]
    }
  }]
};
var j = "/src/entities/meet/model/common.ts";
let Ke = /* @__PURE__ */ function(e) {
  return e.Ban = "Ban", e.DisconnectedFromBackend = "DisconnectedFromBackend", e.Error = "Error", e.Kick = "Kick", e.NormalHangup = "NormalHangup", e;
}({});
const ke = h({
  loc: {
    file: j,
    line: 39,
    column: 43
  },
  name: "eventMeetSpaceJoinRequested",
  sid: "8bd000:/src/entities/meet/model/common.ts:eventMeetSpaceJoinRequested"
}), pd = h({
  loc: {
    file: j,
    line: 42,
    column: 41
  },
  name: "eventMeetSpaceRestoreCall",
  sid: "6xa24y:/src/entities/meet/model/common.ts:eventMeetSpaceRestoreCall"
}), tS = h({
  loc: {
    file: j,
    line: 43,
    column: 39
  },
  name: "eventMeetSpaceTerminate",
  sid: "wdgptp:/src/entities/meet/model/common.ts:eventMeetSpaceTerminate"
}), wa = h({
  loc: {
    file: j,
    line: 44,
    column: 48
  },
  name: "eventMeetSpaceJoinedSuccessfully",
  sid: "-yq7rop:/src/entities/meet/model/common.ts:eventMeetSpaceJoinedSuccessfully"
}), pi = h({
  loc: {
    file: j,
    line: 45,
    column: 35
  },
  name: "eventMeetSpaceLeave",
  sid: "gasx91:/src/entities/meet/model/common.ts:eventMeetSpaceLeave"
}), fe = h({
  loc: {
    file: j,
    line: 46,
    column: 37
  },
  name: "eventMeetSpaceCleanup",
  sid: "wk55nj:/src/entities/meet/model/common.ts:eventMeetSpaceCleanup"
}), Nd = h({
  loc: {
    file: j,
    line: 48,
    column: 42
  },
  name: "eventCallRatedSuccessfully",
  sid: "oa6eif:/src/entities/meet/model/common.ts:eventCallRatedSuccessfully"
});
w({
  and: [{
    clock: Nd,
    target: fe
  }],
  or: {
    loc: {
      file: j,
      line: 50,
      column: 0
    },
    sid: "w9kh27:/src/entities/meet/model/common.ts:"
  }
});
const cr = A(!1, {
  loc: {
    file: j,
    line: 52,
    column: 35
  },
  name: "$showRateACallModal",
  sid: "1pu0wm:/src/entities/meet/model/common.ts:$showRateACallModal"
}).reset(ke, fe), fd = h({
  loc: {
    file: j,
    line: 57,
    column: 42
  },
  name: "eventMeetSpaceExitFinished",
  sid: "-9qo6n9:/src/entities/meet/model/common.ts:eventMeetSpaceExitFinished"
}), Ea = h({
  loc: {
    file: j,
    line: 59,
    column: 48
  },
  name: "eventGraphqlSubscriptionComplete",
  sid: "iaspz7:/src/entities/meet/model/common.ts:eventGraphqlSubscriptionComplete"
}), mr = h({
  loc: {
    file: j,
    line: 60,
    column: 47
  },
  name: "eventGraphqlSubscriptionStarted",
  sid: "-b6pvia:/src/entities/meet/model/common.ts:eventGraphqlSubscriptionStarted"
}), ur = h({
  loc: {
    file: j,
    line: 62,
    column: 35
  },
  name: "eventControlsToggle",
  sid: "js0l9o:/src/entities/meet/model/common.ts:eventControlsToggle"
}), dS = A(!0, {
  loc: {
    file: j,
    line: 63,
    column: 31
  },
  name: "$controlsOpened",
  sid: "xf7scu:/src/entities/meet/model/common.ts:$controlsOpened"
}).on(ur, (e) => !e), lS = h({
  loc: {
    file: j,
    line: 68,
    column: 39
  },
  name: "eventCallViewFullToggle",
  sid: "dq3bsu:/src/entities/meet/model/common.ts:eventCallViewFullToggle"
}), oS = A(!0, {
  and: {
    name: "$loading"
  },
  loc: {
    file: j,
    line: 69,
    column: 24
  },
  name: "$loading",
  sid: "-uzd8o7:/src/entities/meet/model/common.ts:$loading"
}), kr = h({
  loc: {
    file: j,
    line: 70,
    column: 26
  },
  name: "eventError",
  sid: "-kudjyh:/src/entities/meet/model/common.ts:eventError"
}), Ei = A([], {
  and: {},
  loc: {
    file: j,
    line: 82,
    column: 28
  },
  name: "$fatalErrors",
  sid: "-h07iy7:/src/entities/meet/model/common.ts:$fatalErrors"
}), vr = (e) => {
  Ei.on(e.failData, (n, i) => [...n, i instanceof Error ? {
    error: i,
    type: "exception"
  } : i]);
};
Ei.on(kr, (e, n) => e.concat(Array.isArray(n) ? n.map((i) => ({
  error: i,
  type: "exception"
})) : [{
  error: n,
  type: "exception"
}])).reset(fe, ke);
Ei.watch((e) => {
  for (const n of e)
    console.error(n);
});
const Fd = h({
  loc: {
    file: j,
    line: 126,
    column: 30
  },
  name: "eventHideAlert",
  sid: "bkcgje:/src/entities/meet/model/common.ts:eventHideAlert"
}), _a = h({
  loc: {
    file: j,
    line: 128,
    column: 29
  },
  name: "eventNewAlert",
  sid: "ganipk:/src/entities/meet/model/common.ts:eventNewAlert"
}), Ra = A([], {
  loc: {
    file: j,
    line: 130,
    column: 23
  },
  name: "$alerts",
  sid: "-orgxmm:/src/entities/meet/model/common.ts:$alerts"
}).reset(fe, ke);
Ra.on(Fd, (e, n) => e.filter((i) => i.id !== n)).on(_a, (e, n) => [...e, {
  id: vi("alert"),
  ...n
}]);
const _i = B(async ({
  spaceIdOrVanityName: e
}) => {
  if (/^\d+$/.test(e))
    return e;
  try {
    return (await ne().query({
      query: kd,
      variables: {
        vanity_name: e
      }
    })).data.user.meet.get.space.id;
  } catch (n) {
    throw n instanceof ua && n.message === "sql: no rows in result set" ? {
      message: x("BACKEND_ERRORS.MEETSPACE.TERMINATED_MESSAGE"),
      title: x("BACKEND_ERRORS.MEETSPACE.TERMINATED_TITLE"),
      type: "custom"
    } : n;
  }
}, {
  loc: {
    file: j,
    line: 146,
    column: 48
  },
  name: "fetchMeetSpaceByIdOrVanityNameFx",
  sid: "-opqop9:/src/entities/meet/model/common.ts:fetchMeetSpaceByIdOrVanityNameFx"
});
w({
  and: [{
    clock: ke,
    target: _i
  }],
  or: {
    loc: {
      file: j,
      line: 173,
      column: 0
    },
    sid: "ljy27f:/src/entities/meet/model/common.ts:"
  }
});
vr(_i);
const xn = A(null, {
  loc: {
    file: j,
    line: 179,
    column: 31
  },
  name: "$currentSpaceId",
  sid: "-z465m9:/src/entities/meet/model/common.ts:$currentSpaceId"
}).on(_i.doneData, (e, n) => n).reset(fd), Sr = Jt.map((e) => Kn(e) ? e.data.type === "authenticated" ? e.data.user.id : e.data.type : null);
w({
  and: [{
    clock: [pd, Sr],
    source: xn,
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    filter: Cn,
    fn: (e) => ({
      spaceIdOrVanityName: e
    }),
    target: ke
  }],
  or: {
    loc: {
      file: j,
      line: 191,
      column: 0
    },
    sid: "mgxpt3:/src/entities/meet/model/common.ts:"
  }
});
const pr = A(null, {
  and: {
    name: "$roomExit"
  },
  loc: {
    file: j,
    line: 200,
    column: 31
  },
  name: "$roomExitReason",
  sid: "1ehgu3:/src/entities/meet/model/common.ts:$roomExitReason"
}).reset(ke, fd).on(Ea, () => ({
  message: x("ROOM.EXIT_REASON.GENERIC_DISCONNECT"),
  type: Ke.DisconnectedFromBackend
})), sS = nn({
  and: {
    effect: B(async ({
      id: e
    }) => {
      e != null && await ne().mutate({
        mutation: or,
        variables: {
          id: e
        }
      });
    }, {
      loc: {
        file: j,
        line: 210,
        column: 10
      },
      name: "effect",
      sid: "-u716ad:/src/entities/meet/model/common.ts:effect"
    }),
    mapParams(e, n) {
      return {
        id: n
      };
    },
    source: xn
  },
  or: {
    loc: {
      file: j,
      line: 209,
      column: 36
    },
    name: "terminateMeetSpaceFx",
    sid: "-fi5eoy:/src/entities/meet/model/common.ts:terminateMeetSpaceFx"
  }
});
var Dn = "/src/entities/meet/model/aside-panel.ts";
const Nr = h({
  loc: {
    file: Dn,
    line: 6,
    column: 37
  },
  name: "eventAsidePanelClosed",
  sid: "-l9rzsc:/src/entities/meet/model/aside-panel.ts:eventAsidePanelClosed"
}), gd = h({
  loc: {
    file: Dn,
    line: 7,
    column: 36
  },
  name: "eventToggleUsersList",
  sid: "-g9ub9i:/src/entities/meet/model/aside-panel.ts:eventToggleUsersList"
}), ei = h({
  loc: {
    file: Dn,
    line: 8,
    column: 31
  },
  name: "eventToggleChat",
  sid: "cs34j0:/src/entities/meet/model/aside-panel.ts:eventToggleChat"
}), hd = h({
  loc: {
    file: Dn,
    line: 9,
    column: 32
  },
  name: "eventToggleVotes",
  sid: "mdc7jz:/src/entities/meet/model/aside-panel.ts:eventToggleVotes"
}), bd = h({
  loc: {
    file: Dn,
    line: 10,
    column: 35
  },
  name: "eventToggleSettings",
  sid: "-xdgwd8:/src/entities/meet/model/aside-panel.ts:eventToggleSettings"
});
let ce = /* @__PURE__ */ function(e) {
  return e[e.UserList = 0] = "UserList", e[e.Chat = 1] = "Chat", e[e.Votes = 2] = "Votes", e[e.Settings = 3] = "Settings", e;
}({});
const An = A(null, {
  loc: {
    file: Dn,
    line: 19,
    column: 27
  },
  name: "$asidePanel",
  sid: "-em4quf:/src/entities/meet/model/aside-panel.ts:$asidePanel"
}).reset(Nr, fe).on(ei, (e) => e === ce.Chat ? null : ce.Chat).on(gd, (e) => e === ce.UserList ? null : ce.UserList).on(hd, (e) => e === ce.Votes ? null : ce.Votes).on(bd, (e) => e === ce.Settings ? null : ce.Settings);
function Ni(e, n) {
  if (e === null || n === null || e === void 0 || n === void 0)
    return e === n;
  if (typeof e != typeof n)
    return !1;
  if (Object.is(e, n))
    return !0;
  if (typeof e == "object") {
    if (e.constructor !== n.constructor)
      return !1;
    if (Array.isArray(e)) {
      if (e.length !== n.length)
        return !1;
      for (let a = 0; a < e.length; ++a)
        if (!Ni(e[a], n[a]))
          return !1;
      return !0;
    }
    if (e.constructor === RegExp)
      return e.source === n.source && e.flags === n.flags;
    if (e.valueOf !== Object.prototype.valueOf)
      return e.valueOf() === n.valueOf();
    if (e.toString !== Object.prototype.toString)
      return e.toString() === n.toString();
    const i = Object.keys(e);
    if (i.length !== Object.keys(n).length)
      return !1;
    for (let a = 0; a < i.length; ++a)
      if (!Object.prototype.hasOwnProperty.call(n, i[a]))
        return !1;
    for (let a = 0; a < i.length; ++a) {
      const t = i[a];
      if (!(t === "_owner" && e.$$typeof) && !Ni(e[t], n[t]))
        return !1;
    }
    return !0;
  }
  return !1;
}
var yd = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {};
function Td(e) {
  return e && e.__esModule && Object.prototype.hasOwnProperty.call(e, "default") ? e.default : e;
}
var Zi = { exports: {} };
(function(e, n) {
  (function(i, a) {
    a(n);
  })(yd, function(i) {
    function a(r) {
      return r != null && typeof r == "object" && r["@@functional/placeholder"] === !0;
    }
    function t(r) {
      return function p(m) {
        return arguments.length === 0 || a(m) ? p : r.apply(this, arguments);
      };
    }
    function l(r) {
      return function p(m, F) {
        switch (arguments.length) {
          case 0:
            return p;
          case 1:
            return a(m) ? p : t(function(S) {
              return r(m, S);
            });
          default:
            return a(m) && a(F) ? p : a(m) ? t(function(S) {
              return r(S, F);
            }) : a(F) ? t(function(S) {
              return r(m, S);
            }) : r(m, F);
        }
      };
    }
    function o(r) {
      return function p(m, F, S) {
        switch (arguments.length) {
          case 0:
            return p;
          case 1:
            return a(m) ? p : l(function(N, T) {
              return r(m, N, T);
            });
          case 2:
            return a(m) && a(F) ? p : a(m) ? l(function(N, T) {
              return r(N, F, T);
            }) : a(F) ? l(function(N, T) {
              return r(m, N, T);
            }) : t(function(N) {
              return r(m, F, N);
            });
          default:
            return a(m) && a(F) && a(S) ? p : a(m) && a(F) ? l(function(N, T) {
              return r(N, T, S);
            }) : a(m) && a(S) ? l(function(N, T) {
              return r(N, F, T);
            }) : a(F) && a(S) ? l(function(N, T) {
              return r(m, N, T);
            }) : a(m) ? t(function(N) {
              return r(N, F, S);
            }) : a(F) ? t(function(N) {
              return r(m, N, S);
            }) : a(S) ? t(function(N) {
              return r(m, F, N);
            }) : r(m, F, S);
        }
      };
    }
    function s(r) {
      return Object.prototype.toString.call(r) === "[object Object]";
    }
    function c(r, p) {
      return Object.prototype.hasOwnProperty.call(p, r);
    }
    var k = o(function(p, m, F) {
      var S = {}, N;
      for (N in m)
        c(N, m) && (S[N] = c(N, F) ? p(N, m[N], F[N]) : m[N]);
      for (N in F)
        c(N, F) && !c(N, S) && (S[N] = F[N]);
      return S;
    }), u = o(function r(p, m, F) {
      return k(function(S, N, T) {
        return s(N) && s(T) ? r(p, N, T) : p(S, N, T);
      }, m, F);
    }), v = o(function(p, m, F) {
      return u(function(S, N, T) {
        return p(N, T);
      }, m, F);
    });
    i.mergeDeepWith = v, Object.defineProperty(i, "__esModule", { value: !0 });
  });
})(Zi, Zi.exports);
var fr = Zi.exports, Fr = fr.mergeDeepWith;
const Md = /* @__PURE__ */ Td(Fr), gr = (e) => {
  var i;
  if (e == null)
    return "event";
  const n = (i = e.conditions) == null ? void 0 : i.terminate;
  return !(n != null && n.empty) && (n == null ? void 0 : n.time) == null ? "room" : "event";
}, Cd = (e, n) => n != null ? new Set([...e ?? [], ...n.permissions_grant].filter((i) => !n.permissions_deny.includes(i))) : new Set(e);
var ae = "/src/entities/meet/model/subscription.ts";
const Ri = h({
  loc: {
    file: ae,
    line: 35,
    column: 2
  },
  name: "eventMeetSubscriptionSelfReceived",
  sid: "tjf57r:/src/entities/meet/model/subscription.ts:eventMeetSubscriptionSelfReceived"
}), hr = h({
  loc: {
    file: ae,
    line: 37,
    column: 53
  },
  name: "eventMeetSubscriptionSelfInitReceived",
  sid: "qnbmi9:/src/entities/meet/model/subscription.ts:eventMeetSubscriptionSelfInitReceived"
}), xa = h({
  loc: {
    file: ae,
    line: 40,
    column: 2
  },
  name: "eventMeetSubscriptionMemberReceived",
  sid: "-ite3f1:/src/entities/meet/model/subscription.ts:eventMeetSubscriptionMemberReceived"
}), wd = h({
  loc: {
    file: ae,
    line: 43,
    column: 2
  },
  name: "eventMeetSubscriptionRtcIceCandidateReceived",
  sid: "bozjhv:/src/entities/meet/model/subscription.ts:eventMeetSubscriptionRtcIceCandidateReceived"
}), Da = h({
  loc: {
    file: ae,
    line: 46,
    column: 2
  },
  name: "eventMeetSubscriptionRtcInitReceived",
  sid: "-9ejhlu:/src/entities/meet/model/subscription.ts:eventMeetSubscriptionRtcInitReceived"
}), Ed = h({
  loc: {
    file: ae,
    line: 49,
    column: 2
  },
  name: "eventMeetSubscriptionRtcSDPReceived",
  sid: "-n71x6w:/src/entities/meet/model/subscription.ts:eventMeetSubscriptionRtcSDPReceived"
}), br = h({
  loc: {
    file: ae,
    line: 52,
    column: 2
  },
  name: "eventMeetSubscriptionRtcMidiEventsReceived",
  sid: "-n4n041:/src/entities/meet/model/subscription.ts:eventMeetSubscriptionRtcMidiEventsReceived"
}), xi = h({
  loc: {
    file: ae,
    line: 55,
    column: 2
  },
  name: "eventMeetSubscriptionRtcMemberReceived",
  sid: "t7ojw2:/src/entities/meet/model/subscription.ts:eventMeetSubscriptionRtcMemberReceived"
}), _d = h({
  loc: {
    file: ae,
    line: 60,
    column: 2
  },
  name: "eventMeetSubscriptionVoteReceived",
  sid: "-cbman3:/src/entities/meet/model/subscription.ts:eventMeetSubscriptionVoteReceived"
}), Rd = h({
  loc: {
    file: ae,
    line: 63,
    column: 2
  },
  name: "eventMeetSubscriptionChatReceived",
  sid: "-bacway:/src/entities/meet/model/subscription.ts:eventMeetSubscriptionChatReceived"
}), xd = h({
  loc: {
    file: ae,
    line: 66,
    column: 2
  },
  name: "eventMeetSubscriptionSpaceReceived",
  sid: "6q7c07:/src/entities/meet/model/subscription.ts:eventMeetSubscriptionSpaceReceived"
}), Dd = h({
  loc: {
    file: ae,
    line: 68,
    column: 40
  },
  name: "eventRestartSubscription",
  sid: "qdlj8s:/src/entities/meet/model/subscription.ts:eventRestartSubscription"
}), Hn = h({
  loc: {
    file: ae,
    line: 71,
    column: 2
  },
  name: "eventMeetSubscriptionStatusUpdated",
  sid: "-5ccept:/src/entities/meet/model/subscription.ts:eventMeetSubscriptionStatusUpdated"
}), Ad = A(an(), {
  loc: {
    file: ae,
    line: 73,
    column: 39
  },
  name: "$meetSubscriptionStatus",
  sid: "-xyrng0:/src/entities/meet/model/subscription.ts:$meetSubscriptionStatus"
}).on(Hn, (e, n) => n), Aa = A(null, {
  loc: {
    file: ae,
    line: 77,
    column: 22
  },
  name: "$subscription",
  sid: "uxv8op:/src/entities/meet/model/subscription.ts:$subscription"
}), Id = nn({
  and: {
    effect: B(async ({
      currentSpaceId: e,
      email: n,
      nickname: i,
      password: a,
      subscription: t
    }) => {
      if (t != null)
        for (; !t.closed; )
          t.unsubscribe(), await Tr(100);
      let l = !1;
      return ne().subscribe({
        query: ar,
        variables: {
          email: n,
          nickname: i,
          password: a,
          space: e
        }
      }).subscribe({
        complete() {
          Ea(), Hn(an());
        },
        error(o) {
          o.message === "Socket closed" || o.message === "connection exists" || o.message === "no connection" ? Dd() : (Hn(ka(o)), pi({
            message: jo(o, {
              invalid_state: {
                "peer already exists": "Конфликт, пользователь уже присутствует во встрече",
                "space terminated": "Данная встреча уже завершена"
              }
            }),
            type: Ke.Error
          }));
        },
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        next(o) {
          var c, k, u, v, r, p;
          o.errors && pi({
            message: o.errors,
            type: Ke.Error
          }), l || (l = !0, mr(), Hn(be(void 0)));
          const s = (c = o.data) == null ? void 0 : c.meet;
          s != null && (s.self != null ? (Ri(s.self), s.self.init && hr()) : ((k = s.rtc) == null ? void 0 : k.init) != null ? Da(s.rtc.init) : ((u = s.rtc) == null ? void 0 : u.member) != null ? xi(s.rtc.member) : ((v = s.rtc) == null ? void 0 : v.ice) != null ? wd(s.rtc.ice) : ((r = s.rtc) == null ? void 0 : r.sdp) != null ? Ed(s.rtc.sdp) : ((p = s.rtc) == null ? void 0 : p.midi) != null ? br(s.rtc.midi) : s.member != null ? xa(s.member) : s.chat != null ? Rd(s.chat) : s.vote != null ? _d(s.vote) : s.space != null ? xd(s.space) : console.warn("Unknown subscription event received", s));
        }
      });
    }, {
      loc: {
        file: ae,
        line: 80,
        column: 10
      },
      name: "effect",
      sid: "-oc38y6:/src/entities/meet/model/subscription.ts:effect"
    }),
    mapParams: (e, n) => ({
      currentSpaceId: e,
      /* TODO */
      email: void 0,
      nickname: n.$anonymousNickname,
      /* TODO */
      password: void 0,
      subscription: n.$subscription
    }),
    source: {
      $anonymousNickname: Na,
      $subscription: Aa
    }
  },
  or: {
    loc: {
      file: ae,
      line: 79,
      column: 33
    },
    name: "subscribeToMeetFx",
    sid: "bsrixg:/src/entities/meet/model/subscription.ts:subscribeToMeetFx"
  }
});
Aa.on(Id.doneData, (e, n) => n);
w({
  and: [{
    clock: _i.doneData,
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    filter: Cn,
    target: Id
  }],
  or: {
    loc: {
      file: ae,
      line: 196,
      column: 0
    },
    sid: "-95u8rm:/src/entities/meet/model/subscription.ts:"
  }
});
const yr = nn({
  and: {
    effect: B((e) => {
      e == null || e.unsubscribe();
    }, {
      loc: {
        file: ae,
        line: 204,
        column: 10
      },
      name: "effect",
      sid: "kt4k:/src/entities/meet/model/subscription.ts:effect"
    }),
    source: Aa
  },
  or: {
    loc: {
      file: ae,
      line: 203,
      column: 35
    },
    name: "closeSubscriptionFx",
    sid: "-q5igis:/src/entities/meet/model/subscription.ts:closeSubscriptionFx"
  }
}), Tr = (e) => new Promise((n) => setTimeout(() => {
  n(void 0);
}, e));
var Le = "/src/entities/meet/model/space.ts";
const Mr = A(an(), {
  loc: {
    file: Le,
    line: 32,
    column: 25
  },
  name: "$myPeerId",
  sid: "-l2ihky:/src/entities/meet/model/space.ts:$myPeerId"
}), Ia = A(an(), {
  loc: {
    file: Le,
    line: 34,
    column: 46
  },
  name: "$meetSpacesMembershipBySpaceId",
  sid: "-n12u8i:/src/entities/meet/model/space.ts:$meetSpacesMembershipBySpaceId"
});
Ia.on(Ri, (e, n) => {
  if (n.membership == null)
    return e;
  const i = pe((a) => a.ref, /* @__PURE__ */ new Map(), e);
  return n.membership.forEach((a) => {
    i.set(a.space.id, a);
  }), be({
    ref: i
  });
});
const Fe = A([], {
  loc: {
    file: Le,
    line: 66,
    column: 22
  },
  name: "$peers",
  sid: "hu79hc:/src/entities/meet/model/space.ts:$peers"
}), Od = Fe.map((e) => ({
  ref: new Map(e.filter((n) => n.stream != null).flatMap((n) => n.stream.getTracks().map((i) => [i.id, n.stream.id])))
}));
wn(Od, {
  log: "enabled"
});
Fe.reset(fe);
const Oa = A(an(), {
  loc: {
    file: Le,
    line: 81,
    column: 36
  },
  name: "$meetSpacesBySpaceId",
  sid: "kzx7vj:/src/entities/meet/model/space.ts:$meetSpacesBySpaceId"
});
Oa.on(Ri, (e, n) => {
  var a;
  const i = pe((t) => t.ref, /* @__PURE__ */ new Map(), e);
  return (a = n.membership) == null || a.forEach((t) => {
    i.set(t.space.id, t.space);
  }), be({
    ref: i
  });
}).on(xd, (e, n) => {
  const i = pe((a) => a.ref, /* @__PURE__ */ new Map(), e);
  return n.add != null && n.add.spaces.forEach((a) => {
    i.set(a.id, a);
  }), n.change != null && n.change.spaces.forEach((a) => {
    i.set(a.id, a);
  }), n.remove != null && n.remove.space_ids.forEach((a) => {
    i.delete(a);
  }), be({
    ref: i
  });
});
const Di = _e({
  and: [{
    currentSpaceId: xn,
    meetSpaces: Oa
  }],
  or: {
    loc: {
      file: Le,
      line: 114,
      column: 33
    },
    name: "$currentMeetSpace",
    sid: "-q6u26d:/src/entities/meet/model/space.ts:$currentMeetSpace"
  }
}).map(({
  currentSpaceId: e,
  meetSpaces: n
}) => pe((i) => e != null ? i.ref.get(e) ?? null : null, null, n)), ni = A({
  ref: /* @__PURE__ */ new Map()
}, {
  loc: {
    file: Le,
    line: 128,
    column: 40
  },
  name: "$currentMeetSpaceMembers",
  sid: "-dmci2n:/src/entities/meet/model/space.ts:$currentMeetSpaceMembers"
}).on(xa, (e, n) => (n.add != null && n.add.members.forEach((i) => {
  e.ref.set(i.id, i);
}), n.remove != null && n.remove.members.forEach((i) => {
  e.ref.delete(i.id);
}), n.data != null && n.data.members.forEach((i) => {
  e.ref.set(i.id, i);
}), n.change != null && n.change.members.forEach((i) => {
  const a = e.ref.get(i.id);
  a != null ? e.ref.set(i.id, Md(
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    (t, l) => l ?? t,
    a,
    i
  )) : e.ref.set(i.id, {
    ...i,
    permissions_deny: i.permissions_deny ?? [],
    permissions_grant: i.permissions_grant ?? []
  });
}), {
  ref: e.ref
})), Va = _e({
  and: [
    {
      currentMeetSpaceMembers: ni,
      currentSpaceId: xn,
      meetSpacesMembershipBySpaceId: Ia,
      peers: Fe
    },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    ({
      currentMeetSpaceMembers: e,
      currentSpaceId: n,
      meetSpacesMembershipBySpaceId: i,
      peers: a
    }) => {
      var l;
      if (n == null)
        return [];
      if (!Kn(i))
        return [];
      const t = (l = i.data.ref.get(n)) == null ? void 0 : l.id;
      return [...e.ref.values()].filter((o) => o.id !== t).map((o) => ({
        member: o,
        peer: a.find((s) => s.id === o.id)
      }));
    }
  ],
  or: {
    loc: {
      file: Le,
      line: 176,
      column: 48
    },
    name: "$currentMeetSpaceMembersExceptMe",
    sid: "-s49yrz:/src/entities/meet/model/space.ts:$currentMeetSpaceMembersExceptMe"
  }
});
wn(Va, {
  log: "enabled"
});
const dn = _e({
  and: [
    {
      currentMeetSpaceMembers: ni,
      currentSpaceId: xn,
      meetSpacesMembershipBySpaceId: Ia,
      peers: Fe
    },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    ({
      currentMeetSpaceMembers: e,
      currentSpaceId: n,
      meetSpacesMembershipBySpaceId: i,
      peers: a
    }) => {
      var o;
      if (n == null || !Kn(i))
        return null;
      const t = (o = i.data.ref.get(n)) == null ? void 0 : o.id;
      return {
        member: t != null ? e.ref.get(t) : void 0,
        peer: a.find((s) => s.id === t)
      };
    }
  ],
  or: {
    loc: {
      file: Le,
      line: 206,
      column: 35
    },
    name: "$currentMeetSpaceMe",
    sid: "rd3171:/src/entities/meet/model/space.ts:$currentMeetSpaceMe"
  }
});
wn(dn, {
  log: "enabled"
});
const ln = A(/* @__PURE__ */ new Set(), {
  loc: {
    file: Le,
    line: 239,
    column: 46
  },
  name: "$currentMeetSpaceMyPermissions",
  sid: "-vyvpbb:/src/entities/meet/model/space.ts:$currentMeetSpaceMyPermissions"
});
ln.on(_e({
  and: [{
    currentMeetSpace: Di,
    currentMeetSpaceMemberMe: dn
  }],
  or: {
    loc: {
      file: Le,
      line: 244,
      column: 2
    },
    sid: "hx7ayd:/src/entities/meet/model/space.ts:"
  }
}), (e, n) => {
  var a, t;
  const i = Cd((a = n.currentMeetSpace) == null ? void 0 : a.permissions, (t = n.currentMeetSpaceMemberMe) == null ? void 0 : t.member);
  return Ni([...e], [...i]) ? e : i;
});
var vn = "/src/entities/meet/model/reaction-emojis.ts";
const Pa = h({
  loc: {
    file: vn,
    line: 7,
    column: 44
  },
  name: "eventRoomReactionPanelToggle",
  sid: "5oqbwd:/src/entities/meet/model/reaction-emojis.ts:eventRoomReactionPanelToggle"
}), Cr = h({
  loc: {
    file: vn,
    line: 8,
    column: 43
  },
  name: "eventRoomReactionPanelReset",
  sid: "q9gpik:/src/entities/meet/model/reaction-emojis.ts:eventRoomReactionPanelReset"
}), Vd = A(!1, {
  loc: {
    file: vn,
    line: 10,
    column: 40
  },
  name: "$roomReactionPanelOpened",
  sid: "-u5e0bs:/src/entities/meet/model/reaction-emojis.ts:$roomReactionPanelOpened"
}).on(Pa, (e) => !e).reset(Cr), Pd = h({
  loc: {
    file: vn,
    line: 22,
    column: 39
  },
  name: "eventRoomReactionUpdate",
  sid: "-byvrxx:/src/entities/meet/model/reaction-emojis.ts:eventRoomReactionUpdate"
}), wr = h({
  loc: {
    file: vn,
    line: 23,
    column: 38
  },
  name: "eventRoomReactionReset",
  sid: "-d1hn3z:/src/entities/meet/model/reaction-emojis.ts:eventRoomReactionReset"
}), Ld = 2e3, Er = A([], {
  loc: {
    file: vn,
    line: 26,
    column: 30
  },
  name: "$roomReactions",
  sid: "d7qi8c:/src/entities/meet/model/reaction-emojis.ts:$roomReactions"
}).on(Pd, (e, n) => {
  const i = e.filter(({
    created: a
  }) => Date.now() - a.getTime() < Ld);
  return i.push(...n), i;
}).reset(wr, fe, ke), _r = [];
w({
  and: [{
    clock: xi,
    fn: (e) => e.change != null ? e.change.filter((n) => n.reaction.emotion != null).map((n) => ({
      author: n.id,
      created: /* @__PURE__ */ new Date(),
      id: Date.now(),
      left: `${Math.floor(Math.random() * 10)}vw`,
      reaction: n.reaction.emotion ?? ""
    })) : _r,
    target: Pd
  }],
  or: {
    loc: {
      file: vn,
      line: 43,
      column: 0
    },
    sid: "-i33qlr:/src/entities/meet/model/reaction-emojis.ts:"
  }
});
var Re = "/src/entities/meet/model/vote.ts";
const Wn = h("eventVoteCreateFormVisible", {
  loc: {
    file: Re,
    line: 30,
    column: 42
  },
  name: "eventVoteCreateFormVisible",
  sid: "-x8b414:/src/entities/meet/model/vote.ts:eventVoteCreateFormVisible"
}), Ud = h({
  loc: {
    file: Re,
    line: 34,
    column: 34
  },
  name: "eventLoadMoreVotes",
  sid: "-hbcug9:/src/entities/meet/model/vote.ts:eventLoadMoreVotes"
}), $d = B({
  loc: {
    file: Re,
    line: 36,
    column: 26
  },
  name: "castVoteFx",
  sid: "-m5wh2f:/src/entities/meet/model/vote.ts:castVoteFx"
}), Ai = B(({
  cursor: e,
  distanceFromEnd: n
}) => ne().query({
  query: sr,
  variables: {
    cursor: e,
    limit: Math.max(n, 5)
  }
}).then((i) => i.data.meet.vote.list), {
  loc: {
    file: Re,
    line: 41,
    column: 28
  },
  name: "fetchVotesFx",
  sid: "-hl5199:/src/entities/meet/model/vote.ts:fetchVotesFx"
});
w({
  and: [{
    clock: wa,
    fn: () => ({
      cursor: "",
      distanceFromEnd: 0
    }),
    target: Ai
  }],
  or: {
    loc: {
      file: Re,
      line: 51,
      column: 0
    },
    sid: "7exatd:/src/entities/meet/model/vote.ts:"
  }
});
const Rr = A("", {
  loc: {
    file: Re,
    line: 57,
    column: 25
  },
  name: "$votesListCursor",
  sid: "-vvwp8n:/src/entities/meet/model/vote.ts:$votesListCursor"
}).on(Ai.doneData, (e, n) => n.cursor ?? null), Bd = A([], {
  loc: {
    file: Re,
    line: 62,
    column: 21
  },
  name: "$votesFromBE",
  sid: "i4a518:/src/entities/meet/model/vote.ts:$votesFromBE"
});
Bd.reset(ke, fe).on(Ai.doneData, (e, n) => [...n.data, ...e]).on(_d, (e, n) => {
  var t, l, o, s;
  const i = (l = (t = n.tally) == null ? void 0 : t.count) == null ? void 0 : l.votes, a = (s = (o = n.tally) == null ? void 0 : o.self) == null ? void 0 : s.votes;
  if (n.start != null)
    return [...e, n.start];
  if (n.terminate != null) {
    const c = n.terminate.id;
    return e.filter((k) => k.id !== c);
  } else {
    if (i != null)
      return e.map((c) => {
        const k = i.find((u) => u.id === c.id);
        return k == null ? c : {
          ...c,
          cases: c.cases.map((u, v) => ({
            ...u,
            count: k.cases[v]
          }))
        };
      });
    if (a != null)
      return e.map((c) => {
        const k = a.find((u) => u.id === c.id);
        return k == null ? c : {
          ...c,
          cases: c.cases.map((u, v) => k.cases.includes(v) ? {
            ...u,
            me: !0
          } : u)
        };
      });
  }
});
const Hd = Bd.map((e) => e.map((n) => ({
  ...n,
  isActive: n.terminated == null && !n.cases.some((i) => i.me)
}))), xr = Hd.map((e) => e.filter((n) => n.isActive).length), rS = B({
  handler: (e) => ne().mutate({
    mutation: Sd,
    variables: {
      cases: e.cases,
      conditions: e.conditions,
      multiple: e.multiple,
      title: e.title
    }
  })
}, {
  loc: {
    file: Re,
    line: 176,
    column: 26
  },
  name: "postVoteFx",
  sid: "xec4cd:/src/entities/meet/model/vote.ts:postVoteFx"
}), Dr = A(!1, {
  loc: {
    file: Re,
    line: 195,
    column: 40
  },
  name: "$isVoteCreateFormVisible",
  sid: "8klxca:/src/entities/meet/model/vote.ts:$isVoteCreateFormVisible"
}).on(Wn, (e, n) => n).reset(ke, fe);
w({
  and: [{
    clock: Ud,
    source: Rr,
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    filter: (e) => Cn(e),
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    fn: (e, n) => ({
      cursor: e,
      distanceFromEnd: n.distanceFromEnd
    }),
    target: Ai
  }],
  or: {
    loc: {
      file: Re,
      line: 199,
      column: 0
    },
    sid: "x2koa6:/src/entities/meet/model/vote.ts:"
  }
});
$d.use(({
  cases: e,
  voteId: n
}) => ne().mutate({
  mutation: rr,
  variables: {
    cases: e,
    id: n
  }
}).then((i) => {
  var a;
  return ((a = i.data) == null ? void 0 : a.meet.vote.cast) ?? !1;
}));
const xt = B((e) => (
  /* TODOgetApolloClient()
    .mutate({ mutation: VoteEndDocument, variables: { id: vote.id } })
    .then(() => {}) */
  Promise.resolve()
), {
  loc: {
    file: Re,
    line: 222,
    column: 25
  },
  name: "endVoteFx",
  sid: "-d6skpr:/src/entities/meet/model/vote.ts:endVoteFx"
}), Ar = /* @__PURE__ */ g((e) => /* @__PURE__ */ d(E, {
  alignContent: "center",
  alignItems: "center",
  flex: "1",
  flexDirection: "row",
  flexWrap: "wrap",
  justifyContent: "center",
  ...e
}));
Ar.displayName = "CallsView";
const Ir = /* @__PURE__ */ g(() => {
  const e = me(), n = _(() => {
    pi({
      message: void 0,
      type: Ke.NormalHangup
    });
  }, []);
  return /* @__PURE__ */ d(Jn, {
    bg: "red.50",
    h: {
      base: "48px",
      lg: "48px",
      md: "36px"
    },
    onPress: n,
    w: {
      base: "48px",
      lg: "48px",
      md: "36px"
    },
    children: /* @__PURE__ */ d(hs, {
      fill: e.colors.white,
      height: "24",
      width: "24"
    })
  });
});
Ir.displayName = "ControlHangup";
const Or = 64, Yi = 64, Vr = 376, Pr = 720, cS = ({
  asidePanelOpened: e,
  callViewFull: n
}) => {
  const i = D(Vd), {
    height: a,
    width: t
  } = En(), l = kn(), [o] = as([{
    orientation: "portrait"
  }]), s = Tt({
    base: t,
    lg: e ? t - Vr : t
  }), c = Tt({
    base: a - ((n || l === "base") && o ? 108 : 0) - (o ? 80 : 0) - Or - (o && l === "base" && i || i && l !== "base" ? Yi : 0),
    lg: Math.max(a - (n ? 112 : 0) - 56 - (i ? Yi : 0), Pr - (n ? 112 : 0) - 56 - (i ? Yi : 0))
  }), k = L(() => {
    if (n)
      return {
        height: c,
        width: s
      };
    const u = 16 / 9 < s / c;
    return {
      height: u ? c : s / 16 * 9,
      width: u ? c / 9 * 16 : s
    };
  }, [n, s, c]);
  return L(() => ({
    containerSize: k,
    viewCallsHeight: c,
    viewCallsWidth: s,
    winHeight: a,
    winWidth: t
  }), [k, c, s, a, t]);
};
function Lr(e) {
  const n = kn();
  return L(() => e === 1 ? "100%" : n === "base" ? e === 2 || e === 4 ? "50%" : e === 3 ? "33%" : `${90 / e * 2}%` : e < 5 ? "50%" : e > 9 ? `${100 / Math.sqrt(e)}%` : "33.333%", [n, e]);
}
function Ur(e) {
  const n = kn();
  return L(() => e === 1 ? "100%" : n === "base" ? e < 4 ? "100%" : "50%" : e < 5 ? "50%" : e > 9 ? `${100 / Math.sqrt(e)}%` : "33.333%", [n, e]);
}
function mS(e, n) {
  const i = kn(), a = Lr(i === "base" || n ? 1 : e === 1 ? 2 : e), t = Ur(i === "base" || n ? 1 : e === 1 ? 2 : e);
  return {
    cardHeight: a,
    cardWidth: t
  };
}
const Dt = W.object({
  password: W.string({
    required_error: x("VALIDATION.ERRORS.REQUIRED")
  }).nonempty(x("VALIDATION.ERRORS.REQUIRED"))
}).strict(), $r = /* @__PURE__ */ g(({
  onCancel: e,
  onSubmit: n,
  variant: i
}) => {
  const {
    control: a,
    handleSubmit: t
  } = Rn({
    mode: "all",
    resolver: Zn(Dt)
  });
  return /* @__PURE__ */ d(ya, {
    hasCancel: !0,
    okButtonText: "Продолжить",
    onClose: e,
    onOk: t((o) => {
      const s = Dt.parse(o);
      n(s.password);
    }),
    title: "Для входа в комнату необходим пароль",
    children: /* @__PURE__ */ d(de, {
      control: a,
      name: "password",
      render: ({
        field: o,
        fieldState: {
          error: s
        }
      }) => /* @__PURE__ */ d(us, {
        errorMessage: s == null ? void 0 : s.message,
        isInvalid: !!s,
        onBlur: o.onBlur,
        onChangeText: o.onChange,
        placeholder: i === "room" ? "пароль для входа в комнату" : "пароль чтобы присоединиться к встрече",
        value: o.value
      })
    })
  });
});
$r.displayName = "PasswordModal";
var ii = "/src/entities/webrtc/model/devices.ts";
const zd = h({
  loc: {
    file: ii,
    line: 11,
    column: 38
  },
  name: "eventDeviceListChanged",
  sid: "317v9b:/src/entities/webrtc/model/devices.ts:eventDeviceListChanged"
}), jd = h({
  loc: {
    file: ii,
    line: 12,
    column: 42
  },
  name: "eventDeviceListInitialized",
  sid: "gxjeyt:/src/entities/webrtc/model/devices.ts:eventDeviceListInitialized"
});
Oe.addEventListener("devicechange", () => {
  zd();
});
const Ii = A(an(), {
  loc: {
    file: ii,
    line: 18,
    column: 24
  },
  name: "$devices",
  sid: "-8a7fnc:/src/entities/webrtc/model/devices.ts:$devices"
}), Br = Ii.map((e) => Ye((n) => n.filter((i) => i.kind === "videoinput"), e)), Hr = Ii.map((e) => Ye((n) => n.filter((i) => i.kind === "audioinput"), e)), uS = Ii.map((e) => Ye((n) => n.filter((i) => i.kind === "audiooutput"), e)), Wd = B(async () => (await Oe.getUserMedia({
  audio: !0,
  video: !0
}), Oe.enumerateDevices()), {
  loc: {
    file: ii,
    line: 32,
    column: 27
  },
  name: "enumerateDevicesFx",
  sid: "-re7mn9:/src/entities/webrtc/model/devices.ts:enumerateDevicesFx"
});
Ii.on(Wd.doneData, (e, n) => be(n));
w({
  and: [{
    clock: [zd, jd],
    target: Wd
  }],
  or: {
    loc: {
      file: ii,
      line: 39,
      column: 0
    },
    sid: "lrz6sm:/src/entities/webrtc/model/devices.ts:"
  }
});
function Gd(e) {
  switch (e.state) {
    case "plain-camera":
    case "screen-sharing":
      return e.stream;
    case "background-blur-initializing":
    case "background-blur-ready":
    case "background-image-initializing":
    case "background-image-ready":
      return e.cameraStream;
    default:
      return;
  }
}
function zr(e) {
  switch (e.state) {
    case "plain-camera":
    case "screen-sharing":
      return e.stream;
    case "background-blur-ready":
    case "background-image-ready":
      return e.virtualBackgroundStream;
    default:
      return;
  }
}
const qd = /* @__PURE__ */ g(({
  onChange: e,
  small: n,
  value: i,
  images: a
}) => {
  const {
    t
  } = te();
  return En(), /* @__PURE__ */ f(ye, {
    children: [/* @__PURE__ */ d(yn, {
      marginY: 3,
      size: "sm",
      children: t("SETTINGS.CAMERA.VIRTUAL_BACKGROUND.TITLE", {
        defaultValue: "Виртуальный фон"
      })
    }), /* @__PURE__ */ d(ks, {
      noBorder: !0,
      flexWrap: "wrap",
      w: "100%",
      children: /* @__PURE__ */ f(ld, {
        dataSet: {
          media: jr.imagesContainer
        },
        style: Wr.imagesContainer,
        children: [/* @__PURE__ */ d(ui, {
          onPress: () => e({
            backgroundType: "none"
          }),
          selected: i.backgroundType === "none",
          small: n,
          children: t("SETTINGS.CAMERA.VIRTUAL_BACKGROUND.NONE", {
            defaultValue: "Нет"
          })
        }), /* @__PURE__ */ d(ui, {
          onPress: () => e({
            backgroundType: "blur"
          }),
          selected: i.backgroundType === "blur",
          small: n,
          children: /* @__PURE__ */ d(y, {
            children: t("SETTINGS.CAMERA.VIRTUAL_BACKGROUND.BLUR", {
              defaultValue: "Размытие"
            })
          })
        }), a.map((l) => /* @__PURE__ */ d(ui, {
          image: !0,
          onPress: () => e({
            backgroundImage: l,
            backgroundType: "image"
          }),
          selected: i.backgroundType === "image" && i.backgroundImage === l,
          small: n,
          children: /* @__PURE__ */ d(bi, {
            height: n ? 100 : 200,
            src: l,
            width: n ? 100 : 200
          })
        }, l))]
      })
    })]
  });
});
qd.displayName = "Background";
const ui = /* @__PURE__ */ g(({
  children: e,
  image: n,
  onPress: i,
  selected: a,
  small: t
}) => /* @__PURE__ */ d(od, {
  onPress: i,
  children: /* @__PURE__ */ d(mn, {
    mb: 3,
    mr: 3,
    children: /* @__PURE__ */ d(mn, {
      alignItems: "center",
      borderColor: a ? "blue.100" : "gray.100",
      borderStyle: "solid",
      borderWidth: a ? 3 : 1,
      height: t ? 102 : 202,
      justifyContent: "center",
      width: n ? t ? 102 : 202 : 102,
      children: e
    })
  })
}));
ui.displayName = "ImageBox";
const {
  ids: jr,
  styles: Wr
} = Wo({
  imagesContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    width: "100%",
    [`@media (min-width: ${ze.breakpoints.md}px)`]: {
      justifyContent: "flex-start"
    }
  }
}), Gr = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "SendIceCandidate"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "candidate"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "ListType",
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: {
                kind: "Name",
                value: "String"
              }
            }
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "rtc"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "ice"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "candidates"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "candidate"
                    }
                  }
                }]
              }]
            }
          }]
        }
      }]
    }
  }]
}, qr = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "SendOffer"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "offer"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "String"
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "rtc"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "sdp"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "offer"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "offer"
                    }
                  }
                }]
              }]
            }
          }]
        }
      }]
    }
  }]
}, Xr = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "SendAnswer"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "answer"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "String"
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "rtc"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "sdp"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "answer"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "answer"
                    }
                  }
                }]
              }]
            }
          }]
        }
      }]
    }
  }]
}, Xd = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "UpdateMyMediaStatus"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "audio"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "RTCMediaAudio"
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "video"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "RTCMediaVideo"
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "audio_mode"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "RTCMediaAudioMode"
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "rtc"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "media"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "audio"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "audio"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "video"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "video"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "audio_mode"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "audio_mode"
                    }
                  }
                }]
              }]
            }
          }]
        }
      }]
    }
  }]
};
var Yr = "/src/features/meet-webrtc/model/rtc/rtcpeerconnection.ts";
const J = A(null, {
  and: {
    name: "$rtcPeerConnection"
  },
  loc: {
    file: Yr,
    line: 6,
    column: 34
  },
  name: "$rtcPeerConnection",
  sid: "j4qimc:/src/features/meet-webrtc/model/rtc/rtcpeerconnection.ts:$rtcPeerConnection"
}), Oi = J.map((e) => e != null);
wn(Oi, {
  log: "enabled"
});
let P = /* @__PURE__ */ function(e) {
  return e.AUDIO_FILE = "AUDIO_FILE", e.METRONOME = "METRONOME", e.MICROPHONE = "MICROPHONE", e;
}({});
class Kr {
  constructor(n) {
    oi(this, "_tracks", /* @__PURE__ */ new Map());
    this._audioContext = n, this._destination = n.createMediaStreamDestination(), this._destinationTrack = this._destination.stream.getAudioTracks()[0];
  }
  async resumeContextIfNeeded() {
    this._audioContext.state === "suspended" && await this._audioContext.resume();
  }
  get destinationTrack() {
    return this._destinationTrack;
  }
  get destinationStream() {
    return this._destination.stream;
  }
  get audioContext() {
    return this._audioContext;
  }
  hasSourceTrack(n) {
    return this._tracks.has(n);
  }
  setSourceTrackEnabled(n, i) {
    const a = this._tracks.get(n);
    a == null || a.sourceNode.type !== "track" || (a.sourceNode.track.enabled = i, a.gainNode.gain.value = i ? a.volume : 0, this.enableDestinationTrackIfNeeded());
  }
  enableDestinationTrackIfNeeded() {
    let n = !1;
    for (const i of this._tracks.values())
      if (i.gainNode.gain.value > 0) {
        n = !0;
        break;
      }
    this._destinationTrack.enabled = n;
  }
  addTrack(n, i) {
    if (this._tracks.get(n) != null)
      throw new Error(`Track for ${n} already exists`);
    const t = this._audioContext.createMediaStreamSource(new MediaStream([i])), l = this._audioContext.createGain();
    t.connect(l), l.connect(this._destination), this._tracks.set(n, {
      gainNode: l,
      sourceNode: {
        node: t,
        track: i,
        type: "track"
      },
      volume: 1
    }), this.enableDestinationTrackIfNeeded();
  }
  addSourceAudioNode(n, i) {
    if (this._tracks.get(n) != null)
      throw new Error(`Node for ${n} already exists`);
    const t = this._audioContext.createGain();
    i.connect(t), t.connect(this._destination), n === P.AUDIO_FILE && t.connect(this._audioContext.destination), this._tracks.set(n, {
      gainNode: t,
      sourceNode: {
        node: i,
        type: "node"
      },
      volume: 1
    });
  }
  replaceSourceAudioNode(n, i) {
    this.removeSource(n), this.addSourceAudioNode(n, i);
  }
  removeSource(n) {
    const i = this._tracks.get(n);
    i != null && (i.gainNode.disconnect(), i.sourceNode.type === "track" ? (i.sourceNode.node.disconnect(), i.sourceNode.track.stop()) : i.sourceNode.node.disconnect(), this._tracks.delete(n), this.enableDestinationTrackIfNeeded());
  }
  replaceTrack(n, i) {
    this.removeSource(n), this.addTrack(n, i);
  }
  setVolume(n, i) {
    const a = this._tracks.get(n);
    a != null && (a.gainNode.gain.value = i);
  }
  toggleMuteAll() {
    let n = !0;
    for (const i of this._tracks.values())
      if (i.gainNode.gain.value > 0) {
        n = !1;
        break;
      }
    for (const i of this._tracks.values())
      n ? i.gainNode.gain.value = 1 : i.gainNode.gain.value = 0;
  }
  dispose() {
    this._destinationTrack.stop(), this._destination.disconnect();
    for (const n of this._tracks.keys())
      this.removeSource(n);
  }
  getTrackLabel(n) {
    const i = this._tracks.get(n);
    return i == null ? "" : i.sourceNode.type === "track" ? i.sourceNode.track.label : "";
  }
  async addOrReplaceDestinationTrack(n, i) {
    let a = i;
    return a == null ? a = n.addTrack(this._destinationTrack, this._destination.stream) : await a.replaceTrack(this.destinationTrack), a;
  }
}
var ie = "/src/features/meet-webrtc/model/rtc/video.ts";
const La = {
  facing: "user",
  type: "simple"
}, Yd = h("eventVirtualBackgroundCreated", {
  loc: {
    file: ie,
    line: 142,
    column: 45
  },
  name: "eventVirtualBackgroundCreated",
  sid: "cgb36t:/src/features/meet-webrtc/model/rtc/video.ts:eventVirtualBackgroundCreated"
}), fi = A({
  backgroundType: "none"
}, {
  loc: {
    file: ie,
    line: 146,
    column: 39
  },
  name: "$selectedBackgroundType",
  sid: "-bxnvnm:/src/features/meet-webrtc/model/rtc/video.ts:$selectedBackgroundType"
}), Jr = H.discriminatedUnion("type", [H.object({
  facing: H.union([H.literal("user"), H.literal("environment")]),
  type: H.literal("simple")
}), H.object({
  id: H.string(),
  type: H.literal("by_id")
})]).nullable(), Ua = A(null, {
  loc: {
    file: ie,
    line: 161,
    column: 31
  },
  name: "$selectedCamera",
  sid: "-30yits:/src/features/meet-webrtc/model/rtc/video.ts:$selectedCamera"
}), $a = h({
  loc: {
    file: ie,
    line: 163,
    column: 36
  },
  name: "eventVideoTrackEnded",
  sid: "qxlg71:/src/features/meet-webrtc/model/rtc/video.ts:eventVideoTrackEnded"
}), ue = A({
  enabled: !1,
  state: "initial"
}, {
  loc: {
    file: ie,
    line: 165,
    column: 22
  },
  name: "$video",
  sid: "didqjb:/src/features/meet-webrtc/model/rtc/video.ts:$video"
}), kS = ue.map((e) => e.state === "background-blur-initializing" || e.state === "background-image-initializing" || e.state === "background-blur-ready" || e.state === "background-image-ready"), Kd = h({
  loc: {
    file: ie,
    line: 177,
    column: 38
  },
  name: "eventToggleCameraOnOff",
  sid: "-rqhj2t:/src/features/meet-webrtc/model/rtc/video.ts:eventToggleCameraOnOff"
}), Jd = h({
  loc: {
    file: ie,
    line: 179,
    column: 45
  },
  name: "eventScreenSharingIconPressed",
  sid: "-xjb6w9:/src/features/meet-webrtc/model/rtc/video.ts:eventScreenSharingIconPressed"
}), Qd = h({
  loc: {
    file: ie,
    line: 181,
    column: 39
  },
  name: "eventSwitchToNextCamera",
  sid: "-fypndf:/src/features/meet-webrtc/model/rtc/video.ts:eventSwitchToNextCamera"
}), Zd = B({
  loc: {
    file: ie,
    line: 183,
    column: 36
  },
  name: "switchToNextCameraFx",
  sid: "tkpk5y:/src/features/meet-webrtc/model/rtc/video.ts:switchToNextCameraFx"
}), Qr = B({
  loc: {
    file: ie,
    line: 192,
    column: 38
  },
  name: "changeVirtualBackgroundTypeFx",
  sid: "63sg1m:/src/features/meet-webrtc/model/rtc/video.ts:changeVirtualBackgroundTypeFx"
}), Ge = nn({
  and: {
    effect: B(async ({
      event: e,
      rtcPeerConnection: n,
      selectedBackgroundType: i,
      selectedCamera: a,
      video: t
    }) => {
      var l, o, s, c;
      try {
        if (n == null)
          throw new Error("$rtcPeerConnection is null");
        switch (t.state) {
          case "initial":
            switch (e.type) {
              case "camera-icon-pressed":
                const {
                  stream: k,
                  videoTrack: u
                } = await Xe(a);
                return i.backgroundType === "none" ? {
                  enabled: !0,
                  sender: n.addTrack(u, k),
                  state: "plain-camera",
                  stream: k,
                  videoTrack: u
                } : i.backgroundType === "blur" ? {
                  cameraStream: k,
                  cameraVideoTrack: u,
                  enabled: !0,
                  oldVideoTrack: void 0,
                  sender: void 0,
                  state: "background-blur-initializing"
                } : {
                  backgroundImage: i.backgroundImage,
                  cameraStream: k,
                  cameraVideoTrack: u,
                  enabled: !0,
                  oldVideoTrack: void 0,
                  sender: void 0,
                  state: "background-image-initializing"
                };
              case "screen-sharing-icon-pressed": {
                const {
                  stream: v,
                  videoTrack: r
                } = await Pn();
                return {
                  cameraPreviouslyEnabled: !1,
                  enabled: !0,
                  sender: n.addTrack(r, v),
                  state: "screen-sharing",
                  stream: v,
                  videoTrack: r
                };
              }
              case "selected-camera-changed":
              case "selected-virtual-background-changed":
              case "rtc-peer-connection-recreated":
                return t;
              case "video-track-ended":
              case "virtual-background-created":
                return Ln(t, e);
              default:
                throw new K(e);
            }
          case "screen-sharing":
            switch (e.type) {
              case "screen-sharing-icon-pressed":
                if (t.cameraPreviouslyEnabled) {
                  const {
                    stream: k,
                    videoTrack: u
                  } = await Xe(a);
                  return i.backgroundType === "none" ? (t.videoTrack.stop(), await t.sender.replaceTrack(u), {
                    enabled: !0,
                    sender: t.sender,
                    state: "plain-camera",
                    stream: k,
                    videoTrack: u
                  }) : i.backgroundType === "blur" ? {
                    cameraStream: k,
                    cameraVideoTrack: u,
                    enabled: !0,
                    oldVideoTrack: t.videoTrack,
                    sender: t.sender,
                    state: "background-blur-initializing"
                  } : {
                    backgroundImage: i.backgroundImage,
                    cameraStream: k,
                    cameraVideoTrack: u,
                    enabled: !0,
                    oldVideoTrack: t.videoTrack,
                    sender: t.sender,
                    state: "background-image-initializing"
                  };
                } else
                  return {
                    enabled: !1,
                    sender: t.sender,
                    state: "video-track-ended",
                    stream: t.stream,
                    videoTrack: t.videoTrack
                  };
              case "video-track-ended":
                return {
                  enabled: !1,
                  sender: t.sender,
                  state: "video-track-ended",
                  stream: t.stream,
                  videoTrack: t.videoTrack
                };
              case "camera-icon-pressed": {
                const {
                  stream: k,
                  videoTrack: u
                } = await Xe(a);
                return i.backgroundType === "none" ? (t.videoTrack.stop(), await t.sender.replaceTrack(u), {
                  enabled: !0,
                  sender: t.sender,
                  state: "plain-camera",
                  stream: k,
                  videoTrack: u
                }) : i.backgroundType === "blur" ? {
                  cameraStream: k,
                  cameraVideoTrack: u,
                  enabled: !0,
                  oldVideoTrack: t.videoTrack,
                  sender: t.sender,
                  state: "background-blur-initializing"
                } : {
                  backgroundImage: i.backgroundImage,
                  cameraStream: k,
                  cameraVideoTrack: u,
                  enabled: !0,
                  oldVideoTrack: t.videoTrack,
                  sender: t.sender,
                  state: "background-image-initializing"
                };
              }
              case "rtc-peer-connection-recreated": {
                const k = e.newRTCPeerConnection.addTrack(t.videoTrack, t.stream);
                return {
                  ...t,
                  sender: k
                };
              }
              case "selected-camera-changed":
              case "selected-virtual-background-changed":
                return t;
              case "virtual-background-created":
                return Ln(t, e);
              default:
                throw new K(e);
            }
          case "plain-camera":
            switch (e.type) {
              case "video-track-ended":
                return {
                  enabled: !1,
                  sender: t.sender,
                  state: "video-track-ended",
                  stream: t.stream,
                  videoTrack: t.videoTrack
                };
              case "camera-icon-pressed":
                return t.videoTrack.enabled = !t.videoTrack.enabled, {
                  ...t,
                  enabled: t.videoTrack.enabled
                };
              case "screen-sharing-icon-pressed": {
                const {
                  stream: k,
                  videoTrack: u
                } = await Pn();
                return t.videoTrack.stop(), await t.sender.replaceTrack(u), {
                  cameraPreviouslyEnabled: !0,
                  enabled: !0,
                  sender: t.sender,
                  state: "screen-sharing",
                  stream: k,
                  videoTrack: u
                };
              }
              case "selected-camera-changed": {
                const {
                  stream: k,
                  videoTrack: u
                } = await Xe(e.selectedCamera);
                return t.videoTrack.stop(), await t.sender.replaceTrack(u), {
                  enabled: !0,
                  sender: t.sender,
                  state: "plain-camera",
                  stream: k,
                  videoTrack: u
                };
              }
              case "rtc-peer-connection-recreated": {
                const k = e.newRTCPeerConnection.addTrack(t.videoTrack, t.stream);
                return {
                  ...t,
                  sender: k
                };
              }
              case "selected-virtual-background-changed":
                return e.background.backgroundType === "none" ? t : e.background.backgroundType === "blur" ? {
                  cameraStream: t.stream,
                  cameraVideoTrack: t.videoTrack,
                  enabled: t.enabled,
                  oldVideoTrack: t.videoTrack,
                  sender: t.sender,
                  state: "background-blur-initializing"
                } : {
                  backgroundImage: e.background.backgroundImage,
                  cameraStream: t.stream,
                  cameraVideoTrack: t.videoTrack,
                  enabled: t.enabled,
                  oldVideoTrack: t.videoTrack,
                  sender: t.sender,
                  state: "background-image-initializing"
                };
              case "virtual-background-created":
                return Ln(t, e);
              default:
                throw new K(e);
            }
          case "background-blur-initializing":
          case "background-image-initializing":
            switch (e.type) {
              case "virtual-background-created": {
                if (e.canvasRef.current == null)
                  throw new Error("canvasRef is null");
                e.canvasRef.current.getContext("webgl2");
                const k = e.canvasRef.current.captureStream(30), u = k.getVideoTracks()[0];
                let v = t.sender;
                return v == null ? v = n.addTrack(u, k) : await v.replaceTrack(u), t.state === "background-blur-initializing" ? {
                  ...t,
                  sender: v,
                  state: "background-blur-ready",
                  videoTrack: u,
                  virtualBackgroundStream: k
                } : {
                  ...t,
                  sender: v,
                  state: "background-image-ready",
                  videoTrack: u,
                  virtualBackgroundStream: k
                };
              }
              case "video-track-ended":
                return (l = t.oldVideoTrack) == null || l.stop(), {
                  enabled: !1,
                  sender: t.sender,
                  state: "video-track-ended",
                  stream: t.cameraStream,
                  videoTrack: t.cameraVideoTrack
                };
              case "camera-icon-pressed":
                return (o = t.oldVideoTrack) == null || o.stop(), t.cameraVideoTrack.stop(), {
                  enabled: !1,
                  sender: t.sender,
                  state: "video-track-ended",
                  stream: t.cameraStream,
                  videoTrack: t.cameraVideoTrack
                };
              case "selected-camera-changed": {
                const {
                  stream: k,
                  videoTrack: u
                } = await Xe(e.selectedCamera);
                return (s = t.oldVideoTrack) == null || s.stop(), t.cameraVideoTrack.stop(), t.state === "background-blur-initializing" ? {
                  cameraStream: k,
                  cameraVideoTrack: u,
                  enabled: !0,
                  oldVideoTrack: void 0,
                  sender: t.sender,
                  state: "background-blur-initializing"
                } : {
                  backgroundImage: t.backgroundImage,
                  cameraStream: k,
                  cameraVideoTrack: u,
                  enabled: !0,
                  oldVideoTrack: void 0,
                  sender: t.sender,
                  state: "background-image-initializing"
                };
              }
              case "screen-sharing-icon-pressed": {
                const {
                  stream: k,
                  videoTrack: u
                } = await Pn();
                (c = t.oldVideoTrack) == null || c.stop(), t.cameraVideoTrack.stop();
                let v = t.sender;
                return v == null ? v = n.addTrack(u, k) : await v.replaceTrack(u), {
                  cameraPreviouslyEnabled: !0,
                  enabled: !0,
                  sender: v,
                  state: "screen-sharing",
                  stream: k,
                  videoTrack: u
                };
              }
              case "selected-virtual-background-changed": {
                if (e.background.backgroundType === "none") {
                  const k = t.cameraStream.getVideoTracks()[0];
                  let u = t.sender;
                  return u == null ? u = n.addTrack(k, t.cameraStream) : await u.replaceTrack(k), {
                    enabled: t.enabled,
                    sender: u,
                    state: "plain-camera",
                    stream: t.cameraStream,
                    videoTrack: k
                  };
                }
                return e.background.backgroundType === "blur" ? {
                  cameraStream: t.cameraStream,
                  cameraVideoTrack: t.cameraVideoTrack,
                  enabled: t.enabled,
                  oldVideoTrack: t.oldVideoTrack,
                  sender: t.sender,
                  state: "background-blur-initializing"
                } : {
                  backgroundImage: e.background.backgroundImage,
                  cameraStream: t.cameraStream,
                  cameraVideoTrack: t.cameraVideoTrack,
                  enabled: t.enabled,
                  oldVideoTrack: t.oldVideoTrack,
                  sender: t.sender,
                  state: "background-image-initializing"
                };
              }
              case "rtc-peer-connection-recreated":
                return {
                  ...t,
                  sender: void 0
                };
              default:
                throw new K(e);
            }
          case "background-blur-ready":
          case "background-image-ready":
            switch (e.type) {
              case "camera-icon-pressed":
                return t.videoTrack.enabled = !t.videoTrack.enabled, {
                  ...t,
                  enabled: t.videoTrack.enabled
                };
              case "selected-camera-changed": {
                const {
                  stream: v,
                  videoTrack: r
                } = await Xe(e.selectedCamera);
                return t.videoTrack.stop(), t.cameraVideoTrack.stop(), {
                  cameraStream: v,
                  cameraVideoTrack: r,
                  enabled: !0,
                  oldVideoTrack: void 0,
                  sender: t.sender,
                  state: "background-blur-initializing"
                };
              }
              case "screen-sharing-icon-pressed":
                const {
                  stream: k,
                  videoTrack: u
                } = await Pn();
                return t.videoTrack.stop(), t.cameraVideoTrack.stop(), await t.sender.replaceTrack(u), {
                  cameraPreviouslyEnabled: !0,
                  enabled: !0,
                  sender: t.sender,
                  state: "screen-sharing",
                  stream: k,
                  videoTrack: u
                };
              case "virtual-background-created":
                Ln(t, e);
              case "video-track-ended":
                return {
                  enabled: !1,
                  sender: t.sender,
                  state: "video-track-ended",
                  stream: t.cameraStream,
                  videoTrack: t.videoTrack
                };
              case "selected-virtual-background-changed":
                if (e.background.backgroundType === "none") {
                  const v = t.cameraStream.getVideoTracks()[0];
                  return await t.sender.replaceTrack(v), {
                    enabled: t.enabled,
                    sender: t.sender,
                    state: "plain-camera",
                    stream: t.cameraStream,
                    videoTrack: v
                  };
                } else
                  return e.background.backgroundType === "blur" ? {
                    cameraStream: t.cameraStream,
                    cameraVideoTrack: t.cameraVideoTrack,
                    enabled: t.enabled,
                    oldVideoTrack: t.videoTrack,
                    sender: t.sender,
                    state: "background-blur-initializing"
                  } : {
                    backgroundImage: e.background.backgroundImage,
                    cameraStream: t.cameraStream,
                    cameraVideoTrack: t.cameraVideoTrack,
                    enabled: t.enabled,
                    oldVideoTrack: t.videoTrack,
                    sender: t.sender,
                    state: "background-image-initializing"
                  };
              case "rtc-peer-connection-recreated": {
                const v = e.newRTCPeerConnection.addTrack(t.videoTrack, t.cameraStream);
                return {
                  ...t,
                  sender: v
                };
              }
              default:
                throw new K(e);
            }
          case "video-track-ended":
            switch (e.type) {
              case "screen-sharing-icon-pressed":
                const {
                  stream: k,
                  videoTrack: u
                } = await Pn();
                t.videoTrack.stop();
                let v = t.sender;
                return v == null ? v = n.addTrack(u, k) : await v.replaceTrack(u), {
                  cameraPreviouslyEnabled: !1,
                  enabled: !0,
                  sender: v,
                  state: "screen-sharing",
                  stream: k,
                  videoTrack: u
                };
              case "video-track-ended":
                Ln(t, e);
              case "virtual-background-created":
                return t;
              case "selected-camera-changed": {
                const {
                  stream: r,
                  videoTrack: p
                } = await Xe(e.selectedCamera);
                return t.videoTrack.stop(), {
                  cameraStream: r,
                  cameraVideoTrack: p,
                  enabled: !0,
                  oldVideoTrack: void 0,
                  sender: t.sender,
                  state: "background-blur-initializing"
                };
              }
              case "camera-icon-pressed": {
                const {
                  stream: r,
                  videoTrack: p
                } = await Xe(a);
                if (i.backgroundType === "none") {
                  let m = t.sender;
                  return m == null ? m = n.addTrack(p, r) : await m.replaceTrack(p), {
                    enabled: !0,
                    sender: m,
                    state: "plain-camera",
                    stream: r,
                    videoTrack: p
                  };
                } else
                  return i.backgroundType === "blur" ? {
                    cameraStream: r,
                    cameraVideoTrack: p,
                    enabled: !0,
                    oldVideoTrack: void 0,
                    sender: void 0,
                    state: "background-blur-initializing"
                  } : {
                    backgroundImage: i.backgroundImage,
                    cameraStream: r,
                    cameraVideoTrack: p,
                    enabled: !0,
                    oldVideoTrack: void 0,
                    sender: void 0,
                    state: "background-image-initializing"
                  };
              }
              case "selected-virtual-background-changed":
                return t;
              case "rtc-peer-connection-recreated":
                return {
                  ...t,
                  sender: void 0
                };
              default:
                throw new K(e);
            }
          default:
            throw new K(t);
        }
      } catch (k) {
        throw Ca(k, {
          extra: {
            silent: !0
          }
        }), k;
      }
    }, {
      loc: {
        file: ie,
        line: 202,
        column: 10
      },
      name: "effect",
      sid: "rkqs62:/src/features/meet-webrtc/model/rtc/video.ts:effect"
    }),
    mapParams: (e, n) => ({
      ...n,
      event: e
    }),
    source: {
      rtcPeerConnection: J,
      selectedBackgroundType: fi,
      selectedCamera: Ua,
      video: ue
    }
  },
  or: {
    loc: {
      file: ie,
      line: 201,
      column: 35
    },
    name: "videoStateReducerFx",
    sid: "ymsr7l:/src/features/meet-webrtc/model/rtc/video.ts:videoStateReducerFx"
  }
});
w({
  and: [{
    clock: Yd,
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    fn: (e) => ({
      canvasRef: e,
      type: "virtual-background-created"
    }),
    target: Ge
  }],
  or: {
    loc: {
      file: ie,
      line: 852,
      column: 0
    },
    sid: "xcpjlt:/src/features/meet-webrtc/model/rtc/video.ts:"
  }
});
w({
  and: [{
    clock: fi,
    source: {
      rtcPeerConnection: J
    },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    filter: ({
      rtcPeerConnection: e
    }) => e != null,
    fn: (e, n) => ({
      background: n,
      type: "selected-virtual-background-changed"
    }),
    target: Ge
  }],
  or: {
    loc: {
      file: ie,
      line: 862,
      column: 0
    },
    sid: "xtr600:/src/features/meet-webrtc/model/rtc/video.ts:"
  }
});
const el = Ge.pending, Zr = _e({
  and: [{
    video: ue,
    videoPending: el
  }],
  or: {
    loc: {
      file: ie,
      line: 877,
      column: 26
    },
    name: "$videoIsOn",
    sid: "p9imv6:/src/features/meet-webrtc/model/rtc/video.ts:$videoIsOn"
  }
}).map(({
  video: e,
  videoPending: n
}) => n || e.state === "plain-camera" && e.enabled || e.state === "background-blur-initializing" && e.enabled || e.state === "background-blur-ready" && e.enabled || e.state === "background-image-initializing" && e.enabled || e.state === "background-image-ready" && e.enabled);
w({
  and: [{
    clock: Kd,
    source: {
      rtcPeerConnection: J
    },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    filter: ({
      rtcPeerConnection: e
    }) => e != null,
    fn: () => ({
      type: "camera-icon-pressed"
    }),
    target: Ge
  }],
  or: {
    loc: {
      file: ie,
      line: 890,
      column: 0
    },
    sid: "z7sfzv:/src/features/meet-webrtc/model/rtc/video.ts:"
  }
});
w({
  and: [{
    clock: Jd,
    source: {
      rtcPeerConnection: J
    },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    filter: ({
      rtcPeerConnection: e
    }) => e != null,
    fn: () => ({
      type: "screen-sharing-icon-pressed"
    }),
    target: Ge
  }],
  or: {
    loc: {
      file: ie,
      line: 901,
      column: 0
    },
    sid: "-pds4pm:/src/features/meet-webrtc/model/rtc/video.ts:"
  }
});
ue.on(Ge.doneData, (e, n) => n);
w({
  and: [{
    clock: Qd,
    source: {
      rtcPeerConnection: J,
      selectedCamera: Ua,
      video: ue
    },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    filter: ({
      rtcPeerConnection: e
    }) => e != null,
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    fn: ({
      rtcPeerConnection: e,
      selectedCamera: n,
      video: i
    }) => ({
      rtcPeerConnection: e,
      selectedCamera: n ?? La,
      video: i
    }),
    target: Zd
  }],
  or: {
    loc: {
      file: ie,
      line: 913,
      column: 0
    },
    sid: "-ovmx4p:/src/features/meet-webrtc/model/rtc/video.ts:"
  }
});
w({
  and: [{
    clock: Ua,
    source: {
      rtcPeerConnection: J
    },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    filter: ({
      rtcPeerConnection: e
    }, n) => e != null && n != null,
    fn: (e, n) => ({
      selectedCamera: n,
      type: "selected-camera-changed"
    }),
    target: Ge
  }],
  or: {
    loc: {
      file: ie,
      line: 930,
      column: 0
    },
    sid: "-nz724e:/src/features/meet-webrtc/model/rtc/video.ts:"
  }
});
w({
  and: [{
    // clock: $remoteSettings.map(({ backgroundImage, backgroundType }) => ({
    //   backgroundImage,
    //   backgroundType,
    // })),
    clock: Go({
      clock: fi,
      until: Oi
    }),
    source: {
      rtcPeerConnection: J,
      selectedBackgroundType: fi,
      video: ue
    },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    filter: ({
      rtcPeerConnection: e
    }) => e != null,
    target: Qr
  }],
  or: {
    loc: {
      file: ie,
      line: 944,
      column: 0
    },
    sid: "-nfy9cr:/src/features/meet-webrtc/model/rtc/video.ts:"
  }
});
Zd.use(async ({
  selectedCamera: e
}) => {
  if (e == null)
    return La;
  if (e.type === "simple")
    return {
      facing: e.facing === "environment" ? "user" : "environment",
      type: "simple"
    };
  const n = (await Oe.enumerateDevices()).filter((t) => t.kind === "videoinput"), i = n.findIndex((t) => t.deviceId === e.id);
  return {
    id: n[(i + 1) % n.length].deviceId,
    type: "by_id"
  };
});
w({
  and: [{
    clock: $a,
    fn: () => ({
      type: "video-track-ended"
    }),
    target: Ge
  }],
  or: {
    loc: {
      file: ie,
      line: 995,
      column: 0
    },
    sid: "-l26csf:/src/features/meet-webrtc/model/rtc/video.ts:"
  }
});
async function Xe(e) {
  const n = e ?? La, i = {
    audio: !1,
    video: n.type === "simple" ? {
      facingMode: n
    } : {
      deviceId: {
        exact: n.id
      }
    }
  };
  let a;
  try {
    a = await Oe.getUserMedia(i);
  } catch (l) {
    if (!(typeof l == "object" && (l == null ? void 0 : l.name) === "OverconstrainedError" && l.constraint === "deviceId"))
      throw Ca(l, {
        extra: {
          silent: !0
        }
      }), _a({
        message: `Ошибка камеры: ${l instanceof Error ? l.message : String(l)}`,
        status: "warning"
      }), l;
    a = await Oe.getUserMedia({
      audio: !1,
      video: {
        facingMode: "user"
      }
    });
  }
  const t = a.getVideoTracks()[0];
  return t.addEventListener("ended", () => {
    $a();
  }), {
    stream: a,
    videoTrack: t
  };
}
async function Pn() {
  const e = await Oe.getDisplayMedia({
    audio: !1,
    video: {
      // eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
      // @ts-ignore
      cursor: "always"
    }
  }), n = e.getVideoTracks()[0];
  return n.addEventListener("ended", () => {
    $a();
  }), {
    stream: e,
    videoTrack: n
  };
}
function Ln(e, n) {
  throw new Error(`unreachable state: ${JSON.stringify(e)} event: ${JSON.stringify(n)}`);
}
const ec = (e, n) => {
  const i = navigator.webkitGetUserMedia !== void 0, a = {
    // autoGainControl: false,
    // echoCancellation: mode !== RtcMediaAudioMode.MediaAudioModePlay,
    // googEchoCancellation: mode !== RtcMediaAudioMode.MediaAudioModePlay,
    // googExperimentalEchoCancellation:
    //   mode !== RtcMediaAudioMode.MediaAudioModePlay,
    // googExperimentalNoiseSuppression:
    //   mode !== RtcMediaAudioMode.MediaAudioModePlay,
    // googHighpassFilter: mode !== RtcMediaAudioMode.MediaAudioModePlay,
    // googNoiseSuppression: mode !== RtcMediaAudioMode.MediaAudioModePlay,
    // googTypingNoiseDetection: mode !== RtcMediaAudioMode.MediaAudioModePlay,
    // noiseSuppression: mode !== RtcMediaAudioMode.MediaAudioModePlay,
  };
  return i ? {
    audio: {
      ...a,
      // echoCancellationType:
      //   mode !== RtcMediaAudioMode.MediaAudioModePlay ? "aec3" : undefined,
      ...e != null ? {
        deviceId: {
          exact: e
        }
      } : {}
    },
    video: !1
  } : {
    audio: {
      ...e ? {
        deviceId: {
          exact: e
        }
      } : {},
      ...a
    },
    video: !1
  };
};
var q = "/src/features/meet-webrtc/model/rtc/audio.ts";
const nl = h({
  loc: {
    file: q,
    line: 115,
    column: 42
  },
  name: "eventMicrophoneIconPressed",
  sid: "-7r4rg5:/src/features/meet-webrtc/model/rtc/audio.ts:eventMicrophoneIconPressed"
}), Ba = h({
  loc: {
    file: q,
    line: 116,
    column: 37
  },
  name: "eventAudioModeChanged",
  sid: "qbx6qc:/src/features/meet-webrtc/model/rtc/audio.ts:eventAudioModeChanged"
}), Ha = h({
  loc: {
    file: q,
    line: 117,
    column: 36
  },
  name: "eventAudioTrackEnded",
  sid: "-ylywoy:/src/features/meet-webrtc/model/rtc/audio.ts:eventAudioTrackEnded"
}), nc = h({
  loc: {
    file: q,
    line: 118,
    column: 39
  },
  name: "eventSourceTrackUpdated",
  sid: "-99taww:/src/features/meet-webrtc/model/rtc/audio.ts:eventSourceTrackUpdated"
}), ic = h({
  loc: {
    file: q,
    line: 123,
    column: 46
  },
  name: "eventSourceAudioElementUpdated",
  sid: "-6e2rg1:/src/features/meet-webrtc/model/rtc/audio.ts:eventSourceAudioElementUpdated"
}), za = h({
  loc: {
    file: q,
    line: 125,
    column: 43
  },
  name: "eventNewAudioDeviceSelected",
  sid: "zd2edr:/src/features/meet-webrtc/model/rtc/audio.ts:eventNewAudioDeviceSelected"
}), il = h({
  loc: {
    file: q,
    line: 128,
    column: 53
  },
  name: "eventNewAudioDeviceSelectionCancelled",
  sid: "-7r0il:/src/features/meet-webrtc/model/rtc/audio.ts:eventNewAudioDeviceSelectionCancelled"
}), Te = A({
  enabled: !1,
  sourceAudioTracks: new Kr(new AudioContext({
    latencyHint: "interactive"
  })),
  state: "initial"
}, {
  loc: {
    file: q,
    line: 130,
    column: 22
  },
  name: "$audio",
  sid: "xoqgsj:/src/features/meet-webrtc/model/rtc/audio.ts:$audio"
}), ac = A(null, {
  loc: {
    file: q,
    line: 138,
    column: 35
  },
  name: "$selectedMicrophone",
  sid: "1oyyle:/src/features/meet-webrtc/model/rtc/audio.ts:$selectedMicrophone"
}), tc = A(null, {
  loc: {
    file: q,
    line: 139,
    column: 33
  },
  name: "$selectedSpeakers",
  sid: "tazy6x:/src/features/meet-webrtc/model/rtc/audio.ts:$selectedSpeakers"
}), on = nn({
  and: {
    effect: B(async ({
      audio: e,
      currentMeetSpaceMyPermissions: n,
      event: i,
      rtcPeerConnection: a,
      selectedMicrophone: t
    }) => {
      try {
        if (a == null)
          throw new Error("$rtcPeerConnection is null");
        switch (e.state) {
          case "initial":
            switch (i.type) {
              case "rtc-peer-connection-recreated":
                return e;
              case "microphone-icon-pressed": {
                if (!n.has(Z.PermissionChannelMediaAudioSend))
                  return e;
                const {
                  audioTrack: l
                } = await xe(void 0, a, t, $.MediaAudioModeTalk);
                return await e.sourceAudioTracks.resumeContextIfNeeded(), e.sourceAudioTracks.replaceTrack(P.MICROPHONE, l), {
                  enabled: !0,
                  sender: await e.sourceAudioTracks.addOrReplaceDestinationTrack(a, void 0),
                  sourceAudioTracks: e.sourceAudioTracks,
                  state: "mode-talk-mic-on"
                };
              }
              case "audio-track-ended":
              case "selected-microphone-changed":
                return e;
              case "audio-mode-changed": {
                const l = i.newMode;
                if (l === $.MediaAudioModeListen)
                  return {
                    enabled: !1,
                    sender: void 0,
                    sourceAudioTracks: e.sourceAudioTracks,
                    state: "mode-listen"
                  };
                if (!n.has(Z.PermissionChannelMediaAudioSend))
                  return e;
                const {
                  audioTrack: o
                } = await xe(void 0, a, t, l);
                e.sourceAudioTracks.replaceTrack(P.MICROPHONE, o);
                const s = await e.sourceAudioTracks.addOrReplaceDestinationTrack(a, void 0);
                switch (l) {
                  case $.MediaAudioModePlay:
                    return {
                      enabled: !0,
                      sender: s,
                      sourceAudioTracks: e.sourceAudioTracks,
                      state: "mode-play"
                    };
                  case $.MediaAudioModeTalk:
                    return {
                      enabled: !0,
                      sender: s,
                      sourceAudioTracks: e.sourceAudioTracks,
                      state: "mode-talk-mic-on"
                    };
                  default:
                    throw new K(l);
                }
              }
              case "audio-send-permission-revoked":
                return e;
              case "audio-track-updated":
                return e;
              case "source-audio-track-updated":
                return e.sourceAudioTracks.replaceTrack(i.trackType, i.track), {
                  ...e
                };
              case "source-audio-track-removed":
                return e.sourceAudioTracks.removeSource(i.trackType), {
                  ...e
                };
              default:
                throw new K(i);
            }
          case "mode-listen":
            switch (i.type) {
              case "rtc-peer-connection-recreated": {
                const l = await e.sourceAudioTracks.addOrReplaceDestinationTrack(a, void 0);
                return {
                  ...e,
                  sender: l
                };
              }
              case "microphone-icon-pressed":
                return e;
              case "audio-track-ended":
              case "selected-microphone-changed":
                return e.sourceAudioTracks.removeSource(P.MICROPHONE), {
                  ...e
                };
              case "audio-mode-changed":
                switch (i.newMode) {
                  case $.MediaAudioModeListen:
                    return e;
                  case $.MediaAudioModePlay:
                    if (!n.has(Z.PermissionChannelMediaAudioSend))
                      return e;
                    if (e.sourceAudioTracks.hasSourceTrack(P.MICROPHONE)) {
                      e.sourceAudioTracks.setSourceTrackEnabled(P.MICROPHONE, !0);
                      let l = e.sender;
                      return l == null && (l = a.addTrack(e.sourceAudioTracks.destinationTrack, e.sourceAudioTracks.destinationStream)), {
                        enabled: !0,
                        sender: l,
                        sourceAudioTracks: e.sourceAudioTracks,
                        state: "mode-play"
                      };
                    } else {
                      const {
                        audioTrack: l
                      } = await xe(e.sender, a, t, $.MediaAudioModePlay);
                      return e.sourceAudioTracks.replaceTrack(P.MICROPHONE, l), {
                        enabled: !0,
                        sender: await e.sourceAudioTracks.addOrReplaceDestinationTrack(a, e.sender),
                        sourceAudioTracks: e.sourceAudioTracks,
                        state: "mode-play"
                      };
                    }
                  case $.MediaAudioModeTalk: {
                    if (!n.has(Z.PermissionChannelMediaAudioSend))
                      return e;
                    const {
                      audioTrack: l
                    } = await xe(e.sender, a, t, $.MediaAudioModeTalk);
                    return e.sourceAudioTracks.replaceTrack(P.MICROPHONE, l), {
                      enabled: !0,
                      sender: await e.sourceAudioTracks.addOrReplaceDestinationTrack(a, e.sender),
                      sourceAudioTracks: e.sourceAudioTracks,
                      state: "mode-talk-mic-on"
                    };
                  }
                  default:
                    throw new K(i.newMode);
                }
              case "audio-send-permission-revoked":
                return e.sourceAudioTracks.hasSourceTrack(P.MICROPHONE) && e.sourceAudioTracks.setSourceTrackEnabled(P.MICROPHONE, !1), {
                  ...e,
                  enabled: !1,
                  state: "mode-talk-mic-off"
                };
              case "audio-track-updated": {
                e.sourceAudioTracks.replaceTrack(P.MICROPHONE, i.newAudioTrack);
                let l = e.sender;
                return l != null ? await l.replaceTrack(e.sourceAudioTracks.destinationTrack) : l = a.addTrack(e.sourceAudioTracks.destinationTrack, e.sourceAudioTracks.destinationStream), {
                  enabled: !1,
                  sender: l,
                  sourceAudioTracks: e.sourceAudioTracks,
                  state: "mode-listen"
                };
              }
              case "source-audio-track-updated":
                return e.sourceAudioTracks.replaceTrack(i.trackType, i.track), {
                  ...e
                };
              case "source-audio-track-removed":
                return e.sourceAudioTracks.removeSource(i.trackType), {
                  ...e
                };
              default:
                throw new K(i);
            }
          case "mode-play":
            switch (i.type) {
              case "microphone-icon-pressed":
                return e;
              case "rtc-peer-connection-recreated": {
                const l = i.newRTCPeerConnection.addTrack(e.sourceAudioTracks.destinationTrack, e.sourceAudioTracks.destinationStream);
                return {
                  ...e,
                  sender: l
                };
              }
              case "audio-track-ended":
                return Ba($.MediaAudioModeTalk), e.sourceAudioTracks.removeSource(P.MICROPHONE), {
                  enabled: !1,
                  sender: e.sender,
                  sourceAudioTracks: e.sourceAudioTracks,
                  state: "mode-talk-mic-off"
                };
              case "selected-microphone-changed": {
                const {
                  audioTrack: l
                } = await xe(e.sender, a, i.selectedMicrophoneId, $.MediaAudioModePlay);
                return e.sourceAudioTracks.replaceTrack(P.MICROPHONE, l), {
                  enabled: !0,
                  sender: await e.sourceAudioTracks.addOrReplaceDestinationTrack(a, e.sender),
                  sourceAudioTracks: e.sourceAudioTracks,
                  state: "mode-play"
                };
              }
              case "audio-mode-changed":
                switch (i.newMode) {
                  case $.MediaAudioModeListen:
                    return e.sourceAudioTracks.setSourceTrackEnabled(P.MICROPHONE, !1), {
                      enabled: !1,
                      sender: e.sender,
                      sourceAudioTracks: e.sourceAudioTracks,
                      state: "mode-listen"
                    };
                  case $.MediaAudioModePlay:
                    return e;
                  case $.MediaAudioModeTalk:
                    return e.sourceAudioTracks.setSourceTrackEnabled(P.MICROPHONE, !0), {
                      enabled: !0,
                      sender: e.sender,
                      sourceAudioTracks: e.sourceAudioTracks,
                      state: "mode-talk-mic-on"
                    };
                  default:
                    throw new K(i.newMode);
                }
              case "audio-send-permission-revoked":
                return e.sourceAudioTracks.setSourceTrackEnabled(P.MICROPHONE, !1), {
                  ...e,
                  enabled: !1,
                  state: "mode-talk-mic-off"
                };
              case "audio-track-updated": {
                e.sourceAudioTracks.replaceTrack(P.MICROPHONE, i.newAudioTrack);
                let l = e.sender;
                return l != null ? await l.replaceTrack(i.newAudioTrack) : l = a.addTrack(i.newAudioTrack, i.newStream), {
                  enabled: !0,
                  sender: l,
                  sourceAudioTracks: e.sourceAudioTracks,
                  state: "mode-play"
                };
              }
              case "source-audio-track-updated":
                return e.sourceAudioTracks.replaceTrack(i.trackType, i.track), {
                  ...e
                };
              case "source-audio-track-removed":
                return e.sourceAudioTracks.removeSource(i.trackType), {
                  ...e
                };
              default:
                throw new K(i);
            }
          case "mode-talk-mic-off":
            switch (i.type) {
              case "rtc-peer-connection-recreated":
                return {
                  enabled: !1,
                  sender: i.newRTCPeerConnection.addTrack(e.sourceAudioTracks.destinationTrack, e.sourceAudioTracks.destinationStream),
                  sourceAudioTracks: e.sourceAudioTracks,
                  state: "mode-talk-mic-off"
                };
              case "audio-mode-changed":
                switch (i.newMode) {
                  case $.MediaAudioModeListen:
                    return e.sourceAudioTracks.setSourceTrackEnabled(P.MICROPHONE, !1), {
                      enabled: !1,
                      sender: e.sender,
                      sourceAudioTracks: e.sourceAudioTracks,
                      state: "mode-listen"
                    };
                  case $.MediaAudioModePlay: {
                    if (!n.has(Z.PermissionChannelMediaAudioSend))
                      return e;
                    const {
                      audioTrack: l
                    } = await xe(e.sender, a, t, $.MediaAudioModePlay);
                    return e.sourceAudioTracks.replaceTrack(P.MICROPHONE, l), {
                      enabled: !0,
                      sender: await e.sourceAudioTracks.addOrReplaceDestinationTrack(a, e.sender),
                      sourceAudioTracks: e.sourceAudioTracks,
                      state: "mode-play"
                    };
                  }
                  case $.MediaAudioModeTalk:
                    if (e.sourceAudioTracks.hasSourceTrack(P.MICROPHONE))
                      return e.sourceAudioTracks.setSourceTrackEnabled(P.MICROPHONE, !0), {
                        enabled: !0,
                        sender: e.sender,
                        sourceAudioTracks: e.sourceAudioTracks,
                        state: "mode-talk-mic-on"
                      };
                    {
                      const {
                        audioTrack: l
                      } = await xe(e.sender, a, t, $.MediaAudioModeTalk);
                      return e.sourceAudioTracks.replaceTrack(P.MICROPHONE, l), {
                        enabled: !0,
                        sender: await e.sourceAudioTracks.addOrReplaceDestinationTrack(a, e.sender),
                        sourceAudioTracks: e.sourceAudioTracks,
                        state: "mode-talk-mic-on"
                      };
                    }
                  default:
                    throw new K(i.newMode);
                }
              case "audio-track-ended":
                return e.sourceAudioTracks.removeSource(P.MICROPHONE), {
                  ...e
                };
              case "selected-microphone-changed":
                return e;
              case "microphone-icon-pressed": {
                if (e.sourceAudioTracks.hasSourceTrack(P.MICROPHONE))
                  return e.sourceAudioTracks.setSourceTrackEnabled(P.MICROPHONE, !0), {
                    enabled: !0,
                    sender: e.sender,
                    sourceAudioTracks: e.sourceAudioTracks,
                    state: "mode-talk-mic-on"
                  };
                {
                  const {
                    audioTrack: l
                  } = await xe(e.sender, a, t, $.MediaAudioModeTalk);
                  return e.sourceAudioTracks.replaceTrack(P.MICROPHONE, l), {
                    enabled: !0,
                    sender: await e.sourceAudioTracks.addOrReplaceDestinationTrack(a, e.sender),
                    sourceAudioTracks: e.sourceAudioTracks,
                    state: "mode-talk-mic-on"
                  };
                }
              }
              case "audio-send-permission-revoked":
                return e;
              case "audio-track-updated":
                return e.sourceAudioTracks.replaceTrack(P.MICROPHONE, i.newAudioTrack), {
                  enabled: !1,
                  sender: await e.sourceAudioTracks.addOrReplaceDestinationTrack(a, e.sender),
                  sourceAudioTracks: e.sourceAudioTracks,
                  state: "mode-talk-mic-off"
                };
              case "source-audio-track-updated":
                return e.sourceAudioTracks.replaceTrack(i.trackType, i.track), {
                  ...e
                };
              case "source-audio-track-removed":
                return e.sourceAudioTracks.removeSource(i.trackType), {
                  ...e
                };
              default:
                throw new K(i);
            }
          case "mode-talk-mic-on":
            switch (i.type) {
              case "audio-track-ended":
                return e.sourceAudioTracks.removeSource(P.MICROPHONE), {
                  enabled: !1,
                  sender: e.sender,
                  sourceAudioTracks: e.sourceAudioTracks,
                  state: "mode-talk-mic-off"
                };
              case "rtc-peer-connection-recreated": {
                const l = await e.sourceAudioTracks.addOrReplaceDestinationTrack(a, void 0);
                return {
                  ...e,
                  sender: l
                };
              }
              case "microphone-icon-pressed":
                return e.sourceAudioTracks.setSourceTrackEnabled(P.MICROPHONE, !1), {
                  ...e,
                  enabled: !1,
                  state: "mode-talk-mic-off"
                };
              case "selected-microphone-changed": {
                const {
                  audioTrack: l
                } = await xe(e.sender, a, i.selectedMicrophoneId, $.MediaAudioModeTalk);
                return e.sourceAudioTracks.replaceTrack(P.MICROPHONE, l), {
                  enabled: !0,
                  sender: await e.sourceAudioTracks.addOrReplaceDestinationTrack(a, e.sender),
                  sourceAudioTracks: e.sourceAudioTracks,
                  state: "mode-talk-mic-on"
                };
              }
              case "audio-mode-changed":
                switch (i.newMode) {
                  case $.MediaAudioModeListen:
                    return e.sourceAudioTracks.setSourceTrackEnabled(P.MICROPHONE, !1), {
                      enabled: !1,
                      sender: e.sender,
                      sourceAudioTracks: e.sourceAudioTracks,
                      state: "mode-listen"
                    };
                  case $.MediaAudioModePlay:
                    const {
                      audioTrack: l
                    } = await xe(e.sender, a, t, $.MediaAudioModePlay);
                    return e.sourceAudioTracks.replaceTrack(P.MICROPHONE, l), {
                      enabled: !0,
                      sender: await e.sourceAudioTracks.addOrReplaceDestinationTrack(a, e.sender),
                      sourceAudioTracks: e.sourceAudioTracks,
                      state: "mode-play"
                    };
                  case $.MediaAudioModeTalk:
                    return e.sourceAudioTracks.setSourceTrackEnabled(P.MICROPHONE, !0), {
                      enabled: !0,
                      sender: e.sender,
                      sourceAudioTracks: e.sourceAudioTracks,
                      state: "mode-talk-mic-on"
                    };
                  default:
                    throw new K(i.newMode);
                }
              case "audio-send-permission-revoked":
                return e.sourceAudioTracks.removeSource(P.MICROPHONE), {
                  ...e,
                  enabled: !1,
                  state: "mode-talk-mic-off"
                };
              case "audio-track-updated":
                return e.sourceAudioTracks.replaceTrack(P.MICROPHONE, i.newAudioTrack), {
                  enabled: !0,
                  sender: e.sender,
                  sourceAudioTracks: e.sourceAudioTracks,
                  state: "mode-talk-mic-on"
                };
              case "source-audio-track-updated":
                return e.sourceAudioTracks.replaceTrack(i.trackType, i.track), {
                  ...e
                };
              case "source-audio-track-removed":
                return e.sourceAudioTracks.removeSource(i.trackType), {
                  ...e
                };
              default:
                throw new K(i);
            }
          default:
            throw new K(e);
        }
      } catch (l) {
        throw Ca(l, {
          extra: {
            silent: !0
          }
        }), l;
      }
    }, {
      loc: {
        file: q,
        line: 142,
        column: 10
      },
      name: "effect",
      sid: "-9ac9q4:/src/features/meet-webrtc/model/rtc/audio.ts:effect"
    }),
    mapParams(e, n) {
      return {
        ...n,
        event: e
      };
    },
    source: {
      audio: Te,
      currentMeetSpaceMyPermissions: ln,
      rtcPeerConnection: J,
      selectedMicrophone: ac,
      selectedSpeakers: tc
    }
  },
  or: {
    loc: {
      file: q,
      line: 141,
      column: 35
    },
    name: "audioStateReducerFx",
    sid: "-mrpt74:/src/features/meet-webrtc/model/rtc/audio.ts:audioStateReducerFx"
  }
});
Te.on(on.doneData, (e, n) => n);
const dc = on.pending, lc = Te.map((e) => {
  switch (e.state) {
    case "initial":
    case "mode-talk-mic-on":
    case "mode-talk-mic-off":
      return $.MediaAudioModeTalk;
    case "mode-listen":
      return $.MediaAudioModeListen;
    case "mode-play":
      return $.MediaAudioModePlay;
    default:
      throw new K(e);
  }
}), oc = B((e) => ne().mutate({
  mutation: Xd,
  variables: {
    audio_mode: e
  }
}).then((n) => {
  var i;
  return (i = n.data) == null ? void 0 : i.meet.rtc.media;
}), {
  loc: {
    file: q,
    line: 996,
    column: 26
  },
  name: "audioModeChangeFx",
  sid: "-bz9hjm:/src/features/meet-webrtc/model/rtc/audio.ts:audioModeChangeFx"
});
w({
  and: [{
    clock: Ba,
    target: oc
  }],
  or: {
    loc: {
      file: q,
      line: 1006,
      column: 0
    },
    sid: "-j1x44g:/src/features/meet-webrtc/model/rtc/audio.ts:"
  }
});
const sc = w({
  and: [{
    clock: xi.map((e) => e.add ?? e.change),
    source: {
      currentMeetSpaceMe: dn
    },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    fn: ({
      currentMeetSpaceMe: e
    }, n) => {
      var a, t;
      const i = (a = e == null ? void 0 : e.member) == null ? void 0 : a.id;
      return ((t = n == null ? void 0 : n.find((l) => l.id === i)) == null ? void 0 : t.media.audio_mode) ?? null;
    }
  }],
  or: {
    loc: {
      file: q,
      line: 1008,
      column: 33
    },
    name: "eventMyAudioModeReceived",
    sid: "-34dkn8:/src/features/meet-webrtc/model/rtc/audio.ts:eventMyAudioModeReceived"
  }
});
w({
  and: [{
    clock: sc.filter({
      fn: (e) => e != null
    }),
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    fn: (e) => ({
      newMode: e,
      type: "audio-mode-changed"
    }),
    target: on
  }],
  or: {
    loc: {
      file: q,
      line: 1018,
      column: 0
    },
    sid: "-ijrwjj:/src/features/meet-webrtc/model/rtc/audio.ts:"
  }
});
w({
  and: [{
    clock: nl,
    fn: () => ({
      type: "microphone-icon-pressed"
    }),
    target: on
  }],
  or: {
    loc: {
      file: q,
      line: 1029,
      column: 0
    },
    sid: "-i26hjz:/src/features/meet-webrtc/model/rtc/audio.ts:"
  }
});
w({
  and: [{
    clock: za,
    fn: (e) => ({
      selectedMicrophoneId: e.deviceId,
      type: "selected-microphone-changed"
    }),
    target: on
  }],
  or: {
    loc: {
      file: q,
      line: 1035,
      column: 0
    },
    sid: "-hnc1j8:/src/features/meet-webrtc/model/rtc/audio.ts:"
  }
});
w({
  and: [{
    clock: Ha,
    fn: () => ({
      type: "audio-track-ended"
    }),
    target: on
  }],
  or: {
    loc: {
      file: q,
      line: 1045,
      column: 0
    },
    sid: "-h6af51:/src/features/meet-webrtc/model/rtc/audio.ts:"
  }
});
w({
  and: [{
    clock: nc,
    fn: ({
      track: e,
      trackType: n
    }) => ({
      track: e,
      trackType: n,
      type: "source-audio-track-updated"
    }),
    target: on
  }],
  or: {
    loc: {
      file: q,
      line: 1051,
      column: 0
    },
    sid: "-grfz4a:/src/features/meet-webrtc/model/rtc/audio.ts:"
  }
});
const rc = nn({
  and: {
    effect: B(({
      audio: e,
      element: n
    }) => {
      const i = e.sourceAudioTracks.audioContext.createMediaElementSource(n);
      e.sourceAudioTracks.replaceSourceAudioNode(P.AUDIO_FILE, i);
    }, {
      loc: {
        file: q,
        line: 1068,
        column: 10
      },
      name: "effect",
      sid: "gix9uo:/src/features/meet-webrtc/model/rtc/audio.ts:effect"
    }),
    mapParams(e, n) {
      return {
        element: e,
        ...n
      };
    },
    source: {
      audio: Te
    }
  },
  or: {
    loc: {
      file: q,
      line: 1067,
      column: 45
    },
    name: "updateSourceTracksFromAudioElementFx",
    sid: "67vie4:/src/features/meet-webrtc/model/rtc/audio.ts:updateSourceTracksFromAudioElementFx"
  }
});
w({
  and: [{
    clock: ic,
    target: rc
  }],
  or: {
    loc: {
      file: q,
      line: 1088,
      column: 0
    },
    sid: "-f8gjs6:/src/features/meet-webrtc/model/rtc/audio.ts:"
  }
});
w({
  and: [{
    clock: ln,
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    filter: (e) => !e.has(Z.PermissionChannelMediaAudioSend),
    fn: () => ({
      type: "audio-send-permission-revoked"
    }),
    target: on
  }],
  or: {
    loc: {
      file: q,
      line: 1093,
      column: 0
    },
    sid: "-eu5wcs:/src/features/meet-webrtc/model/rtc/audio.ts:"
  }
});
const al = A(null, {
  loc: {
    file: q,
    line: 1106,
    column: 2
  },
  name: "$showNewAudioDeviceSelect",
  sid: "sh4ok6:/src/features/meet-webrtc/model/rtc/audio.ts:$showNewAudioDeviceSelect"
});
al.reset(fe, ke, za, il, Ri).on(Ha, () => ({
  type: "disconnected"
}));
async function xe(e, n, i, a) {
  try {
    const t = await Oe.getUserMedia(ec(i, a)), l = t.getAudioTracks()[0];
    return l.addEventListener("ended", () => {
      Ha();
    }), {
      audioTrack: l,
      /* sender: senderNew,  */
      stream: t
    };
  } catch (t) {
    throw _a({
      message: `Ошибка аудио: ${t instanceof Error ? t.message : String(t)}`,
      status: "warning"
    }), t;
  }
}
var ai = {};
ai.byteLength = uc;
ai.toByteArray = vc;
ai.fromByteArray = Nc;
var Ae = [], ge = [], cc = typeof Uint8Array < "u" ? Uint8Array : Array, Ki = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
for (var gn = 0, mc = Ki.length; gn < mc; ++gn)
  Ae[gn] = Ki[gn], ge[Ki.charCodeAt(gn)] = gn;
ge[45] = 62;
ge[95] = 63;
function tl(e) {
  var n = e.length;
  if (n % 4 > 0)
    throw new Error("Invalid string. Length must be a multiple of 4");
  var i = e.indexOf("=");
  i === -1 && (i = n);
  var a = i === n ? 0 : 4 - i % 4;
  return [i, a];
}
function uc(e) {
  var n = tl(e), i = n[0], a = n[1];
  return (i + a) * 3 / 4 - a;
}
function kc(e, n, i) {
  return (n + i) * 3 / 4 - i;
}
function vc(e) {
  var n, i = tl(e), a = i[0], t = i[1], l = new cc(kc(e, a, t)), o = 0, s = t > 0 ? a - 4 : a, c;
  for (c = 0; c < s; c += 4)
    n = ge[e.charCodeAt(c)] << 18 | ge[e.charCodeAt(c + 1)] << 12 | ge[e.charCodeAt(c + 2)] << 6 | ge[e.charCodeAt(c + 3)], l[o++] = n >> 16 & 255, l[o++] = n >> 8 & 255, l[o++] = n & 255;
  return t === 2 && (n = ge[e.charCodeAt(c)] << 2 | ge[e.charCodeAt(c + 1)] >> 4, l[o++] = n & 255), t === 1 && (n = ge[e.charCodeAt(c)] << 10 | ge[e.charCodeAt(c + 1)] << 4 | ge[e.charCodeAt(c + 2)] >> 2, l[o++] = n >> 8 & 255, l[o++] = n & 255), l;
}
function Sc(e) {
  return Ae[e >> 18 & 63] + Ae[e >> 12 & 63] + Ae[e >> 6 & 63] + Ae[e & 63];
}
function pc(e, n, i) {
  for (var a, t = [], l = n; l < i; l += 3)
    a = (e[l] << 16 & 16711680) + (e[l + 1] << 8 & 65280) + (e[l + 2] & 255), t.push(Sc(a));
  return t.join("");
}
function Nc(e) {
  for (var n, i = e.length, a = i % 3, t = [], l = 16383, o = 0, s = i - a; o < s; o += l)
    t.push(pc(e, o, o + l > s ? s : o + l));
  return a === 1 ? (n = e[i - 1], t.push(
    Ae[n >> 2] + Ae[n << 4 & 63] + "=="
  )) : a === 2 && (n = (e[i - 2] << 8) + e[i - 1], t.push(
    Ae[n >> 10] + Ae[n >> 4 & 63] + Ae[n << 2 & 63] + "="
  )), t.join("");
}
const Ji = Ps.QuickBase64;
Ji && typeof Ji.install == "function" && Ji.install();
function fc(e) {
  let n = "", i = 0, a = e.length;
  for (; i < a; ) {
    const t = e[i++];
    n += String.fromCharCode(t);
  }
  return n;
}
function Fc(e) {
  const n = new ArrayBuffer(e.length), i = new Uint8Array(n);
  for (let a = 0, t = e.length; a < t; a++)
    i[a] = e.charCodeAt(a);
  return n;
}
function gc(e) {
  return typeof base64ToArrayBuffer < "u" ? new Uint8Array(base64ToArrayBuffer(e)) : ai.toByteArray(e);
}
function ja(e) {
  const n = Fc(e);
  return typeof base64FromArrayBuffer < "u" ? base64FromArrayBuffer(n) : ai.fromByteArray(new Uint8Array(n));
}
function ea(e) {
  const n = gc(e);
  return fc(n);
}
var dl = "/src/features/meet-webrtc/model/rtc/events.ts";
const ll = h("eventRTCTrack", {
  loc: {
    file: dl,
    line: 4,
    column: 29
  },
  name: "eventRTCTrack",
  sid: "-pxbhif:/src/features/meet-webrtc/model/rtc/events.ts:eventRTCTrack"
}), ol = h("eventRTCIceCandidate", {
  loc: {
    file: dl,
    line: 9,
    column: 36
  },
  name: "eventRTCIceCandidate",
  sid: "-5yfjt9:/src/features/meet-webrtc/model/rtc/events.ts:eventRTCIceCandidate"
});
var O = "/src/features/meet-webrtc/model/rtc/common.ts";
const sl = h({
  loc: {
    file: O,
    line: 53,
    column: 45
  },
  name: "eventRTCSignalingStateUpdated",
  sid: "xqgquw:/src/features/meet-webrtc/model/rtc/common.ts:eventRTCSignalingStateUpdated"
}), Wa = h({
  loc: {
    file: O,
    line: 55,
    column: 2
  },
  name: "eventRTCConnectionStateUpdated",
  sid: "-pl99j1:/src/features/meet-webrtc/model/rtc/common.ts:eventRTCConnectionStateUpdated"
}), Ga = h({
  loc: {
    file: O,
    line: 57,
    column: 2
  },
  name: "eventRTCIceConnectionStateUpdated",
  sid: "-l5eawu:/src/features/meet-webrtc/model/rtc/common.ts:eventRTCIceConnectionStateUpdated"
}), rl = h({
  loc: {
    file: O,
    line: 59,
    column: 2
  },
  name: "eventRTCIceGatheringStateUpdated",
  sid: "-2ilfx7:/src/features/meet-webrtc/model/rtc/common.ts:eventRTCIceGatheringStateUpdated"
}), qa = h({
  loc: {
    file: O,
    line: 60,
    column: 38
  },
  name: "eventNegotiationNeeded",
  sid: "vhl96h:/src/features/meet-webrtc/model/rtc/common.ts:eventNegotiationNeeded"
}), In = A(null, {
  loc: {
    file: O,
    line: 62,
    column: 31
  },
  name: "$signalingState",
  sid: "-up41k1:/src/features/meet-webrtc/model/rtc/common.ts:$signalingState"
}).on(sl, (e, n) => n), Sn = A(null, {
  loc: {
    file: O,
    line: 66,
    column: 32
  },
  name: "$connectionState",
  sid: "nldqg6:/src/features/meet-webrtc/model/rtc/common.ts:$connectionState"
}).on(Wa, (e, n) => n), On = A(null, {
  loc: {
    file: O,
    line: 69,
    column: 35
  },
  name: "$iceConnectionState",
  sid: "-nmfgn1:/src/features/meet-webrtc/model/rtc/common.ts:$iceConnectionState"
}).on(Ga, (e, n) => n), Xa = A(null, {
  loc: {
    file: O,
    line: 72,
    column: 34
  },
  name: "$iceGatheringState",
  sid: "-1e8kxl:/src/features/meet-webrtc/model/rtc/common.ts:$iceGatheringState"
}).on(rl, (e, n) => n);
let Be = /* @__PURE__ */ function(e) {
  return e[e.Good = 0] = "Good", e[e.Loading = 1] = "Loading", e[e.BadConnection = 2] = "BadConnection", e;
}({});
const vS = _e({
  and: [{
    connectionState: Sn,
    iceConnectionState: On,
    iceGatheringState: Xa,
    signalingState: In
  }, ({
    connectionState: e,
    iceConnectionState: n,
    iceGatheringState: i,
    signalingState: a
  }) => e === "failed" ? Be.BadConnection : e === "closed" || e === "disconnected" || e === "connecting" || e === "new" ? Be.Loading : n === "disconnected" || n === "failed" || n === "closed" ? Be.BadConnection : n === "new" || n === "checking" || i === "gathering" ? Be.Loading : a === "closed" ? Be.BadConnection : a === "have-local-offer" || a === "have-local-pranswer" || a === "have-remote-offer" || a === "have-remote-pranswer" ? Be.Loading : Be.Good],
  or: {
    loc: {
      file: O,
      line: 81,
      column: 51
    },
    name: "$webrtcUserFriendlyConnectionStatus",
    sid: "-9rbwm0:/src/features/meet-webrtc/model/rtc/common.ts:$webrtcUserFriendlyConnectionStatus"
  }
}), Ya = A([], {
  loc: {
    file: O,
    line: 135,
    column: 27
  },
  name: "$iceServers",
  sid: "-ymwy7l:/src/features/meet-webrtc/model/rtc/common.ts:$iceServers"
});
Ya.on(Da, (e, n) => Ni(e, n.ice_servers) ? e : n.ice_servers);
function hc(e) {
  const n = new $s({
    iceServers: e,
    iceTransportPolicy: "relay"
  });
  return n.addEventListener("connectionstatechange", () => {
    Wa(n.connectionState);
  }), n.addEventListener("iceconnectionstatechange", () => {
    Ga(n.iceConnectionState);
  }), n.addEventListener("icegatheringstatechange", () => {
    rl(n.iceGatheringState);
  }), n.addEventListener("signalingstatechange", () => {
    sl(n.signalingState);
  }), n.addEventListener("negotiationneeded", () => {
    qa();
  }), n.addEventListener(
    "track",
    // @ts-expect-error wrong type
    (i) => {
      ll(i);
    }
  ), n.addEventListener(
    "icecandidate",
    // @ts-expect-error wrong type
    (i) => {
      ol(i);
    }
  ), n.addTransceiver("video", void 0), n.addTransceiver("audio", void 0), typeof window < "u" && (window.rtcPeerConnection = n), n;
}
const Vi = B({
  loc: {
    file: O,
    line: 193,
    column: 41
  },
  name: "closeRTCPeertConnectionFx",
  sid: "-cw1zrl:/src/features/meet-webrtc/model/rtc/common.ts:closeRTCPeertConnectionFx"
});
Vi.use(({
  rtcPeerConnection: e
}) => {
  e == null || e.close();
});
w({
  and: [{
    clock: Ea,
    source: {
      rtcPeerConnection: J
    },
    target: Vi
  }],
  or: {
    loc: {
      file: O,
      line: 202,
      column: 0
    },
    sid: "ljxko0:/src/features/meet-webrtc/model/rtc/common.ts:"
  }
});
J.reset(Vi.done);
const Pi = nn({
  and: {
    effect: B(({
      iceServers: e,
      rtcPeerConnection: n
    }) => {
      try {
        n == null || n.close();
      } catch {
      }
      return {
        newRTCPeerConnection: hc(e)
      };
    }, {
      loc: {
        file: O,
        line: 212,
        column: 10
      },
      name: "effect",
      sid: "-6imz4z:/src/features/meet-webrtc/model/rtc/common.ts:effect"
    }),
    source: {
      iceServers: Ya,
      rtcPeerConnection: J
    }
  },
  or: {
    loc: {
      file: O,
      line: 211,
      column: 57
    },
    name: "recreateRTCPeerConnectionAfterReconnectFx",
    sid: "yhfhex:/src/features/meet-webrtc/model/rtc/common.ts:recreateRTCPeerConnectionAfterReconnectFx"
  }
}), bc = w({
  and: [{
    clock: Ya,
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    filter: (e) => e.length > 0
  }],
  or: {
    loc: {
      file: O,
      line: 233,
      column: 35
    },
    name: "eventIceServersListUpdated",
    sid: "-h5i6yg:/src/features/meet-webrtc/model/rtc/common.ts:eventIceServersListUpdated"
  }
});
w({
  and: [{
    clock: Da,
    source: J,
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    // filter: (rtcPeerConnection, init) =>
    //   init.codecs.length > 0 && rtcPeerConnection != null,
    target: Pi
  }],
  or: {
    loc: {
      file: O,
      line: 243,
      column: 0
    },
    sid: "ngnuu5:/src/features/meet-webrtc/model/rtc/common.ts:"
  }
});
w({
  and: [{
    clock: bc,
    target: Pi
  }],
  or: {
    loc: {
      file: O,
      line: 252,
      column: 0
    },
    sid: "nx5omz:/src/features/meet-webrtc/model/rtc/common.ts:"
  }
});
J.on(Pi.doneData, (e, n) => n.newRTCPeerConnection);
const cl = B(() => {
  Ho();
}, {
  loc: {
    file: O,
    line: 263,
    column: 42
  },
  name: "restartGraphQLConnectionFx",
  sid: "6h69iz:/src/features/meet-webrtc/model/rtc/common.ts:restartGraphQLConnectionFx"
}), yc = w({
  and: [{
    clock: [Ga, Wa],
    filter: (e) => e === "failed"
  }],
  or: {
    loc: {
      file: O,
      line: 273,
      column: 33
    },
    name: "eventRTCConnectionFailed",
    sid: "5j768i:/src/features/meet-webrtc/model/rtc/common.ts:eventRTCConnectionFailed"
  }
});
w({
  and: [{
    clock: [qo({
      leading: !0,
      source: yc,
      timeout: 1e4,
      trailing: !0
    }), Dd],
    source: {
      currentSpaceId: xn,
      rtcPeerConnection: J
    },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    filter: (e) => e.currentSpaceId != null,
    target: cl
  }],
  or: {
    loc: {
      file: O,
      line: 285,
      column: 0
    },
    sid: "pdxxln:/src/features/meet-webrtc/model/rtc/common.ts:"
  }
});
w({
  and: [{
    clock: Pi.doneData,
    fn: (e) => ({
      newRTCPeerConnection: e.newRTCPeerConnection,
      type: "rtc-peer-connection-recreated"
    }),
    target: Ge
  }],
  or: {
    loc: {
      file: O,
      line: 327,
      column: 0
    },
    sid: "-xrxzbw:/src/features/meet-webrtc/model/rtc/common.ts:"
  }
});
const Ue = B({
  loc: {
    file: O,
    line: 337,
    column: 24
  },
  name: "hangupFx",
  sid: "1khq10:/src/features/meet-webrtc/model/rtc/common.ts:hangupFx"
});
Ue.use(({
  audio: e,
  peers: n,
  rtcPeerConnection: i,
  video: a
}) => {
  var t;
  if (i != null) {
    switch (e.sourceAudioTracks.dispose(), a.state) {
      case "background-blur-ready":
      case "background-image-ready":
        a.virtualBackgroundStream.getTracks().forEach((l) => {
          l.stop();
        });
      case "background-blur-initializing":
      case "background-image-initializing":
        a.cameraStream.getTracks().forEach((l) => {
          l.stop();
        });
        break;
      case "plain-camera":
      case "screen-sharing":
        a.stream.getTracks().forEach((l) => {
          l.stop();
        });
        break;
      case "initial":
      case "video-track-ended":
        break;
      default:
        throw new K(a);
    }
    for (const l of n)
      for (const o of ((t = l.stream) == null ? void 0 : t.getTracks()) ?? [])
        o.stop();
  }
});
pr.on(Ue.done, (e, n) => n.params.exitReason);
Te.reset(Ue.done);
ue.reset(Ue.done);
Ei.reset(Ue.done);
cr.on(Ue, () => !0);
w({
  and: [{
    clock: Ue.done,
    target: yr
  }],
  or: {
    loc: {
      file: O,
      line: 394,
      column: 0
    },
    sid: "-uia0cm:/src/features/meet-webrtc/model/rtc/common.ts:"
  }
});
w({
  and: [{
    clock: Ue.done,
    source: {
      rtcPeerConnection: J
    },
    target: Vi
  }],
  or: {
    loc: {
      file: O,
      line: 398,
      column: 0
    },
    sid: "-ug2tz6:/src/features/meet-webrtc/model/rtc/common.ts:"
  }
});
const Tc = h({
  loc: {
    file: O,
    line: 404,
    column: 41
  },
  name: "eventOfferingStateUpdated",
  sid: "8t6o6r:/src/features/meet-webrtc/model/rtc/common.ts:eventOfferingStateUpdated"
}), SS = A(!1, {
  loc: {
    file: O,
    line: 405,
    column: 27
  },
  name: "$isOffering",
  sid: "zgjcs4:/src/features/meet-webrtc/model/rtc/common.ts:$isOffering"
}).on(Tc, (e, n) => n), Mc = B((e) => {
  e.candidate != null && e.candidate.length > 0 && ne().mutate({
    mutation: Gr,
    variables: {
      candidate: ja(JSON.stringify(e.candidate))
    }
  });
}, {
  loc: {
    file: O,
    line: 410,
    column: 34
  },
  name: "sendIceCandidateFx",
  sid: "hj75gc:/src/features/meet-webrtc/model/rtc/common.ts:sendIceCandidateFx"
}), na = nn({
  and: {
    effect: B(async ({
      rtcPeerConnection: e
    }) => {
      if (e == null)
        throw new Error("$rtcPeerConnection is null");
      e.signalingState !== "have-local-offer" && await e.setLocalDescription(), await ne().mutate({
        mutation: qr,
        variables: {
          offer: ja(JSON.stringify(e.localDescription))
        }
      });
    }, {
      loc: {
        file: O,
        line: 423,
        column: 10
      },
      name: "effect",
      sid: "-4r26dh:/src/features/meet-webrtc/model/rtc/common.ts:effect"
    }),
    source: {
      rtcPeerConnection: J
    }
  },
  or: {
    loc: {
      file: O,
      line: 422,
      column: 27
    },
    name: "sendOfferFx",
    sid: "-zggf5h:/src/features/meet-webrtc/model/rtc/common.ts:sendOfferFx"
  }
});
w({
  and: [{
    clock: qa,
    source: {
      isOffering: na.pending
    },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    // filter: ({ isOffering }) => isOffering === false,
    target: na
  }],
  or: {
    loc: {
      file: O,
      line: 447,
      column: 0
    },
    sid: "-i5ggbh:/src/features/meet-webrtc/model/rtc/common.ts:"
  }
});
Xo({
  effect: na,
  maxAttempts: 5,
  target: qa,
  timeout: 1e4
});
const Cc = w({
  and: [{
    clock: [Sn, On, In],
    filter: (e) => !["closed", "failed"].includes(e ?? "")
  }],
  or: {
    loc: {
      file: O,
      line: 464,
      column: 30
    },
    name: "eventStopReconnecting",
    sid: "6c7h2:/src/features/meet-webrtc/model/rtc/common.ts:eventStopReconnecting"
  }
}), wc = Yt({
  filter: (e) => ["closed", "failed"].includes(e.$connectionState ?? "") || ["closed", "failed"].includes(e.$iceConnectionState ?? "") || e.$signalingState === "have-local-offer",
  source: _e({
    and: [{
      $connectionState: Sn,
      $iceConnectionState: On,
      $signalingState: In
    }],
    or: {
      loc: {
        file: O,
        line: 475,
        column: 10
      },
      name: "source",
      sid: "-phti2g:/src/features/meet-webrtc/model/rtc/common.ts:source"
    }
  }),
  timeout: 1e4
}), Ec = Yt({
  filter: (e) => ["closed", "failed"].includes(e.$connectionState ?? "") || ["closed", "failed"].includes(e.$iceConnectionState ?? "") || e.$signalingState === "have-local-offer",
  source: _e({
    and: [{
      $connectionState: Sn,
      $iceConnectionState: On,
      $signalingState: In
    }],
    or: {
      loc: {
        file: O,
        line: 489,
        column: 10
      },
      name: "source",
      sid: "-8x8q9n:/src/features/meet-webrtc/model/rtc/common.ts:source"
    }
  }),
  timeout: 3e4
});
Ec.watch(() => {
  Bs("WebRTC connection stalled for over 30 seconds.");
});
const _c = Lo({
  sid: "-hhjc9g:/src/features/meet-webrtc/model/rtc/common.ts:eventReconnectInterval",
  fn: () => Hs({
    leading: !0,
    start: wc,
    stop: Cc,
    timeout: 15e3,
    trailing: !1
  }),
  name: "eventReconnectInterval",
  method: "interval",
  loc: {
    file: O,
    line: 501,
    column: 31
  }
});
w({
  and: [{
    clock: _c.tick,
    source: {
      rtcPeerConnection: J
    },
    target: cl
  }],
  or: {
    loc: {
      file: O,
      line: 508,
      column: 0
    },
    sid: "-5cov2v:/src/features/meet-webrtc/model/rtc/common.ts:"
  }
});
const ml = B({
  name: "addIceCandidateFx"
}, {
  loc: {
    file: O,
    line: 527,
    column: 33
  },
  name: "addIceCandidateFx",
  sid: "-c6w44x:/src/features/meet-webrtc/model/rtc/common.ts:addIceCandidateFx"
});
ml.use(async ({
  candidate: e,
  rtcPeerConnection: n
}) => {
  if (n == null)
    throw new Error("$rtcPeerConnection is null");
  await Promise.all(e.candidates.map((i) => {
    const a = JSON.parse(ea(i));
    return n.addIceCandidate(a);
  }));
});
w({
  and: [{
    clock: wd,
    source: J,
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    fn: (e, n) => ({
      rtcPeerConnection: e,
      candidate: n
    }),
    target: ml
  }],
  or: {
    loc: {
      file: O,
      line: 549,
      column: 0
    },
    sid: "-3fykwq:/src/features/meet-webrtc/model/rtc/common.ts:"
  }
});
const ul = B({
  loc: {
    file: O,
    line: 558,
    column: 28
  },
  name: "processSDPFx",
  sid: "ruq7he:/src/features/meet-webrtc/model/rtc/common.ts:processSDPFx"
});
w({
  and: [{
    clock: Ed,
    source: J,
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    fn: (e, n) => ({
      rtcPeerConnection: e,
      sdp: n
    }),
    target: ul
  }],
  or: {
    loc: {
      file: O,
      line: 565,
      column: 0
    },
    sid: "-2k2ihs:/src/features/meet-webrtc/model/rtc/common.ts:"
  }
});
ul.use(async ({
  rtcPeerConnection: e,
  sdp: n
}) => {
  if (e == null)
    throw new Error("$rtcPeerConnection is null");
  if (n.answer != null && n.answer !== "") {
    const a = ea(n.answer);
    await e.setRemoteDescription(new RTCSessionDescription(JSON.parse(a)));
  }
  if (!(n.offer != null && n.offer !== ""))
    return;
  const i = ea(n.offer);
  await e.setRemoteDescription(new RTCSessionDescription(JSON.parse(i))), await e.setLocalDescription(), await ne().mutate({
    mutation: Xr,
    variables: {
      answer: ja(JSON.stringify(e.localDescription))
    }
  });
});
w({
  and: [{
    clock: ol,
    target: Mc
  }],
  or: {
    loc: {
      file: O,
      line: 604,
      column: 0
    },
    sid: "99i8rq:/src/features/meet-webrtc/model/rtc/common.ts:"
  }
});
var Rc = "/src/features/meet-webrtc/model/rtc/link-quality.ts";
let Ne = /* @__PURE__ */ function(e) {
  return e[e.GREEN = 0] = "GREEN", e[e.YELLOW = 1] = "YELLOW", e[e.RED = 2] = "RED", e;
}({});
const xc = _e({
  and: [{
    connectionState: Sn,
    iceConnectionState: On,
    iceGatheringState: Xa,
    signalingState: In
  }],
  or: {
    loc: {
      file: Rc,
      line: 16,
      column: 28
    },
    name: "$linkQuality",
    sid: "iwv0ll:/src/features/meet-webrtc/model/rtc/link-quality.ts:$linkQuality"
  }
}).map(({
  connectionState: e,
  iceConnectionState: n,
  iceGatheringState: i,
  signalingState: a
}) => {
  const t = e === "connected" || e == null ? Ne.GREEN : e === "connecting" ? Ne.YELLOW : Ne.RED, l = n === "connected" || n === "completed" ? Ne.GREEN : n === "new" || n === "checking" ? Ne.YELLOW : Ne.RED, o = i === "complete" ? Ne.GREEN : Ne.YELLOW, s = a === "stable" ? Ne.GREEN : a === "closed" ? Ne.RED : Ne.YELLOW;
  return Math.max(t, l, o, s);
});
Fe.on(ll, (e, {
  streams: n
}) => e.map((i) => i.id === n[0].id ? {
  ...i,
  stream: n[0]
} : i));
Fe.on(xi, (e, n) => {
  if (n.add != null)
    return e.concat(n.add);
  if (n.remove != null)
    return e.filter((i) => {
      var a;
      return !((a = n.remove) != null && a.includes(i.id));
    });
  if (n.data != null)
    return n.data;
  if (n.change != null) {
    const i = n.change;
    return e.map((a) => {
      const t = i.find((l) => l.id === a.id);
      return t == null ? a : Md((l, o) => o ?? l, a, t);
    });
  }
  return e;
});
var Ka = "/src/features/meet-webrtc/model/update-av-status.ts";
const Ja = B({
  loc: {
    file: Ka,
    line: 17,
    column: 31
  },
  name: "updateAVStateFx",
  sid: "edigt4:/src/features/meet-webrtc/model/update-av-status.ts:updateAVStateFx"
});
Ja.use(async (e) => {
  await ne().mutate({
    mutation: Xd,
    variables: {
      audio: e.audio,
      video: e.video
    }
  });
});
w({
  and: [{
    clock: Te,
    source: {
      meetSubscriptionStatus: Ad,
      webrtcInitialized: Oi
    },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    filter: ({
      meetSubscriptionStatus: e,
      webrtcInitialized: n
    }) => e.status === va.Success && n,
    fn: (e, n) => ({
      audio: n.enabled ? Si.MediaAudioMicrophone : Si.MediaAudioOff
    }),
    target: Ja
  }],
  or: {
    loc: {
      file: Ka,
      line: 33,
      column: 0
    },
    sid: "-1ykblj:/src/features/meet-webrtc/model/update-av-status.ts:"
  }
});
w({
  and: [{
    clock: ue,
    source: {
      meetSubscriptionStatus: Ad,
      webrtcInitialized: Oi
    },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    filter: ({
      meetSubscriptionStatus: e,
      webrtcInitialized: n
    }, i) => i.state !== "initial" && e.status === va.Success && n,
    fn(e, n) {
      return n.state === "initial" || n.state === "video-track-ended" ? {
        video: De.MediaVideoOff
      } : {
        video: n.enabled ? n.state === "screen-sharing" ? De.MediaVideoScreen : n.state === "plain-camera" || n.state === "background-blur-ready" || n.state === "background-image-ready" ? De.MediaVideoCamera : De.MediaVideoOff : De.MediaVideoOff
      };
    },
    target: Ja
  }],
  or: {
    loc: {
      file: Ka,
      line: 55,
      column: 0
    },
    sid: "-zdhmf:/src/features/meet-webrtc/model/update-av-status.ts:"
  }
});
function pS() {
  return D(Sn) === "connected";
}
const NS = /* @__PURE__ */ Zt.memo(() => {
  const {
    t: e
  } = te(), n = me(), {
    audio: i,
    audioPending: a
  } = D({
    audio: Te,
    audioPending: dc
  }), t = D(ln), l = _(() => {
    nl();
  }, []), o = i.enabled ? "green.50" : "rgba(255, 255, 255, 0.12)", s = i.enabled ? "green.50" : "rgba(255, 255, 255, 0.08)", c = t.has(Z.PermissionChannelMediaAudioSend) ? i.enabled ? i.sourceAudioTracks.getTrackLabel(P.MICROPHONE) : "" : e("ROOM.CONTROLS.MIC.NO_PERMISSION_TOOLTIP");
  return /* @__PURE__ */ d(fa, {
    closeDelay: 3e3,
    label: c ?? "",
    children: /* @__PURE__ */ d(Jn, {
      _focus: {
        bg: o
      },
      _hover: {
        bg: o
      },
      _pressed: {
        bg: o
      },
      bg: s,
      disabled: a || !t.has(Z.PermissionChannelMediaAudioSend),
      h: {
        base: "48px",
        lg: "48px",
        md: "36px"
      },
      onPress: l,
      w: {
        base: "48px",
        lg: "48px",
        md: "36px"
      },
      children: /* @__PURE__ */ d(Ta, {
        fill: i.enabled ? n.colors.white : n.colors.gray[50],
        height: "24",
        width: "24"
      })
    })
  });
}), fS = /* @__PURE__ */ Zt.memo(() => {
  const e = me(), {
    video: n,
    videoIsOn: i,
    videoPending: a
  } = D({
    video: ue,
    videoIsOn: Zr,
    videoPending: el
  }), t = D(ln), l = _(() => {
    Kd();
  }, []), o = L(() => {
    var c;
    return i ? ((c = Gd(n)) == null ? void 0 : c.getVideoTracks()[0].label) ?? "" : "";
  }, [n, i]), s = i ? "green.50" : "rgba(255, 255, 255, 0.12)";
  return /* @__PURE__ */ d(fa, {
    closeDelay: 3e3,
    label: o,
    children: /* @__PURE__ */ d(Jn, {
      _focus: {
        bg: s
      },
      _hover: {
        bg: s
      },
      _pressed: {
        bg: s
      },
      bg: i ? "green.50" : "rgba(255, 255, 255, 0.08)",
      disabled: !t.has(Z.PermissionChannelMediaVideoSend),
      h: {
        base: "48px",
        lg: "48px",
        md: "36px"
      },
      onPress: l,
      opacity: !t.has(Z.PermissionChannelMediaVideoSend) || a ? 0.4 : 1,
      w: "48px",
      children: /* @__PURE__ */ d(ad, {
        fill: i ? e.colors.white : e.colors.gray[50],
        height: "24",
        width: "24"
      })
    })
  });
}), Dc = /* @__PURE__ */ g(() => {
  const [e, n] = G(!1), [i, a] = G(!1);
  ee(() => {
    (async () => {
      const c = await Oe.enumerateDevices();
      a(c.filter((k) => k.kind === "videoinput").length >= 2);
    })();
  }, []);
  const t = kn(), l = me(), o = _(() => {
    Qd();
  }, []);
  return i || t === "base" ? /* @__PURE__ */ d(We, {
    _focus: {
      bg: "rgba(255, 255, 255, 0.12)"
    },
    _hover: {
      bg: "rgba(255, 255, 255, 0.12)"
    },
    _pressed: {
      bg: "rgba(255, 255, 255, 0.12)"
    },
    bg: "rgba(255, 255, 255, 0.08)",
    borderRadius: "32",
    h: {
      base: "48px",
      lg: "48px",
      md: "36px"
    },
    onHoverIn: () => n(!0),
    onHoverOut: () => n(!1),
    onPress: o,
    shadow: "1",
    w: {
      base: "48px",
      lg: "48px",
      md: "36px"
    },
    children: /* @__PURE__ */ d(bs, {
      fill: e ? "white" : l.colors.gray[400]
    })
  }) : null;
});
Dc.displayName = "ControlChangeCamera";
const Ac = /* @__PURE__ */ g(() => {
  const e = D(lc);
  return /* @__PURE__ */ f(E, {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
    position: "relative",
    children: [/* @__PURE__ */ d(ki, {
      activeMode: e,
      mode: $.MediaAudioModeTalk
    }), /* @__PURE__ */ d(ki, {
      activeMode: e,
      mode: $.MediaAudioModeListen
    }), /* @__PURE__ */ d(ki, {
      activeMode: e,
      mode: $.MediaAudioModePlay
    })]
  });
});
Ac.displayName = "ControlMode";
const ki = /* @__PURE__ */ g(({
  activeMode: e,
  mode: n
}) => {
  const [i, a] = G(!1), t = me(), l = _(() => {
    Ba(n);
  }, [n]), o = e === n;
  return /* @__PURE__ */ d(We, {
    _focus: {
      bg: o ? t.colors.green[600] : "rgba(255, 255, 255, 0.12)"
    },
    _hover: {
      bg: o ? t.colors.green[600] : "rgba(255, 255, 255, 0.12)"
    },
    _pressed: {
      bg: o ? t.colors.green[600] : "rgba(255, 255, 255, 0.12)"
    },
    bg: o ? t.colors.green[700] : "rgba(255, 255, 255, 0.08)",
    borderRadius: "32",
    h: {
      base: "48px",
      lg: "48px",
      md: "36px"
    },
    onHoverIn: () => a(!0),
    onHoverOut: () => a(!1),
    onPress: l,
    px: "5",
    shadow: "1",
    children: /* @__PURE__ */ d(E, {
      alignItems: "center",
      flexDirection: "row",
      children: /* @__PURE__ */ d(y, {
        color: i ? t.colors.white : o ? t.colors.text[300] : t.colors.text[100],
        fontSize: "14",
        ml: "3",
        children: Ic(n)
      })
    })
  });
});
ki.displayName = "ControlModeButton";
function Ic(e) {
  switch (e) {
    case $.MediaAudioModeListen:
      return x("ROOM.MUSIC_MODE.LISTEN");
    case $.MediaAudioModeTalk:
      return x("ROOM.MUSIC_MODE.TALK");
    case $.MediaAudioModePlay:
      return x("ROOM.MUSIC_MODE.PLAY");
    default:
      throw new K(e);
  }
}
const Oc = /* @__PURE__ */ g(({
  iconOnly: e
}) => {
  const [n, i] = G(!1), a = me(), {
    t
  } = te(), l = D(ue), o = _(() => {
    Jd();
  }, []), s = l.state === "screen-sharing" && l.enabled;
  return /* @__PURE__ */ d(We, {
    _focus: {
      bg: "rgba(255, 255, 255, 0.12)"
    },
    _hover: {
      bg: "rgba(255, 255, 255, 0.12)"
    },
    _pressed: {
      bg: "rgba(255, 255, 255, 0.12)"
    },
    bg: "rgba(255, 255, 255, 0.08)",
    borderRadius: "32",
    h: {
      base: "48px",
      lg: "48px",
      md: "36px"
    },
    onHoverIn: () => i(!0),
    onHoverOut: () => i(!1),
    onPress: o,
    px: "5",
    shadow: "1",
    children: /* @__PURE__ */ f(E, {
      alignItems: "center",
      flexDirection: "row",
      children: [/* @__PURE__ */ d(ys, {
        fill: s ? a.colors.green[50] : n ? a.colors.white : a.colors.gray[400],
        height: "24",
        width: "24"
      }), e ? null : /* @__PURE__ */ f(E, {
        position: "relative",
        children: [/* @__PURE__ */ d(y, {
          color: n ? a.colors.white : a.colors.text[100],
          fontSize: "14",
          ml: "3",
          opacity: s && n ? 0 : 1,
          children: s ? t("ROOM.CONTROLS.SCREEN_SHARING_START", {
            defaultValue: "Идет демонстрация"
          }) : t("ROOM.CONTROLS.SCREEN_SHARING", {
            defaultValue: "Демонстрация"
          })
        }), /* @__PURE__ */ d(y, {
          bottom: "0",
          color: "white",
          display: s && n ? "block" : "none",
          fontSize: "14",
          left: "0",
          position: "absolute",
          right: "0",
          textAlign: "center",
          top: "0",
          children: t("ROOM.CONTROLS.SCREEN_SHARING_STOP", {
            defaultValue: "Остановить"
          })
        })]
      })]
    })
  });
});
Oc.displayName = "ControlScreenShare";
const At = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "ChatSendMessage"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "text"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "String"
          }
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "embeds"
        }
      },
      type: {
        kind: "ListType",
        type: {
          kind: "NonNullType",
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "EmbedInput"
            }
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "chat"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "send"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "text"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "text"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "embeds"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "embeds"
                    }
                  }
                }]
              }]
            }
          }]
        }
      }]
    }
  }]
}, Vc = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "ChatEditMessage"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "embeds"
        }
      },
      type: {
        kind: "ListType",
        type: {
          kind: "NonNullType",
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "EmbedInput"
            }
          }
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "id"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "ID"
          }
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "text"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "String"
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "chat"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "edit"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "embeds"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "embeds"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "id"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "id"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "text"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "text"
                    }
                  }
                }]
              }]
            }
          }]
        }
      }]
    }
  }]
}, Pc = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "ChatDeleteMessage"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "id"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "ID"
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "chat"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "remove"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "id"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "id"
                    }
                  }
                }]
              }]
            }
          }]
        }
      }]
    }
  }]
}, Lc = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "ChatReactToMessage"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "emoji"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "String"
          }
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "messageId"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "ID"
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "chat"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "react"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "reaction"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "emoji"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "id"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "messageId"
                    }
                  }
                }]
              }]
            }
          }]
        }
      }]
    }
  }]
}, Uc = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "ChatUnreactToMessage"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "emoji"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "String"
          }
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "messageId"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "ID"
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "chat"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "unreact"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "reaction"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "emoji"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "id"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "messageId"
                    }
                  }
                }]
              }]
            }
          }]
        }
      }]
    }
  }]
};
function kl(e) {
  const n = e.trim();
  return n.length === 0 ? "" : zs(n).map(se);
}
function se(e) {
  if (Array.isArray(e))
    return e.map(se);
  switch (e.type) {
    case "BIG_EMOJI":
      return /* @__PURE__ */ d(y, {
        display: "inline-flex",
        fontSize: "48px",
        lineHeight: "48px",
        children: e.value.map((n) => se(n))
      });
    case "BOLD":
      return /* @__PURE__ */ d(y, {
        bold: !0,
        children: e.value.map(se)
      });
    case "CODE":
      return /* @__PURE__ */ d(y, {
        fontFamily: "monospace",
        children: e.value.map(se)
      });
    case "CODE_LINE":
      return /* @__PURE__ */ d(y, {
        children: se(e.value)
      });
    case "COLOR":
      return null;
    case "PLAIN_TEXT":
      return e.value;
    case "EMOJI":
      return /* @__PURE__ */ d(y, {
        display: "inline-flex",
        children: e.unicode != null ? e.unicode : null
      });
    case "HEADING":
      return /* @__PURE__ */ d(yn, {
        size: $c(e.level),
        children: e.value.map(se)
      });
    case "INLINE_CODE":
      return /* @__PURE__ */ d(y, {
        fontFamily: "monospace",
        children: se(e.value)
      });
    case "ITALIC":
      return /* @__PURE__ */ d(y, {
        italic: !0,
        children: e.value.map(se)
      });
    case "STRIKE":
      return /* @__PURE__ */ d(y, {
        strikeThrough: !0,
        children: e.value.map(se)
      });
    case "LINK":
      return /* @__PURE__ */ d(od, {
        onPress: () => sd.openURL(e.value.src.value),
        children: /* @__PURE__ */ d(y, {
          underline: !0,
          color: "blue.400",
          children: se(e.value.label)
        })
      });
    case "PARAGRAPH":
      return [...e.value.map((n) => se(n)), /* @__PURE__ */ d(y, {
        children: `
`
      }, "z")];
    case "KATEX":
      return e.value;
    case "INLINE_KATEX":
      return e.value;
    case "QUOTE":
      return /* @__PURE__ */ d(y, {
        bgColor: "gray.400",
        children: e.value.map(se)
      });
    case "LIST_ITEM":
      return /* @__PURE__ */ f(y, {
        children: [e.number, " ", e.value.map(se)]
      });
    case "MENTION_CHANNEL":
      return se(e.value);
    case "MENTION_USER":
      return se(e.value);
    case "TASKS":
    case "UNORDERED_LIST":
    case "ORDERED_LIST":
      return e.value.flatMap((n) => n.value.map(se));
    case "LINE_BREAK":
      return /* @__PURE__ */ d(y, {
        children: `
`
      });
    case "IMAGE":
      return /* @__PURE__ */ d(bi, {
        source: {
          uri: e.value.src.value
        }
      });
    default:
      throw new K(e);
  }
}
function $c(e) {
  switch (e) {
    case 1:
      return "xl";
    case 2:
      return "lg";
    case 3:
      return "md";
    case 4:
      return "sm";
    default:
      throw new K(e);
  }
}
var pn = "/src/features/meet/chat/model/file-attachments/index.ts";
const Li = h({
  loc: {
    file: pn,
    line: 30,
    column: 41
  },
  name: "eventFileAttachmentsAdded",
  sid: "-zgcvdc:/src/features/meet/chat/model/file-attachments/index.ts:eventFileAttachmentsAdded"
}), vl = h({
  loc: {
    file: pn,
    line: 31,
    column: 43
  },
  name: "eventFileAttachmentsRemoved",
  sid: "jwovfx:/src/features/meet/chat/model/file-attachments/index.ts:eventFileAttachmentsRemoved"
}), Sl = h({
  loc: {
    file: pn,
    line: 32,
    column: 41
  },
  name: "eventFileAttachmentsReset",
  sid: "2kkb27:/src/features/meet/chat/model/file-attachments/index.ts:eventFileAttachmentsReset"
}), pl = h({
  loc: {
    file: pn,
    line: 34,
    column: 48
  },
  name: "eventFileAttachmentsUploadFailed",
  sid: "-1fuia5:/src/features/meet/chat/model/file-attachments/index.ts:eventFileAttachmentsUploadFailed"
}), Nl = h({
  loc: {
    file: pn,
    line: 38,
    column: 50
  },
  name: "eventFileAttachmentsUploadFinished",
  sid: "t7djkz:/src/features/meet/chat/model/file-attachments/index.ts:eventFileAttachmentsUploadFinished"
}), fl = h({
  loc: {
    file: pn,
    line: 42,
    column: 57
  },
  name: "eventFileAttachmentsUploadProgressUpdated",
  sid: "-kyu6rn:/src/features/meet/chat/model/file-attachments/index.ts:eventFileAttachmentsUploadProgressUpdated"
}), Qa = A({
  ref: /* @__PURE__ */ new Map()
}, {
  loc: {
    file: pn,
    line: 47,
    column: 40
  },
  name: "$fileAttachmentsInternal",
  sid: "-6ebvek:/src/features/meet/chat/model/file-attachments/index.ts:$fileAttachmentsInternal"
}), Fl = Qa.map((e) => [...e.ref.values()]);
Qa.on(Sl, () => ({
  ref: /* @__PURE__ */ new Map()
})).on(Li, (e, n) => {
  for (const i of n)
    e.ref.set(i.name, {
      file: i,
      progress: 0,
      state: "new"
    });
  return {
    ref: e.ref
  };
}).on(vl, (e, n) => (e.ref.delete(n), {
  ref: e.ref
})).on(Nl, (e, n) => {
  const i = e.ref.get(n.fileAttachment.file.name);
  if (i != null) {
    const a = {
      file: i.file,
      response: n.response,
      state: "uploaded"
    };
    e.ref.set(i.file.name, a);
  }
  return {
    ref: e.ref
  };
}).on(pl, (e, n) => {
  const i = e.ref.get(n.fileAttachment.file.name);
  if (i != null) {
    const a = {
      error: n.error,
      file: i.file,
      state: "error"
    };
    e.ref.set(i.file.name, a);
  }
  return {
    ref: e.ref
  };
}).on(fl, (e, n) => {
  const i = e.ref.get(n.fileAttachment.file.name);
  if (i != null && i.state === "new") {
    const a = {
      ...i,
      progress: n.progress
    };
    e.ref.set(i.file.name, a);
  }
  return {
    ref: e.ref
  };
});
var Za = "/src/features/meet/chat/model/message-edit.ts";
const gl = h({
  loc: {
    file: Za,
    line: 3,
    column: 40
  },
  name: "eventMessageEditSelected",
  sid: "fn4ufr:/src/features/meet/chat/model/message-edit.ts:eventMessageEditSelected"
}), hl = h({
  loc: {
    file: Za,
    line: 5,
    column: 41
  },
  name: "eventMessageReplySelected",
  sid: "gwuwii:/src/features/meet/chat/model/message-edit.ts:eventMessageReplySelected"
}), bl = h({
  loc: {
    file: Za,
    line: 7,
    column: 37
  },
  name: "eventMessageEditReset",
  sid: "eu2jkh:/src/features/meet/chat/model/message-edit.ts:eventMessageEditReset"
});
var z = "/src/features/meet/chat/model/common.ts";
const et = h({
  loc: {
    file: z,
    line: 92,
    column: 39
  },
  name: "eventChatMessageReacted",
  sid: "bmcwk1:/src/features/meet/chat/model/common.ts:eventChatMessageReacted"
}), yl = B({
  loc: {
    file: z,
    line: 97,
    column: 37
  },
  name: "sendMessageReactionFx",
  sid: "-via7k3:/src/features/meet/chat/model/common.ts:sendMessageReactionFx"
}), Tl = h({
  loc: {
    file: z,
    line: 102,
    column: 41
  },
  name: "eventChatMessageUnreacted",
  sid: "-n73qsn:/src/features/meet/chat/model/common.ts:eventChatMessageUnreacted"
}), Ml = B({
  loc: {
    file: z,
    line: 107,
    column: 39
  },
  name: "sendMessageUnreactionFx",
  sid: "1jvgzy:/src/features/meet/chat/model/common.ts:sendMessageUnreactionFx"
}), Cl = h("eventSendMessage", {
  loc: {
    file: z,
    line: 112,
    column: 32
  },
  name: "eventSendMessage",
  sid: "o3dia5:/src/features/meet/chat/model/common.ts:eventSendMessage"
}), wl = A([], {
  loc: {
    file: z,
    line: 114,
    column: 36
  },
  name: "$unconfirmedMessages",
  sid: "bc9ms0:/src/features/meet/chat/model/common.ts:$unconfirmedMessages"
}), Ui = A(an(), {
  loc: {
    file: z,
    line: 118,
    column: 33
  },
  name: "$receivedMessages",
  sid: "c1bueq:/src/features/meet/chat/model/common.ts:$receivedMessages"
}), El = w({
  and: [{
    source: {
      $receivedMessages: Ui,
      $unconfirmedMessages: wl
    },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    fn(e) {
      return Ye((n) => [...e.$unconfirmedMessages, ...n], e.$receivedMessages);
    }
  }],
  or: {
    loc: {
      file: z,
      line: 122,
      column: 29
    },
    name: "$chatMessages",
    sid: "2nns2x:/src/features/meet/chat/model/common.ts:$chatMessages"
  }
}), Nn = A({
  cursorPosition: 0,
  localId: qn(),
  text: " ",
  type: "new"
}, {
  loc: {
    file: z,
    line: 171,
    column: 31
  },
  name: "$currentMessage",
  sid: "-mz8gim:/src/features/meet/chat/model/common.ts:$currentMessage"
});
es({
  deserialize: (e) => {
    try {
      const n = JSON.parse(e);
      return n.type != null ? n : {
        curPosition: 0,
        text: " ",
        type: "new"
      };
    } catch {
      return {
        curPosition: 0,
        text: " ",
        type: "new"
      };
    }
  },
  store: Nn
});
const {
  __: Bc,
  eventSubscriptionChatEditMessagesReceived_: Hc,
  eventSubscriptionChatReactionCountReceived_: zc,
  eventSubscriptionChatReactionSelfReceived_: jc,
  eventSubscriptionChatRemoveMessagesReceived_: Wc,
  eventSubscriptionChatSentMessagesReceived_: Gc
} = Uo({
  and: [Rd, {
    eventSubscriptionChatEditMessagesReceived_: (e) => e.edit != null,
    eventSubscriptionChatReactionCountReceived_: (e) => {
      var n;
      return ((n = e.reaction) == null ? void 0 : n.count) != null;
    },
    eventSubscriptionChatReactionSelfReceived_: (e) => {
      var n;
      return ((n = e.reaction) == null ? void 0 : n.self) != null;
    },
    eventSubscriptionChatRemoveMessagesReceived_: (e) => e.remove != null,
    eventSubscriptionChatSentMessagesReceived_: (e) => e.sent != null
  }],
  or: {
    loc: {
      file: z,
      line: 203,
      column: 4
    },
    sid: "7ri8ci:/src/features/meet/chat/model/common.ts:"
  }
});
Bc.watch((e) => (
  // eslint-disable-next-line no-console
  console.warn("Unknown chat event received", e)
));
const _l = Wc.map((e) => e.remove), Rl = Gc.map((e) => e.sent), xl = Hc.map((e) => e.edit), qc = zc.map((e) => e.reaction.count), Xc = jc.map((e) => e.reaction.self), Dl = h({
  loc: {
    file: z,
    line: 244,
    column: 41
  },
  name: "eventCurrentMesageChanged",
  sid: "8lu8aj:/src/features/meet/chat/model/common.ts:eventCurrentMesageChanged"
}), ia = h({
  loc: {
    file: z,
    line: 248,
    column: 55
  },
  name: "eventCurrentMesageCursorPositionChanged",
  sid: "-9qv0q3:/src/features/meet/chat/model/common.ts:eventCurrentMesageCursorPositionChanged"
}), Al = h({
  loc: {
    file: z,
    line: 264,
    column: 47
  },
  name: "eventCurrentMesageEmojiInserted",
  sid: "d37329:/src/features/meet/chat/model/common.ts:eventCurrentMesageEmojiInserted"
}), Vn = B({
  loc: {
    file: z,
    line: 266,
    column: 33
  },
  name: "sendChatMessageFx",
  sid: "-yr2lkw:/src/features/meet/chat/model/common.ts:sendChatMessageFx"
}), nt = B({
  loc: {
    file: z,
    line: 273,
    column: 40
  },
  name: "sendMessageUploadFilesFx",
  sid: "cbh0e0:/src/features/meet/chat/model/common.ts:sendMessageUploadFilesFx"
}), it = h({
  loc: {
    file: z,
    line: 283,
    column: 41
  },
  name: "eventChatScrollEndReached",
  sid: "rq11wl:/src/features/meet/chat/model/common.ts:eventChatScrollEndReached"
}), Gn = B(({
  cursor: e,
  limit: n
}) => ne().query({
  fetchPolicy: "network-only",
  query: ir,
  variables: {
    cursor: e,
    limit: Math.max(n ?? 0, 5)
  }
}).then((i) => i.data.meet.chat.history), {
  loc: {
    file: z,
    line: 287,
    column: 42
  },
  name: "fetchMessagesFromHistoryFx",
  sid: "jracg:/src/features/meet/chat/model/common.ts:fetchMessagesFromHistoryFx"
}), Il = A("", {
  loc: {
    file: z,
    line: 303,
    column: 30
  },
  name: "$messageHistoryCursor",
  sid: "mgvqr1:/src/features/meet/chat/model/common.ts:$messageHistoryCursor"
});
Il.on(Gn.done, (e, n) => n.result.cursor ?? null).reset(ke);
w({
  and: [{
    clock: wa,
    fn: () => ({
      cursor: ""
    }),
    target: Gn
  }],
  or: {
    loc: {
      file: z,
      line: 311,
      column: 0
    },
    sid: "mvujok:/src/features/meet/chat/model/common.ts:"
  }
});
w({
  and: [{
    clock: it,
    source: {
      messageHistoryCursor: Il,
      isPending: Gn.pending
    },
    filter: (e) => e.messageHistoryCursor != null && !e.isPending,
    fn: (e, n) => ({
      cursor: e.messageHistoryCursor,
      limit: n.distanceFromEnd
    }),
    target: Gn
  }],
  or: {
    loc: {
      file: z,
      line: 317,
      column: 0
    },
    sid: "mz5b8q:/src/features/meet/chat/model/common.ts:"
  }
});
const FS = Vn.pending, at = B({
  loc: {
    file: z,
    line: 334,
    column: 31
  },
  name: "deleteMessageFx",
  sid: "-86jp9n:/src/features/meet/chat/model/common.ts:deleteMessageFx"
}), Ol = w({
  and: [{
    clock: Cl,
    source: {
      accessToken: ns,
      currentMessage: Nn,
      fileAttachments: Fl
    },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    filter: ({
      currentMessage: e,
      fileAttachments: n
    }) => e.text.trim().length > 0 || n.length > 0
  }],
  or: {
    loc: {
      file: z,
      line: 336,
      column: 33
    },
    name: "eventActuallySendMessage",
    sid: "-rpkzzn:/src/features/meet/chat/model/common.ts:eventActuallySendMessage"
  }
});
w({
  and: [{
    clock: Ol,
    target: nt
  }],
  or: {
    loc: {
      file: z,
      line: 348,
      column: 0
    },
    sid: "oetz0o:/src/features/meet/chat/model/common.ts:"
  }
});
nt.use(async ({
  accessToken: e,
  fileAttachments: n
}) => n.length > 0 ? Promise.all(n.map(async (i) => {
  try {
    const a = new FormData();
    a.append("file", i.file);
    const l = (await js.request({
      data: a,
      headers: {
        Authorization: e
      },
      method: "POST",
      onUploadProgress(o) {
        fl({
          fileAttachment: i,
          progress: Math.round(100 * o.loaded / (o.total ?? 1))
        });
      },
      url: "/media"
    })).data;
    return Nl({
      fileAttachment: i,
      response: l
    }), {
      file: i.file,
      response: l,
      state: "uploaded"
    };
  } catch (a) {
    return a instanceof Ws && pl({
      error: a,
      fileAttachment: i
    }), {
      error: a,
      file: i.file,
      state: "error"
    };
  }
})) : []);
Vn.use(async ({
  currentMessage: e,
  fileAttachments: n
}) => {
  if (e.text === "" && !n.every((t) => t.state === "uploaded"))
    throw new Error("currentMessage is null and no attachments");
  if (n.some((t) => t.state !== "uploaded"))
    throw new Error("Cannot upload failed or non-uploaded files");
  const a = n.map((t) => ({
    type: yt.EmbedTypeFile,
    value: t.response.id
  }));
  switch (e.type) {
    case "new": {
      const t = await ne().mutate({
        mutation: At,
        variables: {
          embeds: a,
          text: e.text
        }
      });
      if (t.data == null)
        throw new Error("data is null");
      return {
        messageId: t.data.meet.chat.send
      };
    }
    case "editing":
      return await ne().mutate({
        mutation: Vc,
        variables: {
          embeds: e.oldMessage.embeds.map((t) => ({
            type: t.type,
            value: t.value
          })).concat(a),
          id: e.oldMessage.id,
          text: e.text
        }
      }), {
        messageId: null
      };
    case "reply": {
      const t = await ne().mutate({
        mutation: At,
        variables: {
          embeds: a.concat([{
            type: yt.EmbedTypeMessage,
            value: e.repliedMessage.id
          }]),
          text: e.text
        }
      });
      if (t.data == null)
        throw new Error("data is null");
      return {
        messageId: t.data.meet.chat.send
      };
    }
    default:
      throw new K(e);
  }
});
w({
  and: [{
    clock: nt.done,
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    fn: (e) => ({
      ...e.params,
      fileAttachments: e.result
    }),
    target: Vn
  }],
  or: {
    loc: {
      file: z,
      line: 469,
      column: 0
    },
    sid: "-uz8rco:/src/features/meet/chat/model/common.ts:"
  }
});
Qa.on(Vn.done, () => ({
  ref: /* @__PURE__ */ new Map()
}));
Nn.on(ia, (e, n) => ({
  ...e,
  cursorPosition: n
})).on(Dl, (e, n) => ({
  ...e,
  cursorPosition: n.cursorPosition,
  text: n.text
}));
Nn.on(Al, (e, n) => {
  const i = e.text, a = i.slice(0, e.cursorPosition), t = i.slice(e.cursorPosition), l = `${a}${n}${t}`;
  return {
    ...e,
    cursorPosition: e.cursorPosition + n.length,
    text: l
  };
});
Nn.on(w({
  and: [{
    clock: gl,
    source: Ui,
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    fn: (e, n) => pe((i) => i.find((a) => a.type === "RECEIVED" && a.message.id === n), void 0, e)
  }],
  or: {
    loc: {
      file: z,
      line: 505,
      column: 2
    },
    sid: "-j7bdtj:/src/features/meet/chat/model/common.ts:"
  }
}), (e, n) => n == null ? {
  cursorPosition: 0,
  // embeds: [],
  localId: qn(),
  text: " ",
  type: "new"
} : {
  cursorPosition: n.message.text.length - 1,
  // embeds: payload.message.embeds.map(({ type, value }) => ({
  //   type,
  //   value,
  // })),
  localId: qn(),
  oldMessage: n.message,
  text: n.message.text,
  type: "editing"
});
Nn.on(w({
  and: [{
    clock: hl,
    source: Ui,
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    fn: (e, n) => pe((i) => i.find((a) => a.type === "RECEIVED" && a.message.id === n), void 0, e)
  }],
  or: {
    loc: {
      file: z,
      line: 543,
      column: 4
    },
    sid: "-hc8hdr:/src/features/meet/chat/model/common.ts:"
  }
}), (e, n) => n == null ? {
  cursorPosition: 0,
  // embeds: [],
  localId: qn(),
  text: " ",
  type: "new"
} : {
  cursorPosition: 0,
  // embeds: payload.message.embeds.map(({ type, value }) => ({
  //   type,
  //   value,
  // })),
  localId: qn(),
  repliedMessage: n.message,
  text: " ",
  type: "reply"
}).reset(bl, Vn.done);
const Vl = A(0, {
  loc: {
    file: z,
    line: 579,
    column: 41
  },
  name: "$receivedMessagesEditedAt",
  sid: "iudvzm:/src/features/meet/chat/model/common.ts:$receivedMessagesEditedAt"
}).on(_l, () => Date.now()).on(xl, () => Date.now());
Ui.on(Gn.doneData, (e, n) => be([...pe((i) => i, [], e), ...n.data.map((i) => ({
  message: i,
  type: "RECEIVED"
})).reverse()])).on(w({
  and: [{
    clock: Rl,
    source: dn,
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    fn(e, n) {
      var i;
      return {
        messages: n,
        myPeerId: (i = e == null ? void 0 : e.member) == null ? void 0 : i.id
      };
    }
  }],
  or: {
    loc: {
      file: z,
      line: 593,
      column: 4
    },
    sid: "-ez0des:/src/features/meet/chat/model/common.ts:"
  }
}), (e, n) => be([...n.messages.messages.map((i) => ({
  message: i,
  type: "RECEIVED"
})).reverse(), ...pe((i) => i, [], e)])).on(_l, (e, n) => Ye((i) => i.filter((a) => a.type !== "RECEIVED" || !n.messages.includes(a.message.id)), e)).on(xl, (e, n) => Ye((i) => i.map((a) => {
  const t = n.messages.find((l) => a.type === "RECEIVED" && l.id === a.message.id);
  return t == null || a.type !== "RECEIVED" ? a : {
    ...a,
    message: {
      ...a.message,
      embeds: t.embeds,
      text: t.text,
      updated: t.updated
    }
  };
}), e)).on(qc, (e, n) => Ye((i) => i.map((a) => {
  if (a.type !== "RECEIVED")
    return a;
  const t = n.messages.find((l) => l.id === a.message.id);
  return t == null ? a : {
    ...a,
    message: {
      ...a.message,
      reactions: t.reactions.map((l) => {
        var o;
        return {
          ...l,
          me: ((o = a.message.reactions.find((s) => s.name === l.name)) == null ? void 0 : o.me) ?? !1
        };
      })
    }
  };
}), e)).on(Xc, (e, n) => Ye((i) => i.map((a) => {
  if (a.type !== "RECEIVED")
    return a;
  const t = n.messages.find((l) => l.id === a.message.id);
  return t == null ? a : {
    ...a,
    message: {
      ...a.message,
      reactions: a.message.reactions.map((l) => ({
        ...l,
        me: t.reactions.includes(l.name)
      }))
    }
  };
}), e)).reset(ke, fe);
wl.on(Ol, (e, n) => (
  /* FIXME переделать на sample? */
  n.currentMessage.type === "new" ? [{
    localId: n.currentMessage.localId,
    messageText: n.currentMessage.text,
    sent: (/* @__PURE__ */ new Date()).toISOString(),
    type: "UNCONFIRMED"
  }, ...e] : void 0
)).on(Vn.done, (e, n) => e.filter((i) => i.localId !== n.params.currentMessage.localId)).reset(ke, fe);
at.use((e) => ne().mutate({
  mutation: Pc,
  variables: {
    id: e
  }
}).then(() => {
}));
w({
  and: [{
    clock: et,
    target: yl
  }],
  or: {
    loc: {
      file: z,
      line: 738,
      column: 0
    },
    sid: "bm9fjh:/src/features/meet/chat/model/common.ts:"
  }
});
yl.use(({
  messageId: e,
  reaction: n
}) => ne().mutate({
  mutation: Lc,
  variables: {
    emoji: n,
    messageId: e
  }
}).then(() => {
}));
w({
  and: [{
    clock: Tl,
    target: Ml
  }],
  or: {
    loc: {
      file: z,
      line: 748,
      column: 0
    },
    sid: "c3b1xo:/src/features/meet/chat/model/common.ts:"
  }
});
Ml.use(({
  messageId: e,
  reaction: n
}) => ne().mutate({
  mutation: Uc,
  variables: {
    emoji: n,
    messageId: e
  }
}).then(() => {
}));
function qn() {
  return vi("unsent");
}
var ti = "/src/features/meet/chat/model/has-new-messages.ts";
const Pl = h({
  loc: {
    file: ti,
    line: 7,
    column: 45
  },
  name: "eventToggleChatHasNewMessages",
  sid: "-6v8mqw:/src/features/meet/chat/model/has-new-messages.ts:eventToggleChatHasNewMessages"
}), Ll = h({
  loc: {
    file: ti,
    line: 8,
    column: 44
  },
  name: "eventResetChatHasNewMessages",
  sid: "-dikvdt:/src/features/meet/chat/model/has-new-messages.ts:eventResetChatHasNewMessages"
}), Yc = A(!1, {
  loc: {
    file: ti,
    line: 10,
    column: 35
  },
  name: "$chatHasNewMessages",
  sid: "-c23oj9:/src/features/meet/chat/model/has-new-messages.ts:$chatHasNewMessages"
}).on(Pl, (e, n) => n).reset(Ll);
w({
  and: [{
    clock: ei,
    source: An,
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    filter: (e) => e === ce.Chat,
    target: Ll
  }],
  or: {
    loc: {
      file: ti,
      line: 15,
      column: 0
    },
    sid: "2hcwqc:/src/features/meet/chat/model/has-new-messages.ts:"
  }
});
w({
  and: [{
    clock: Rl,
    source: An,
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    fn: (e) => e !== ce.Chat,
    target: Pl
  }],
  or: {
    loc: {
      file: ti,
      line: 25,
      column: 0
    },
    sid: "2yej4j:/src/features/meet/chat/model/has-new-messages.ts:"
  }
});
var $i = "/src/features/meet/chat/model/message-context-menu.ts";
const aa = h({
  loc: {
    file: $i,
    line: 8,
    column: 36
  },
  name: "eventOpenContextMenu",
  sid: "-abihg0:/src/features/meet/chat/model/message-context-menu.ts:eventOpenContextMenu"
}), Ul = h({
  loc: {
    file: $i,
    line: 9,
    column: 37
  },
  name: "eventCloseContextMenu",
  sid: "3e18dw:/src/features/meet/chat/model/message-context-menu.ts:eventCloseContextMenu"
}), Kc = h({
  loc: {
    file: $i,
    line: 10,
    column: 37
  },
  name: "eventResetContextMenu",
  sid: "193cet:/src/features/meet/chat/model/message-context-menu.ts:eventResetContextMenu"
}), $l = A({
  messageId: "",
  opened: !1
}, {
  and: {
    name: "$chatContextMenuStore"
  },
  loc: {
    file: $i,
    line: 12,
    column: 38
  },
  name: "$chatContextMenuOpened",
  sid: "85cfcg:/src/features/meet/chat/model/message-context-menu.ts:$chatContextMenuOpened"
}).on(aa, (e, n) => n).on(Ul, () => ({
  messageId: "",
  opened: !1
})).reset(Kc);
var tt = "/src/features/meet/chat/model/message-delete.ts";
const Bl = h({
  loc: {
    file: tt,
    line: 5,
    column: 42
  },
  name: "eventMessageDeleteSelected",
  sid: "ywqjn7:/src/features/meet/chat/model/message-delete.ts:eventMessageDeleteSelected"
}), Hl = h({
  loc: {
    file: tt,
    line: 7,
    column: 39
  },
  name: "eventMessageDeleteReset",
  sid: "-xbb983:/src/features/meet/chat/model/message-delete.ts:eventMessageDeleteReset"
}), Jc = A(null, {
  loc: {
    file: tt,
    line: 8,
    column: 32
  },
  name: "$messageDeleteId",
  sid: "s90ulh:/src/features/meet/chat/model/message-delete.ts:$messageDeleteId"
}).on(Bl, (e, n) => n).reset(Hl, at.done), Fi = /* @__PURE__ */ g(({
  file: e,
  fileName: n,
  fileSize: i,
  mime: a,
  onDelete: t,
  progress: l,
  url: o
}) => {
  const s = me(), c = _(() => {
    t == null || t();
  }, [t]), k = L(() => {
    var u;
    return a != null && a.includes("image/") ? o != null ? (u = Sa(o, 48)) == null ? void 0 : u.uri : e != null ? URL.createObjectURL(e) : null : null;
  }, [e, a, o]);
  return /* @__PURE__ */ f(le, {
    alignItems: "center",
    flexDirection: "row",
    onPress: () => {
      o != null && sd.openURL(o);
    },
    py: "1",
    children: [/* @__PURE__ */ d(E, {
      alignItems: "center",
      bg: "rgba(255, 255, 255, 0.08)",
      borderRadius: "5",
      h: "48px",
      justifyContent: "center",
      mr: "3",
      w: "48px",
      children: k != null ? /* @__PURE__ */ d(bi, {
        borderRadius: "5",
        h: "48px",
        resizeMode: "cover",
        source: {
          uri: k
        },
        w: "48px"
      }) : /* @__PURE__ */ d(Ts, {
        fill: s.colors.blue[50]
      })
    }), /* @__PURE__ */ f(E, {
      flex: "1",
      children: [/* @__PURE__ */ d(y, {
        color: "gray.400",
        ellipsizeMode: "middle",
        fontSize: "14",
        fontWeight: "500",
        lineHeight: "20",
        mb: "1",
        numberOfLines: 1,
        children: n
      }), /* @__PURE__ */ d(y, {
        color: "text.200",
        ellipsizeMode: "tail",
        fontSize: "14",
        fontWeight: "500",
        lineHeight: "20",
        mb: "1",
        numberOfLines: 1,
        children: i
      }), l ? /* @__PURE__ */ d(ts, {
        size: "xs",
        value: l,
        w: "100%"
      }) : null]
    }), /* @__PURE__ */ d(E, {
      children: t != null ? /* @__PURE__ */ d(Ti, {
        onPress: c,
        children: /* @__PURE__ */ d(Ci, {
          fill: s.colors.gray[400],
          height: "32",
          width: "32"
        })
      }) : null
    })]
  });
});
Fi.displayName = "AttachedFile";
const Qc = [
  "B",
  "kB",
  "MB",
  "GB",
  "TB",
  "PB",
  "EB",
  "ZB",
  "YB"
], Zc = [
  "B",
  "kiB",
  "MiB",
  "GiB",
  "TiB",
  "PiB",
  "EiB",
  "ZiB",
  "YiB"
], em = [
  "b",
  "kbit",
  "Mbit",
  "Gbit",
  "Tbit",
  "Pbit",
  "Ebit",
  "Zbit",
  "Ybit"
], nm = [
  "b",
  "kibit",
  "Mibit",
  "Gibit",
  "Tibit",
  "Pibit",
  "Eibit",
  "Zibit",
  "Yibit"
], It = (e, n, i) => {
  let a = e;
  return typeof n == "string" || Array.isArray(n) ? a = e.toLocaleString(n, i) : (n === !0 || i !== void 0) && (a = e.toLocaleString(void 0, i)), a;
};
var im = (e, n) => {
  if (!Number.isFinite(e))
    throw new TypeError(`Expected a finite number, got ${typeof e}: ${e}`);
  n = Object.assign({ bits: !1, binary: !1 }, n);
  const i = n.bits ? n.binary ? nm : em : n.binary ? Zc : Qc;
  if (n.signed && e === 0)
    return ` 0 ${i[0]}`;
  const a = e < 0, t = a ? "-" : n.signed ? "+" : "";
  a && (e = -e);
  let l;
  if (n.minimumFractionDigits !== void 0 && (l = { minimumFractionDigits: n.minimumFractionDigits }), n.maximumFractionDigits !== void 0 && (l = Object.assign({ maximumFractionDigits: n.maximumFractionDigits }, l)), e < 1) {
    const k = It(e, n.locale, l);
    return t + k + " " + i[0];
  }
  const o = Math.min(Math.floor(n.binary ? Math.log(e) / Math.log(1024) : Math.log10(e) / 3), i.length - 1);
  e /= Math.pow(n.binary ? 1024 : 1e3, o), l || (e = e.toPrecision(3));
  const s = It(Number(e), n.locale, l), c = i[o];
  return t + s + " " + c;
};
const ta = /* @__PURE__ */ Td(im), zl = /* @__PURE__ */ g(({
  message: e
}) => {
  const n = me(), {
    t: i
  } = te(), a = _(() => {
    bl();
  }, []);
  return /* @__PURE__ */ f(E, {
    alignItems: "center",
    flexDirection: "row",
    mb: "2",
    children: [e.type === "editing" ? /* @__PURE__ */ d(Ms, {
      fill: n.colors.blue[100],
      height: "24",
      width: "24"
    }) : /* @__PURE__ */ d(Cs, {
      fill: n.colors.blue[100],
      height: "24",
      width: "24"
    }), /* @__PURE__ */ f(E, {
      borderLeftColor: "blue.100",
      borderLeftStyle: "solid",
      borderLeftWidth: "2",
      flex: "1",
      mx: "2",
      px: "10px",
      children: [/* @__PURE__ */ d(y, {
        color: "blue.100",
        fontSize: "14",
        fontWeight: "500",
        lineHeight: "18",
        children: e.type === "editing" ? i("CHAT.TITLE.EDIT", {
          defaultValue: "Редактирование"
        }) : i("CHAT.TITLE.REPLY", {
          defaultValue: "Ответ"
        })
      }), /* @__PURE__ */ d(y, {
        color: "gray.400",
        ellipsizeMode: "tail",
        fontSize: "14",
        fontWeight: "500",
        lineHeight: "18",
        numberOfLines: 1,
        children: e.type === "editing" ? e.oldMessage.text : e.repliedMessage.text
      })]
    }), /* @__PURE__ */ d(le, {
      onPress: a,
      w: "6",
      children: /* @__PURE__ */ d(Ci, {
        height: "24",
        width: "24"
      })
    })]
  });
});
zl.displayName = "ChatMessageEditPreview";
const am = {
  backgroundColor: "rgba(0, 0, 0, 0)",
  bottom: -12,
  left: -12,
  position: "absolute",
  right: -12,
  top: -12,
  zIndex: 1
}, dt = /* @__PURE__ */ g(() => D($l).opened ? /* @__PURE__ */ d("div", {
  "data-testid": "ContextMenuOverlay",
  style: am
}) : null);
dt.displayName = "ContextMenuOverlay";
var Gt;
const lt = typeof window < "u" && !!((Gt = window.document) != null && Gt.createElement), tm = lt && !!(window.addEventListener || window.attachEvent), dm = lt && !!window.screen, lm = !1, om = {
  /**
   * Denotes the currently running platform.
   * Can be one of ios, android, web.
   */
  OS: Ze.OS,
  /**
   * Returns the value with the matching platform.
   * Object keys can be any of ios, android, native, web, default.
   *
   * @ios ios, native, default
   * @android android, native, default
   * @web web, default
   */
  select: Ze.select,
  /**
   * Denotes if the DOM API is available in the current environment.
   * The DOM is not available in native React runtimes and Node.js.
   */
  isDOMAvailable: lt,
  /**
   * Denotes if the current environment can attach event listeners
   * to the window. This will return false in native React
   * runtimes and Node.js.
   */
  canUseEventListeners: tm,
  /**
   * Denotes if the current environment can inspect properties of the
   * screen on which the current window is being rendered. This will
   * return false in native React runtimes and Node.js.
   */
  canUseViewport: dm,
  /**
   * If the JavaScript is being executed in a remote JavaScript environment.
   * When `true`, synchronous native invocations cannot be executed.
   */
  isAsyncDebugging: lm
}, sm = om;
class rm extends Error {
  constructor(i, a) {
    super(a);
    oi(this, "code");
    oi(this, "info");
    this.code = i;
  }
}
globalThis.ExpoModulesCore_CodedError = rm;
var cm = { exports: {} };
(function(e, n) {
  (function(i, a) {
    e.exports = a();
  })(yd, function() {
    var i = /^v?(?:\d+)(\.(?:[x*]|\d+)(\.(?:[x*]|\d+)(\.(?:[x*]|\d+))?(?:-[\da-z\-]+(?:\.[\da-z\-]+)*)?(?:\+[\da-z\-]+(?:\.[\da-z\-]+)*)?)?)?$/i;
    function a(v, r) {
      return v.indexOf(r) === -1 ? v.length : v.indexOf(r);
    }
    function t(v) {
      var r = v.replace(/^v/, "").replace(/\+.*$/, ""), p = a(r, "-"), m = r.substring(0, p).split(".");
      return m.push(r.substring(p + 1)), m;
    }
    function l(v) {
      return isNaN(Number(v)) ? v : Number(v);
    }
    function o(v) {
      if (typeof v != "string")
        throw new TypeError("Invalid argument expected string");
      if (!i.test(v))
        throw new Error("Invalid argument not valid semver ('" + v + "' received)");
    }
    function s(v, r) {
      [v, r].forEach(o);
      for (var p = t(v), m = t(r), F = 0; F < Math.max(p.length - 1, m.length - 1); F++) {
        var S = parseInt(p[F] || 0, 10), N = parseInt(m[F] || 0, 10);
        if (S > N)
          return 1;
        if (N > S)
          return -1;
      }
      var T = p[p.length - 1], b = m[m.length - 1];
      if (T && b) {
        var M = T.split(".").map(l), C = b.split(".").map(l);
        for (F = 0; F < Math.max(M.length, C.length); F++) {
          if (M[F] === void 0 || typeof C[F] == "string" && typeof M[F] == "number")
            return -1;
          if (C[F] === void 0 || typeof M[F] == "string" && typeof C[F] == "number" || M[F] > C[F])
            return 1;
          if (C[F] > M[F])
            return -1;
        }
      } else if (T || b)
        return T ? -1 : 1;
      return 0;
    }
    var c = [
      ">",
      ">=",
      "=",
      "<",
      "<="
    ], k = {
      ">": [1],
      ">=": [0, 1],
      "=": [0],
      "<=": [-1, 0],
      "<": [-1]
    };
    function u(v) {
      if (typeof v != "string")
        throw new TypeError("Invalid operator type, expected string but got " + typeof v);
      if (c.indexOf(v) === -1)
        throw new TypeError("Invalid operator, expected one of " + c.join("|"));
    }
    return s.validate = function(v) {
      return typeof v == "string" && i.test(v);
    }, s.compare = function(v, r, p) {
      u(p);
      var m = s(v, r);
      return k[p].indexOf(m) > -1;
    }, s;
  });
})(cm);
var Ot;
(function(e) {
  e.GRANTED = "granted", e.UNDETERMINED = "undetermined", e.DENIED = "denied";
})(Ot || (Ot = {}));
const mm = {
  get name() {
    return "ExpoDocumentPicker";
  },
  async getDocumentAsync({ type: e = "*/*", multiple: n = !1 }) {
    if (!sm.isDOMAvailable)
      return { canceled: !0, assets: null };
    const i = document.createElement("input");
    return i.style.display = "none", i.setAttribute("type", "file"), i.setAttribute("accept", Array.isArray(e) ? e.join(",") : e), i.setAttribute("id", String(Math.random())), n && i.setAttribute("multiple", "multiple"), document.body.appendChild(i), new Promise((a, t) => {
      i.addEventListener("change", async () => {
        if (i.files) {
          const o = [];
          for (let s = 0; s < i.files.length; s++)
            o.push(um(i.files[s]));
          try {
            const s = await Promise.all(o);
            a({ canceled: !1, assets: s, output: i.files });
          } catch (s) {
            t(s);
          }
        } else
          a({ canceled: !0, assets: null });
        document.body.removeChild(i);
      });
      const l = new MouseEvent("click");
      i.dispatchEvent(l);
    });
  }
};
function um(e) {
  return new Promise((n, i) => {
    const a = e.type, t = new FileReader();
    t.onerror = () => {
      i(new Error("Failed to read the selected media because the operation failed."));
    }, t.onload = ({ target: l }) => {
      const o = l.result;
      n({
        uri: o,
        mimeType: a,
        name: e.name,
        lastModified: e.lastModified,
        size: e.size,
        file: e
      });
    }, t.readAsDataURL(e);
  });
}
async function km({ type: e = "*/*", copyToCacheDirectory: n = !0, multiple: i = !1 } = {}) {
  return typeof e == "string" && (e = [e]), await mm.getDocumentAsync({
    type: e,
    copyToCacheDirectory: n,
    multiple: i
  });
}
const vm = async () => {
  const e = await km();
  !e.canceled && e.output && Li(e.assets.map((n) => n.file).filter(Cn));
}, jl = /* @__PURE__ */ g(() => /* @__PURE__ */ d(le, {
  color: "gray.400",
  onPress: vm,
  children: /* @__PURE__ */ d(Gs, {})
}));
jl.displayName = "FilePicker";
const Wl = "textarea-enter-message", Sm = "_container_1v965_1", pm = "_row_1v965_5", Nm = "_rowInside_1v965_10", fm = "_button_1v965_20", Fm = "_containerShort_1v965_24", gm = "_gap_1v965_48", hm = "_inputContainer_1v965_53", bm = "_input_1v965_53", ym = "_buttonSend_1v965_88", Tm = "_buttonDisabled_1v965_92", Ce = {
  container: Sm,
  row: pm,
  rowInside: Nm,
  button: fm,
  containerShort: Fm,
  gap: gm,
  inputContainer: hm,
  input: bm,
  buttonSend: ym,
  buttonDisabled: Tm
}, ot = /* @__PURE__ */ g(({
  placeholder: e,
  shortView: n
}) => {
  const {
    t: i
  } = te(), a = D(Nn), [t, l] = G(!1), o = je(null), s = je(null), c = _(() => {
    o.current != null && (s.current != null && (clearTimeout(s.current), s.current = null), o.current.classList.add("focused"));
  }, [s]), k = _(() => {
    s.current != null && (clearTimeout(s.current), s.current = null), s.current = setTimeout(() => {
      o.current != null && o.current.classList.remove("focused");
    }, 500);
  }, []), u = _(() => {
    o.current != null && o.current.focus();
  }, []), v = _(() => {
    var C;
    ia(((C = o.current) == null ? void 0 : C.selectionStart) ?? 0);
  }, []), r = _(() => {
    o.current != null && (o.current.style.height = "24px", o.current.style.height = `${o.current.scrollHeight}px`);
  }, []), p = _(() => {
    if (o.current == null)
      return;
    const C = o.current.value, V = o.current.selectionStart;
    Dl({
      cursorPosition: V,
      text: C
    }), l(!1), r();
  }, [r]), m = _(() => {
    o.current != null && (l(!1), Cl(), r(), u());
  }, [u, r]), F = _((C) => {
    C.preventDefault(), m();
  }, [m]), S = _(() => {
    l((C) => !C), u();
  }, [u]), N = _((C) => {
    C != null && C.code === "Enter" && !C.getModifierState("Shift") && (C.preventDefault(), C.stopPropagation(), m());
  }, [m]), T = _(() => {
    o.current != null && ia(o.current.selectionStart);
  }, []), b = _((C) => {
    o.current != null && Al(C);
  }, []), M = _((C) => {
    if (C == null)
      return;
    const V = C.clipboardData.items;
    for (let X = 0; X < V.length; X++)
      if (V[X].type.includes("image")) {
        const re = V[X].getAsFile();
        if (re == null)
          return;
        Li([re]);
      }
  }, []);
  return ee(() => {
    o.current != null && (o.current.value = a.text, o.current.setSelectionRange(a.cursorPosition, a.cursorPosition), o.current.focus(), r());
  }, [a.cursorPosition, a.text, r]), /* @__PURE__ */ f(ye, {
    children: [/* @__PURE__ */ d(dt, {}), /* @__PURE__ */ f("form", {
      className: Ie(Ce.container, {
        [Ce.containerShort]: n
      }),
      onSubmit: F,
      children: [a.type === "editing" || a.type === "reply" ? /* @__PURE__ */ d(zl, {
        message: a
      }) : null, /* @__PURE__ */ f("div", {
        className: Ce.row,
        children: [n ? null : /* @__PURE__ */ f(ye, {
          children: [/* @__PURE__ */ d(jl, {}), /* @__PURE__ */ d("div", {
            className: Ce.gap
          })]
        }), /* @__PURE__ */ f("div", {
          className: Ce.rowInside,
          children: [/* @__PURE__ */ d("div", {
            className: Ce.inputContainer,
            children: /* @__PURE__ */ d("textarea", {
              ref: o,
              className: Ie(Ce.input),
              id: Wl,
              onBlur: k,
              onChange: p,
              onClick: v,
              onFocus: c,
              onKeyDown: N,
              onKeyUp: T,
              onPaste: M,
              placeholder: e ?? i("CHAT.MESSAGE", {
                defaultValue: "Сообщение..."
              })
            })
          }), /* @__PURE__ */ d("div", {
            className: Ce.gap
          }), /* @__PURE__ */ d("button", {
            className: Ce.button,
            onClick: S,
            type: "button",
            children: /* @__PURE__ */ d(qs, {})
          })]
        }), /* @__PURE__ */ d("button", {
          className: Ie(Ce.button, Ce.buttonSend, {
            // [css.buttonDisabled]: !Boolean(message.current),
            // [css.buttonSend]: Boolean(message.current),
          }),
          title: "Отправить сообщение",
          type: "submit",
          children: /* @__PURE__ */ d(Xs, {})
        }), /* @__PURE__ */ d(vs, {
          onEmojiSelect: b,
          visible: t
        })]
      })]
    })]
  });
});
ot.displayName = "MessageInputPanel";
const Mm = /* @__PURE__ */ g(() => {
  const {
    t: e
  } = te(), n = me(), i = D(Fl), a = _(() => {
    Sl();
  }, []);
  return i.length === 0 ? null : /* @__PURE__ */ d(E, {
    bg: "rgba(0, 0, 0, .7)",
    bottom: "0",
    justifyContent: "center",
    left: "0",
    p: "2",
    position: "absolute",
    right: "0",
    top: "0",
    zIndex: "9",
    children: /* @__PURE__ */ f(E, {
      bg: "#141414",
      borderRadius: "12",
      maxHeight: "100%",
      p: "6",
      shadow: "1",
      children: [/* @__PURE__ */ f(E, {
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "space-between",
        mb: "6",
        children: [/* @__PURE__ */ d(y, {
          color: "white",
          fontSize: "16px",
          fontWeight: "500",
          lineHeight: "20px",
          children: e("CHAT.TITLE.ATTACH_FILE", {
            defaultValue: "Отправить файл"
          })
        }), /* @__PURE__ */ d(Ti, {
          onPress: a,
          children: /* @__PURE__ */ d(Ci, {
            fill: n.colors.gray[400],
            height: "32",
            width: "32"
          })
        })]
      }), i.map((t) => /* @__PURE__ */ d(Fi, {
        file: t.file,
        fileName: t.file.name,
        fileSize: ta(t.file.size),
        mime: t.file.type,
        onDelete: () => {
          vl(t.file.name);
        },
        progress: t.state === "new" ? t.progress : void 0
      }, t.file.name + t.file.size)), /* @__PURE__ */ d(E, {
        mb: "8",
        mt: "6",
        children: /* @__PURE__ */ d(ot, {
          shortView: !0,
          placeholder: e("CHAT.SIGN", {
            defaultValue: "Подпись..."
          })
        })
      })]
    })
  });
});
Mm.displayName = "ChatAddAttachments";
const da = "chatBodyPanel", Cm = "_dropzoneContainer_m10z4_1", wm = "_dropzoneOverlay_m10z4_9", Vt = {
  dropzoneContainer: Cm,
  dropzoneOverlay: wm
}, st = /* @__PURE__ */ g(({
  children: e
}) => {
  const {
    getInputProps: n,
    getRootProps: i,
    isDragActive: a
  } = Js({
    noClick: !0,
    // accept: {
    //   "image/*": [".png", ".gif", ".jpeg", ".jpg"],
    // },
    onDrop: Li
  });
  return /* @__PURE__ */ f("div", {
    className: Vt.dropzoneContainer,
    ...i(),
    "data-testid": "ChatBodyPanel",
    id: da,
    children: [/* @__PURE__ */ d("input", {
      ...n()
    }), a ? /* @__PURE__ */ d("div", {
      className: Vt.dropzoneOverlay
    }) : null, e]
  });
});
st.displayName = "ChatBodyPanel";
const la = /* @__PURE__ */ g(({
  children: e,
  distance: n
}) => /* @__PURE__ */ f(y, {
  color: "white",
  fontSize: "14",
  lineHeight: "18",
  maxWidth: "100%",
  children: [e, /* @__PURE__ */ d(y, {
    display: "inline-flex",
    minWidth: "40px",
    opacity: "0",
    pr: "7",
    children: n
  })]
}));
la.displayName = "ChatItemText";
const oa = /* @__PURE__ */ g(/* @__PURE__ */ ms(({
  children: e
}, n) => /* @__PURE__ */ d(y, {
  ref: n,
  bottom: "6px",
  color: "text.100",
  fontSize: "12",
  fontWeight: "500",
  lineHeight: "20px",
  position: "absolute",
  right: "2",
  children: e
})));
oa.displayName = "ChatItemTime";
const rt = /* @__PURE__ */ g(({
  isOwner: e,
  message: n
}) => {
  if (n.type !== "RECEIVED")
    return null;
  const i = n.message.reactions;
  return /* @__PURE__ */ d(E, {
    flexDirection: e ? "row-reverse" : "row",
    flexWrap: "wrap",
    ml: e ? "-1" : "0",
    mr: e ? "-1" : "0",
    my: "1",
    children: i.map((a) => /* @__PURE__ */ d(Gl, {
      count: a.count,
      hasMyReaction: a.me,
      isOwner: e ?? !1,
      messageId: n.message.id,
      reaction: a.name
    }, a.name))
  });
});
rt.displayName = "ChatItemReaction";
const Gl = /* @__PURE__ */ g(({
  count: e,
  hasMyReaction: n,
  isOwner: i,
  messageId: a,
  reaction: t
}) => {
  const [l, o] = G(!1);
  return /* @__PURE__ */ f(le, {
    alignItems: "center",
    bg: "rgba(255, 255, 255, .04)",
    borderRadius: "12",
    flexDirection: "row",
    h: "24px",
    ml: i ? "0" : "1",
    mr: i ? "1" : "0",
    mt: "1",
    onHoverIn: () => o(!0),
    onHoverOut: () => o(!1),
    onPress: () => {
      n ? Tl({
        messageId: a,
        reaction: t
      }) : et({
        messageId: a,
        reaction: t
      });
    },
    px: "2",
    children: [/* @__PURE__ */ d(y, {
      fontSize: "12",
      lineHeight: "20",
      children: t
    }), /* @__PURE__ */ d(y, {
      color: l ? "white" : n ? "blue.100" : "text.100",
      fontSize: "12",
      fontWeight: "500",
      lineHeight: "20",
      ml: "1",
      position: "relative",
      top: "1px",
      children: e
    })]
  });
});
Gl.displayName = "Reaction";
const ql = /* @__PURE__ */ g(({
  children: e,
  message: n
}) => /* @__PURE__ */ f(E, {
  alignItems: "flex-start",
  flex: "1",
  flexDirection: "column",
  mr: "36px",
  mt: "1",
  children: [/* @__PURE__ */ d(E, {
    bg: "#232324",
    borderBottomLeftRadius: "2px",
    borderBottomRightRadius: "12px",
    borderTopRadius: "10px",
    maxWidth: "100%",
    minWidth: 90,
    position: "relative",
    px: "2",
    py: "10px",
    children: e
  }), /* @__PURE__ */ d(rt, {
    message: n
  })]
}));
ql.displayName = "Message";
const Em = "_emojiesInner_16r1q_1", _m = "_emojies_16r1q_1", Pt = {
  emojiesInner: Em,
  emojies: _m
}, Xl = /* @__PURE__ */ g(({
  onReactionSelect: e
}) => {
  const n = md.emojis, i = je(null);
  return ee(() => {
    const a = i.current;
    if (a != null) {
      const t = (l) => {
        a.scrollLeft += l.deltaY, l.preventDefault(), l.stopPropagation();
      };
      return a.addEventListener("wheel", t), () => {
        a.removeEventListener("wheel", t);
      };
    }
  }, [i]), ee(() => {
    const a = i.current;
    if (a != null) {
      const t = (l) => {
        l.code === "ArrowLeft" ? a.scrollLeft -= 10 : l.code === "ArrowRight" && (a.scrollLeft += 10);
      };
      return document.body.addEventListener("keydown", t, !1), () => {
        document.body.removeEventListener("keydown", t);
      };
    }
  }, []), /* @__PURE__ */ d("div", {
    ref: i,
    className: Pt.emojiesInner,
    children: /* @__PURE__ */ d("div", {
      className: Pt.emojies,
      children: ed.emojis.map((a) => {
        const t = n[a];
        return t == null || t.version !== 1 ? null : /* @__PURE__ */ d(Ss, {
          emoji: t.skins[0].native,
          onEmojiSelect: e
        }, t.id);
      })
    })
  });
});
Xl.displayName = "MessageReactionSelect";
const Rm = "_menuContainer_utwk9_1", xm = "_showMenu_utwk9_1", Dm = "_menu_utwk9_1", Am = "_menuItem_utwk9_20", Im = "_menuItemDelete_utwk9_33", Om = "_container_utwk9_41", Vm = "_containerOwner_utwk9_47", Pm = "_containerSelected_utwk9_51", Lm = "_containerHidden_utwk9_55", Um = "_emojies_utwk9_71", $m = "_bg_utwk9_96", we = {
  menuContainer: Rm,
  showMenu: xm,
  menu: Dm,
  menuItem: Am,
  menuItemDelete: Im,
  container: Om,
  containerOwner: Vm,
  containerSelected: Pm,
  containerHidden: Lm,
  emojies: Um,
  bg: $m
}, si = 208, Bm = 36, Un = 10, Hm = 20, sa = /* @__PURE__ */ g(({
  children: e,
  isMe: n,
  messageId: i
}) => {
  const a = D($l), {
    height: t,
    width: l
  } = En(), [o, s] = G({
    panelRect: void 0,
    rect: null,
    x: 0,
    y: 0
  }), c = je(null), k = je(!1), [u, v] = G({}), r = kn(), p = _((b) => {
    if (c.current == null)
      return;
    b.preventDefault(), (!a.opened || a.messageId !== i) && aa({
      messageId: i,
      opened: !0
    });
    const M = c.current.getBoundingClientRect(), C = document.querySelector(`#${da}`);
    s({
      panelRect: C == null ? void 0 : C.getBoundingClientRect(),
      rect: M,
      x: b.clientX,
      y: b.clientY
    });
  }, [i, a.messageId, a.opened]), m = _(() => {
    a.opened && a.messageId === i && (Ul(), k.current = !1);
  }, [i, a.messageId, a.opened]), F = _(() => {
    k.current = !0;
  }, []), S = _((b) => {
    if (c.current == null)
      return;
    const M = c.current.getBoundingClientRect(), C = document.querySelector(`#${da}`);
    s({
      panelRect: C == null ? void 0 : C.getBoundingClientRect(),
      rect: M,
      x: b.clientX,
      y: b.clientY
    }), k.current ? a.opened ? m() : aa({
      messageId: i,
      opened: !0
    }) : a.opened && m();
  }, [m, i, a.opened]), N = _((b) => {
    et({
      messageId: i,
      reaction: b
    }), m();
  }, [m, i]);
  ee(() => {
    const b = (M) => {
      var C;
      (C = c.current) != null && C.contains(M.target) || m();
    };
    return document.addEventListener("click", b, !1), document.addEventListener("contextmenu", b, !1), () => {
      document.removeEventListener("click", b), document.removeEventListener("contextmenu", b);
    };
  }, [m]), ee(() => {
    const b = (M) => {
      M.code === "Escape" && m();
    };
    return document.body.addEventListener("keydown", b, !1), () => {
      document.body.removeEventListener("keydown", b);
    };
  }, [m]);
  const T = L(() => {
    const b = [{
      className: we.menuItem,
      id: "reply",
      onClick: (M) => {
        M.stopPropagation(), hl(i), m();
      },
      title: "Ответить"
    }];
    return n && b.unshift({
      className: Ie(we.menuItem, we.menuItemDelete),
      id: "delete",
      onClick: (M) => {
        M.stopPropagation(), Bl(i), m();
      },
      title: "Удалить"
    }, {
      className: we.menuItem,
      id: "edit",
      onClick: (M) => {
        M.stopPropagation(), gl(i), m();
      },
      title: "Редактировать"
    }), b;
  }, [m, n, i]);
  return ee(() => {
    if (!(c.current == null || o.rect == null || o.panelRect == null) && a.opened && a.messageId === i) {
      const {
        panelRect: b,
        rect: M,
        x: C,
        y: V
      } = o, X = Math.abs(C - M.x), re = Math.abs(V - M.y), Q = T.length, I = Hm + Q * Bm + 40, R = {
        left: C + si < l - Un ? C - M.x : M.width - si,
        top: Number(b.bottom) > V + I + Un ? re + Un : M.height - I
      };
      r === "base" && (R.left = C - si > Un ? X - si - Un : 0), v(R);
    }
  }, [T.length, t, l, n, r, o, a.opened, a.messageId, i]), ee(() => {
    if (c.current == null)
      return;
    const b = c.current.parentNode;
    if (b == null || !(b instanceof HTMLDivElement))
      return;
    a.opened && a.messageId === i ? b.style.zIndex = "3" : b.style.zIndex = "auto";
    const M = document.querySelector(`#${Wl}`);
    M != null && M.classList.contains("focused") && M.focus();
  }, [i, a.messageId, a.opened]), /* @__PURE__ */ f("div", {
    ref: c,
    className: Ie(we.container, {
      [we.containerOwner]: n,
      [we.containerSelected]: a.opened && a.messageId === i,
      [we.containerHidden]: a.opened && a.messageId !== i
    }),
    onClick: S,
    onContextMenu: p,
    onTouchEnd: F,
    children: [e, a.opened && a.messageId === i && /* @__PURE__ */ f("div", {
      className: we.menuContainer,
      style: u,
      children: [/* @__PURE__ */ d("div", {
        className: we.emojies,
        children: /* @__PURE__ */ d(Xl, {
          onReactionSelect: N
        })
      }), /* @__PURE__ */ d("div", {
        className: we.menu,
        children: T.map(({
          className: b,
          id: M,
          onClick: C,
          title: V
        }) => /* @__PURE__ */ d(Yl, {
          className: b,
          onClick: C,
          children: V
        }, M))
      })]
    }), a.opened && a.messageId !== i ? /* @__PURE__ */ d(dt, {}) : null]
  });
});
sa.displayName = "MessageContainer";
const Yl = /* @__PURE__ */ g(({
  children: e,
  className: n,
  onClick: i
}) => /* @__PURE__ */ d("div", {
  className: n,
  onClick: i,
  children: e
}));
Yl.displayName = "MenuItem";
const Kl = /* @__PURE__ */ g(({
  children: e,
  message: n
}) => /* @__PURE__ */ f(E, {
  alignItems: "flex-end",
  flex: "1",
  flexDirection: "column",
  ml: "36px",
  children: [/* @__PURE__ */ d(E, {
    bg: "#232324",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "2px",
    borderTopRadius: "10px",
    maxWidth: "100%",
    position: "relative",
    px: "2",
    py: "10px",
    children: e
  }), /* @__PURE__ */ d(rt, {
    isOwner: !0,
    message: n
  })]
}));
Kl.displayName = "MyMessage";
const ra = /* @__PURE__ */ g(({
  message: e
}) => {
  const n = D(ni), i = L(() => e.text !== "" ? kl(e.text) : e.text, [e.text]);
  return /* @__PURE__ */ f(E, {
    borderLeftColor: "blue.50",
    borderLeftStyle: "solid",
    borderLeftWidth: "2",
    mb: "3",
    pl: "10px",
    children: [/* @__PURE__ */ d(y, {
      color: "blue.50",
      ellipsizeMode: "tail",
      fontSize: "14",
      fontWeight: "500",
      lineHeight: "18",
      numberOfLines: 1,
      children: e.nickname ?? tn(n.ref.get(e.member_id))
    }), /* @__PURE__ */ d(y, {
      color: "gray.400",
      ellipsizeMode: "tail",
      fontSize: "14",
      fontWeight: "400",
      lineHeight: "18",
      numberOfLines: 1,
      children: i
    })]
  });
});
ra.displayName = "RespondingMessage";
const ct = /* @__PURE__ */ g(({
  message: e,
  myUserId: n
}) => {
  var v, r, p;
  const i = me(), a = D(ni), {
    data: t
  } = Yn(wi, {
    skip: e.type !== "RECEIVED",
    variables: {
      id: e.type === "RECEIVED" ? e.message.member_id : ""
    }
  }), [l, o] = e.type === "RECEIVED" ? [Kt(e.message.created), e.message.text] : [e.sent, e.messageText], s = L(() => {
    const m = new Date(l), F = Qs(new Date(l)), S = Zs(m), N = Rt(new Date(l), "HH:mm");
    return F ? `Сегодня, в ${N}` : S ? `Вчера, в ${N}` : `${Rt(new Date(l), "dd.MM.yyyy")} в ${N}`;
  }, [l]), c = L(() => o !== "" ? kl(o) : o, [o]), k = L(() => {
    var m;
    if (e.type === "RECEIVED")
      return (m = e.message.embeds.find((F) => F.message != null)) == null ? void 0 : m.message;
  }, [e]), u = L(() => e.type === "RECEIVED" ? e.message.embeds.filter((m) => m.file != null).map((m) => m.file) : void 0, [e]);
  return e.type !== "RECEIVED" || n === e.message.user_id ? /* @__PURE__ */ d(sa, {
    isMe: !0,
    messageId: e.type === "RECEIVED" ? e.message.id : "",
    children: /* @__PURE__ */ f(Kl, {
      message: e,
      children: [k != null ? /* @__PURE__ */ d(ra, {
        message: k
      }) : null, u == null ? void 0 : u.map((m) => /* @__PURE__ */ d(Fi, {
        fileName: (m == null ? void 0 : m.filename) ?? "",
        fileSize: ta((m == null ? void 0 : m.size) ?? 0),
        mime: m == null ? void 0 : m.mime,
        onDelete: () => {
        },
        url: m == null ? void 0 : m.uri
      }, m == null ? void 0 : m.id)), /* @__PURE__ */ f(oa, {
        children: [s, " ", /* @__PURE__ */ d(Jl, {
          fill: e.type === "RECEIVED" ? i.colors.blue[50] : i.colors.gray[500],
          style: zm.mark
        })]
      }), /* @__PURE__ */ d(la, {
        distance: s,
        children: c
      })]
    })
  }) : /* @__PURE__ */ f(sa, {
    messageId: e.message.id,
    children: [k != null ? /* @__PURE__ */ d(ra, {
      message: k
    }) : null, u == null ? void 0 : u.map((m) => /* @__PURE__ */ d(Fi, {
      fileName: (m == null ? void 0 : m.filename) ?? "",
      fileSize: ta((m == null ? void 0 : m.size) ?? 0),
      mime: m == null ? void 0 : m.mime,
      url: m == null ? void 0 : m.uri
      // onDelete={() => {
      //   eventFileAttachmentsRemoved(item.file.fil);
      // }}
    }, m == null ? void 0 : m.id)), /* @__PURE__ */ f(E, {
      alignItems: "center",
      flexDirection: "row",
      mb: "6px",
      mr: "32px",
      children: [/* @__PURE__ */ d(Fa, {
        bg: "#262626",
        h: "32px",
        source: Sa((r = (v = a.ref.get(e.message.member_id)) == null ? void 0 : v.user) == null ? void 0 : r.avatar.uri, 32),
        w: "32px",
        children: /* @__PURE__ */ d(y, {
          color: "text.100",
          fontSize: 16,
          fontWeight: 400,
          children: pa(t == null ? void 0 : t.meet.member.get)
        })
      }), /* @__PURE__ */ d(y, {
        color: "text.100",
        ellipsizeMode: "tail",
        flex: "1",
        fontSize: "14",
        fontWeight: "500",
        lineHeight: "24",
        ml: "2",
        numberOfLines: 1,
        children: e.message.nickname ?? tn((p = a.ref.get(e.message.member_id)) == null ? void 0 : p.user)
      })]
    }), /* @__PURE__ */ f(ql, {
      message: e,
      children: [/* @__PURE__ */ d(fa, {
        label: s,
        children: /* @__PURE__ */ d(oa, {
          children: s
        })
      }), /* @__PURE__ */ d(la, {
        distance: s,
        children: c
      })]
    })]
  });
});
ct.displayName = "ChatMessageItem";
const Jl = /* @__PURE__ */ g((e) => /* @__PURE__ */ d(er, {
  fill: "none",
  height: 11,
  width: 16,
  ...e,
  children: /* @__PURE__ */ d(nr, {
    d: "M11.071.653a.457.457 0 0 0-.304-.102.493.493 0 0 0-.381.178l-6.19 7.636-2.405-2.272a.463.463 0 0 0-.336-.146.47.47 0 0 0-.343.146l-.311.31a.445.445 0 0 0-.14.337c0 .136.047.25.14.343l2.996 2.996a.724.724 0 0 0 .501.203.697.697 0 0 0 .546-.266l6.646-8.417a.497.497 0 0 0 .108-.299.441.441 0 0 0-.19-.374L11.07.653Zm-2.45 7.674a15.31 15.31 0 0 1-.546-.355.43.43 0 0 0-.317-.12.46.46 0 0 0-.362.158l-.292.33a.482.482 0 0 0 .013.666l1.079 1.073c.135.135.3.203.495.203a.699.699 0 0 0 .552-.267l6.62-8.391a.446.446 0 0 0 .109-.298.487.487 0 0 0-.178-.375l-.355-.273a.487.487 0 0 0-.673.076L8.62 8.327Z"
  })
}));
Jl.displayName = "Mark";
const zm = _n.create({
  mark: {
    marginLeft: 2
  }
}), jm = /* @__PURE__ */ g(() => {
  var s;
  const e = (s = hi()) == null ? void 0 : s.id, [n, i] = D([El, Vl]), a = je(null);
  ee(() => {
    a.current != null && a.current.scrollToEnd({
      animated: !0
    });
  }, []);
  const t = _((c) => /* @__PURE__ */ d(ct, {
    message: c.item,
    myUserId: e
  }, c.item.type === "RECEIVED" ? c.item.message.id : c.item.messageText), [e]), l = L(() => pe((c) => c, [], n), [n]), o = L(() => [e, i], [e, i]);
  return /* @__PURE__ */ d(st, {
    children: Kn(n) ? /* @__PURE__ */ d(ga, {
      ref: a,
      inverted: !0,
      data: l,
      extraData: o,
      keyExtractor: Wm,
      mb: "2",
      onEndReached: it,
      px: "4",
      renderItem: t
    }) : /* @__PURE__ */ d(yi, {
      flex: 1
    })
  });
});
jm.displayName = "ChatBody";
const Wm = (e) => e.type === "RECEIVED" ? e.message.id + e.message.updated + e.message.created : e.messageText, Gm = /* @__PURE__ */ g(() => {
  const e = D(Jc), {
    t: n
  } = te(), i = _(() => {
    e != null && at(e);
  }, [e]), a = _(() => {
    Hl();
  }, []);
  return e == null ? null : /* @__PURE__ */ d(E, {
    bg: "rgba(0, 0, 0, .7)",
    bottom: "0",
    justifyContent: "center",
    left: "0",
    p: "2",
    position: "absolute",
    right: "0",
    top: "0",
    zIndex: "9",
    children: /* @__PURE__ */ f(E, {
      bg: "#141414",
      borderRadius: "12",
      maxHeight: "100%",
      p: "6",
      shadow: "1",
      children: [/* @__PURE__ */ d(y, {
        color: "white",
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "20px",
        mb: "8",
        children: n("CHAT.TITLE.REMOVE_MESSAGE", {
          defaultValue: "Хотите удалить это сообщение?"
        })
      }), /* @__PURE__ */ f(Qe, {
        justifyContent: "flex-end",
        space: "2",
        children: [/* @__PURE__ */ d(le, {
          alignItems: "center",
          bg: "rgba(61, 61, 77, 0.25)",
          borderRadius: "22",
          h: "44",
          justifyContent: "center",
          onPress: a,
          px: "6",
          children: /* @__PURE__ */ d(y, {
            color: "white",
            fontSize: "14",
            fontWeight: "500",
            lineHeight: "24",
            children: n("BUTTON.CANCEL")
          })
        }), /* @__PURE__ */ d(le, {
          alignItems: "center",
          bg: "rgba(61, 61, 77, 0.25)",
          borderRadius: "22",
          h: "44",
          justifyContent: "center",
          onPress: i,
          px: "6",
          children: /* @__PURE__ */ d(y, {
            color: "red.50",
            fontSize: "14",
            fontWeight: "500",
            lineHeight: "24",
            children: n("BUTTON.DELETE", {
              defaultValue: "Удалить"
            })
          })
        })]
      })]
    })
  });
});
Gm.displayName = "ChatMessageDelete";
const qm = /* @__PURE__ */ g(() => {
  const e = _(() => {
    ei();
  }, []);
  return /* @__PURE__ */ d(E, {
    alignItems: "flex-end",
    display: {
      base: "flex",
      lg: "none"
    },
    pb: "3",
    pt: "5",
    px: "4",
    children: /* @__PURE__ */ d(le, {
      h: "32px",
      onPress: e,
      w: "32px",
      children: /* @__PURE__ */ d(td, {})
    })
  });
});
qm.displayName = "ChatMobileHead";
const Ql = /* @__PURE__ */ g(() => {
  var s;
  const e = (s = hi()) == null ? void 0 : s.id, {
    chatMessagesRemoteData: n,
    receivedMessagesEditedAt: i
  } = D({
    chatMessagesRemoteData: El,
    receivedMessagesEditedAt: Vl
  }), a = je(null);
  ee(() => {
    a.current != null && a.current.scrollToEnd({
      animated: !0
    });
  }, []);
  const t = _((c) => /* @__PURE__ */ d(ct, {
    message: c.item,
    myUserId: e
  }, c.item.type === "RECEIVED" ? c.item.message.id : c.item.messageText), [e]), l = L(() => pe((c) => c, [], n), [n]), o = L(() => [e, i], [e, i]);
  return /* @__PURE__ */ d(st, {
    children: Kn(n) ? /* @__PURE__ */ d(ga, {
      ref: a,
      inverted: !0,
      data: l,
      extraData: o,
      keyExtractor: Xm,
      mb: "2",
      onEndReached: it,
      px: "4",
      renderItem: t
    }) : /* @__PURE__ */ d(yi, {
      flex: 1
    })
  });
});
Ql.displayName = "MessagesList";
const Xm = (e) => e.type === "RECEIVED" ? e.message.id + e.message.updated + e.message.created : e.messageText, Ym = /* @__PURE__ */ g(() => /* @__PURE__ */ f(ye, {
  children: [/* @__PURE__ */ d(E, {
    bg: "#141414",
    borderBottomRadius: "12",
    flex: "1",
    children: /* @__PURE__ */ d(Ql, {})
  }), /* @__PURE__ */ d(E, {
    bg: "#141414",
    borderRadius: "12",
    mt: "2",
    children: /* @__PURE__ */ d(ot, {})
  })]
}));
Ym.displayName = "ChatPanel";
const Km = /* @__PURE__ */ g(() => {
  const e = me(), n = _(() => {
    ei();
  }, []);
  return /* @__PURE__ */ d(E, {
    flexDirection: "row",
    h: "48px",
    justifyContent: "flex-end",
    p: "2",
    children: /* @__PURE__ */ d(Ti, {
      onPress: n,
      children: /* @__PURE__ */ d(Ci, {
        fill: e.colors.gray[400],
        height: "32",
        width: "32"
      })
    })
  });
});
Km.displayName = "ChatPanelHead";
const Lt = W.object({
  conditions: W.object({
    join: W.object({
      // emails: z.boolean().optional().nullable(),
      link: W.boolean().optional().nullable(),
      // password: z.boolean().optional().nullable(),
      password: W.string().min(2).max(30).optional().nullable().transform((e) => e === "" ? void 0 : e)
    }),
    start: W.object({
      always: W.boolean().optional().nullable(),
      time: W.any().optional().nullable()
    }).optional().nullable(),
    terminate: W.object({
      empty: W.boolean().optional().nullable(),
      force: W.boolean().optional().nullable(),
      time: W.any().optional().nullable()
    }).optional().nullable()
  }),
  description: W.string().min(1, "должно содержать минимум один символ").optional().or(W.literal("")).transform((e) => e === "" ? void 0 : e),
  joinContactsEmails: W.array(W.object({
    value: W.string()
  })).optional().nullable(),
  pincode: W.string().optional().nullable().transform((e) => e === "" ? void 0 : e),
  recording: W.boolean().optional().nullable(),
  title: W.string({
    required_error: x("VALIDATION.ERRORS.REQUIRED")
  }).min(1, {
    message: x("VALIDATION.ERRORS.AT_LEAST_ONE", {
      defaultValue: "Название должно содержать минимум один символ"
    })
  }),
  vanity_name: W.string().min(4, "минимум 4 символа").regex(/^[a-z\d-.]+$/, 'содержит невалидные символы, разрешены цифры, английские буквы в нижнем регистре и тире "-"').optional().or(W.literal("")).transform((e) => e === "" ? void 0 : e)
}), Zl = /* @__PURE__ */ g(({
  meetSpace: e,
  onSuccess: n,
  type: i,
  variant: a
}) => {
  var X, re, Q;
  const [t, l] = G(!1), [o, s] = G(!1), c = is(), k = (c == null ? void 0 : c.type) === "authenticated" && c.user.premium, [u, {
    loading: v
  }] = Mn(vd, {
    refetchQueries: [ud, lr, Pk]
  }), {
    control: r,
    formState: {
      errors: p,
      isValid: m
    },
    handleSubmit: F,
    setError: S
  } = Rn({
    defaultValues: {
      /* @ts-expect-error TODO null vs undefined */
      title: "",
      ...e,
      ...e == null ? {
        conditions: {
          join: {
            link: !0
          }
        }
      } : void 0,
      joinContactsEmails: (Q = (re = (X = e == null ? void 0 : e.conditions) == null ? void 0 : X.join.contacts) == null ? void 0 : re.email) == null ? void 0 : Q.map((I) => ({
        value: I
      }))
    },
    mode: "all",
    resolver: Zn(Lt)
  }), N = _(async (I) => {
    const {
      joinContactsEmails: R,
      ...Y
    } = Lt.parse(I);
    try {
      await u({
        variables: {
          id: e == null ? void 0 : e.id,
          ...Y,
          conditions: {
            ...Y.conditions,
            join: {
              ...Y.conditions.join,
              contacts: {
                email: R == null ? void 0 : R.map((U) => U.value)
              }
            }
            // ...(recording ? { terminate: { empty: true } } : {}),
          }
          // recording,
        }
      }), n();
    } catch (U) {
      U instanceof ua && S("root", {
        message: U.message === "rpc error: code = Unknown desc = permission denied" ? "Доступ запрещен" : U.message,
        type: "serverError"
      });
    }
  }, [u, e == null ? void 0 : e.id, n, S]), {
    append: T,
    fields: b,
    remove: M
  } = cd({
    control: r,
    name: "joinContactsEmails"
  }), C = _(() => {
    b.length === 0 && T({
      value: ""
    }), s((I) => !I);
  }, [T, b.length]), V = i === "create" ? a === "room" ? x("ROOM.EDIT_ROOM.CREATE_ROOM") : x("ROOM.EDIT_ROOM.CREATE_EVENT") : a === "room" ? x("ROOM.EDIT_ROOM.EDIT_ROOM") : x("ROOM.EDIT_ROOM.EDIT_EVENT");
  return /* @__PURE__ */ d(E, {
    alignItems: "stretch",
    flex: {
      lg: "1"
    },
    justifyContent: "space-between",
    children: /* @__PURE__ */ f(eo, {
      children: [/* @__PURE__ */ f(E, {
        width: "100%",
        children: [/* @__PURE__ */ f(Ee, {
          isRequired: !0,
          mb: {
            base: 6,
            lg: 10
          },
          children: [/* @__PURE__ */ d(rn, {
            mb: "2",
            children: V
          }), /* @__PURE__ */ d(de, {
            control: r,
            name: "title",
            render: ({
              field: I,
              fieldState: {
                error: R
              }
            }) => /* @__PURE__ */ d(cn, {
              errorMessage: R == null ? void 0 : R.message,
              isInvalid: !!R,
              mb: 0,
              onBlur: I.onBlur,
              onChangeText: I.onChange,
              placeholder: x("ROOM.EDIT.TITLE_PLACEHOLDER"),
              value: I.value,
              withAnimation: !1
            })
          })]
        }), /* @__PURE__ */ f(Ee, {
          mb: {
            base: 6,
            lg: 10
          },
          children: [/* @__PURE__ */ d(rn, {
            mb: "2",
            children: x("ROOM.EDIT.VANITY_NAME")
          }), /* @__PURE__ */ d(de, {
            control: r,
            name: "vanity_name",
            render: ({
              field: I,
              fieldState: {
                error: R
              }
            }) => /* @__PURE__ */ d(cn, {
              errorMessage: R == null ? void 0 : R.message,
              isInvalid: !!R,
              mb: 0,
              onBlur: I.onBlur,
              onChangeText: I.onChange,
              placeholder: x("ROOM.EDIT.VANITY_NAME_PLACEHOLDER"),
              value: I.value,
              withAnimation: !1
            })
          })]
        }), /* @__PURE__ */ f(Ee, {
          mb: {
            base: 6,
            lg: 10
          },
          children: [/* @__PURE__ */ d(rn, {
            mb: "2",
            children: x("ROOM.EDIT.DESCRIPTION")
          }), /* @__PURE__ */ d(de, {
            control: r,
            name: "description",
            render: ({
              field: I,
              fieldState: {
                error: R
              }
            }) => /* @__PURE__ */ d(cn, {
              errorMessage: R == null ? void 0 : R.message,
              isInvalid: !!R,
              mb: 0,
              onBlur: I.onBlur,
              onChangeText: I.onChange,
              placeholder: x("ROOM.EDIT.DESCRIPTION_PLACEHOLDER"),
              value: I.value,
              withAnimation: !1
            })
          })]
        }), a === "event" ? /* @__PURE__ */ f(ye, {
          children: [/* @__PURE__ */ d(yn, {
            color: "black",
            fontSize: "16",
            fontWeight: "600",
            lineHeight: "24",
            mb: {
              base: 4,
              lg: 6
            },
            mt: "0",
            children: x("ROOM.CREATE.DATE_AND_TIME_ACTIVITY", {
              defaultValue: "Время активности встречи"
            })
          }), /* @__PURE__ */ f(E, {
            mb: "3",
            children: [/* @__PURE__ */ d(rn, {
              display: {
                base: "none",
                lg: "flex"
              },
              mb: "2",
              children: x("ROOM.CREATE.DATE_AND_TIME", {
                defaultValue: "Дата и время"
              })
            }), /* @__PURE__ */ d(rn, {
              display: {
                base: "flex",
                lg: "none"
              },
              mb: "2",
              children: x("ROOM.CREATE.DATE_AND_TIME_START", {
                defaultValue: "Дата и время начала встречи"
              })
            }), /* @__PURE__ */ f(E, {
              alignItems: "flex-start",
              flex: "1",
              flexDirection: {
                base: "column",
                lg: "row"
              },
              children: [/* @__PURE__ */ d(de, {
                control: r,
                name: "conditions.start.time",
                render: ({
                  field: {
                    onBlur: I,
                    onChange: R,
                    value: Y
                  }
                }) => /* @__PURE__ */ d(wt, {
                  mode: "datetime",
                  onBlur: I,
                  onChange: R,
                  value: Y
                })
              }), /* @__PURE__ */ d(y, {
                color: "black",
                display: {
                  base: "none",
                  lg: "flex"
                },
                fontSize: "16",
                fontWeight: "500",
                h: "12",
                justifyContent: "center",
                lineHeight: "48",
                textAlign: "center",
                w: "10",
                children: "–"
              }), /* @__PURE__ */ d(rn, {
                display: {
                  base: "flex",
                  lg: "none"
                },
                mb: "2",
                mt: "2",
                children: x("ROOM.CREATE.DATE_AND_TIME_STOP", {
                  defaultValue: "Дата и время окончания встречи"
                })
              }), /* @__PURE__ */ d(de, {
                control: r,
                name: "conditions.terminate.time",
                render: ({
                  field: {
                    onBlur: I,
                    onChange: R,
                    value: Y
                  }
                }) => /* @__PURE__ */ d(wt, {
                  mode: "datetime",
                  onBlur: I,
                  onChange: R,
                  value: Y
                })
              })]
            })]
          }), /* @__PURE__ */ d(Ee, {
            children: /* @__PURE__ */ d(de, {
              control: r,
              name: "conditions.start.always",
              render: ({
                field: {
                  onChange: I,
                  value: R
                }
              }) => /* @__PURE__ */ f(le, {
                flexDir: "row",
                onPress: () => I(!R),
                children: [/* @__PURE__ */ d(qe, {
                  checked: !!R,
                  view: {
                    mr: 2
                  }
                }), /* @__PURE__ */ d(y, {
                  children: x("ROOM.CREATE.AUTOSTART", {
                    defaultValue: "Стартует автоматически"
                  })
                })]
              })
            })
          }), /* @__PURE__ */ d(Ee, {
            children: /* @__PURE__ */ d(de, {
              control: r,
              name: "conditions.terminate.empty",
              render: ({
                field: {
                  onChange: I,
                  value: R
                }
              }) => /* @__PURE__ */ f(le, {
                flexDir: "row",
                onPress: () => I(!R),
                children: [/* @__PURE__ */ d(qe, {
                  checked: !!R,
                  view: {
                    mr: 2
                  }
                }), /* @__PURE__ */ d(y, {
                  children: x("ROOM.CREATE.AUTOCLOSE", {
                    defaultValue: "Завершается при выходе последнего участника"
                  })
                })]
              })
            })
          }), /* @__PURE__ */ d(Ee, {
            children: /* @__PURE__ */ d(de, {
              control: r,
              name: "conditions.terminate.force",
              render: ({
                field: {
                  onChange: I,
                  value: R
                }
              }) => /* @__PURE__ */ f(le, {
                flexDir: "row",
                onPress: () => I(!R),
                children: [/* @__PURE__ */ d(qe, {
                  checked: !!R,
                  view: {
                    mr: 2
                  }
                }), /* @__PURE__ */ d(y, {
                  children: x("ROOM.CREATE.CLOSE_BY_HAND", {
                    defaultValue: "Можно завершить вручную"
                  })
                })]
              })
            })
          })]
        }) : null, k ? /* @__PURE__ */ d(Ee, {
          children: /* @__PURE__ */ d(de, {
            control: r,
            name: "recording",
            render: ({
              field: {
                onChange: I,
                value: R
              }
            }) => /* @__PURE__ */ f(E, {
              children: [/* @__PURE__ */ f(le, {
                flexDir: "row",
                onPress: () => I(!R),
                children: [/* @__PURE__ */ d(qe, {
                  checked: !!R,
                  view: {
                    mr: 2
                  }
                }), /* @__PURE__ */ d(y, {
                  children: x("ROOM.CREATE.ENABLE_RECORDING", {
                    defaultValue: "Разрешена запись аудио"
                  })
                })]
              }), R ? /* @__PURE__ */ f(E, {
                flexDirection: "row",
                mt: 1,
                children: [/* @__PURE__ */ d(qe, {
                  checked: !0,
                  disabled: !0,
                  view: {
                    mr: 2
                  }
                }), /* @__PURE__ */ d(y, {
                  children: x("ROOM.CREATE.AUTOCLOSE", {
                    defaultValue: "Завершается при выходе последнего участника"
                  })
                })]
              }) : null]
            })
          })
        }) : null, /* @__PURE__ */ d(yn, {
          color: "black",
          fontSize: "16",
          fontWeight: "600",
          lineHeight: "24",
          mb: {
            base: 4,
            lg: 6
          },
          mt: 4,
          children: x("ROOM.CREATE.ACCESS", {
            defaultValue: "Доступ"
          })
        }), /* @__PURE__ */ d(Ee, {
          children: /* @__PURE__ */ d(de, {
            control: r,
            name: "conditions.join.link",
            render: ({
              field: {
                onChange: I,
                value: R
              }
            }) => /* @__PURE__ */ f(le, {
              flexDir: "row",
              onPress: () => I(!R),
              children: [/* @__PURE__ */ d(qe, {
                checked: !!R,
                view: {
                  mr: 2
                }
              }), /* @__PURE__ */ d(y, {
                children: x("ROOM.CREATE.BY_LINK", {
                  defaultValue: "По ссылке"
                })
              })]
            })
          })
        }), /* @__PURE__ */ f(Ee, {
          flexDir: "column",
          isInvalid: !!p.title,
          children: [/* @__PURE__ */ f(le, {
            flexDir: "row",
            onPress: () => l((I) => !I),
            children: [/* @__PURE__ */ d(qe, {
              checked: t,
              view: {
                mr: 2
              }
            }), /* @__PURE__ */ d(y, {
              children: x("ROOM.CREATE.BY_PASSWORD", {
                defaultValue: "По паролю"
              })
            })]
          }), t ? /* @__PURE__ */ d(de, {
            control: r,
            name: "conditions.join.password",
            render: ({
              field: {
                onBlur: I,
                onChange: R,
                value: Y
              },
              fieldState: {
                error: U,
                isDirty: ve
              }
            }) => /* @__PURE__ */ d(cn, {
              errorMessage: U == null ? void 0 : U.message,
              isInvalid: !!U && ve,
              mb: "4",
              mt: "3",
              onBlur: I,
              onChangeText: R,
              type: "password",
              value: Y ?? void 0,
              withAnimation: !1
            })
          }) : null]
        }), /* @__PURE__ */ f(Ee, {
          flexDirection: "column",
          children: [/* @__PURE__ */ f(le, {
            flexDir: "row",
            onPress: () => C(),
            children: [/* @__PURE__ */ d(qe, {
              checked: o,
              view: {
                mr: 2
              }
            }), /* @__PURE__ */ d(y, {
              children: x("ROOM.CREATE.BY_EMAILS", {
                defaultValue: "Список по e-mail"
              })
            })]
          }), o ? /* @__PURE__ */ f(ye, {
            children: [/* @__PURE__ */ d(zn, {
              mb: "4",
              mt: "3",
              space: 3,
              children: b.map((I, R) => /* @__PURE__ */ f(Qe, {
                alignItems: "center",
                space: 1,
                children: [/* @__PURE__ */ d(E, {
                  flex: "1",
                  children: /* @__PURE__ */ d(de, {
                    control: r,
                    name: `joinContactsEmails[${R}].value`,
                    render: ({
                      field: {
                        name: Y,
                        onBlur: U,
                        onChange: ve,
                        value: sn
                      }
                    }) => /* @__PURE__ */ d(cn, {
                      mb: "0",
                      nativeID: Y,
                      onBlur: U,
                      onChange: ve,
                      value: sn,
                      w: "100%"
                    })
                  })
                }), /* @__PURE__ */ d(le, {
                  alignItems: "center",
                  h: "36px",
                  justifyContent: "center",
                  onPress: () => M(R),
                  w: "36px",
                  children: /* @__PURE__ */ d(Qt, {})
                })]
              }, I.id))
            }), /* @__PURE__ */ d(E, {
              flexDir: "row-reverse",
              pr: "10",
              children: /* @__PURE__ */ d(We, {
                h: "36",
                onPress: () => T({
                  value: ""
                }),
                pl: "2",
                pr: "4",
                variant: "primary",
                children: /* @__PURE__ */ f(E, {
                  alignItems: "center",
                  flexDirection: "row",
                  children: [/* @__PURE__ */ d(ws, {
                    fill: ze.colors.white,
                    height: "24",
                    width: "24"
                  }), /* @__PURE__ */ d(y, {
                    color: "white",
                    fontSize: "14",
                    fontWeight: "500",
                    ml: "2",
                    children: x("BUTTON.ADD_EMAIL", {
                      defaultValue: "Добавить email"
                    })
                  })]
                })
              })
            })]
          }) : null]
        })]
      }), p.root != null ? /* @__PURE__ */ d(ps, {
        message: p.root.message
      }) : null, /* @__PURE__ */ d(E, {
        alignSelf: "flex-start",
        mt: 6,
        w: {
          base: "100%",
          lg: "auto"
        },
        children: /* @__PURE__ */ d(We, {
          color: "white",
          disabled: !m,
          isLoadingText: "Сохранение",
          loading: v,
          onPress: F(N),
          variant: "primary",
          w: {
            base: "100%"
          },
          children: /* @__PURE__ */ d(y, {
            color: "white",
            fontSize: "14",
            fontWeight: "500",
            children: x(i === "create" ? "BUTTON.CREATE" : "BUTTON.SAVE", {
              defaultValue: i === "create" ? "Создать" : "Сохранить"
            })
          })
        })
      })]
    })
  });
});
Zl.displayName = "EditMeetSpaceForm";
const Ee = /* @__PURE__ */ g((e) => /* @__PURE__ */ d(He, {
  mb: "3",
  ...e
}));
Ee.displayName = "MyFormControl";
const rn = /* @__PURE__ */ g(({
  isSwitch: e,
  ...n
}) => /* @__PURE__ */ d(He.Label, {
  alignItems: "center",
  flexDirection: e ? {
    lg: "row-reverse"
  } : void 0,
  mr: {
    lg: 2
  },
  ...n
}));
rn.displayName = "MyFormControlLabel";
const eo = /* @__PURE__ */ g((e) => {
  const {
    height: n
  } = En();
  return /* @__PURE__ */ d(ha, {
    maxHeight: {
      base: n - 94,
      lg: Math.min(n - 100, 716)
    },
    mb: "4",
    mr: {
      base: -2,
      lg: 0
    },
    pr: {
      base: 2,
      lg: 0
    },
    py: {
      base: 2,
      lg: 5
    },
    children: e.children
  });
});
eo.displayName = "FormContent";
const no = /* @__PURE__ */ g(({
  children: e,
  onClose: n,
  title: i
}) => /* @__PURE__ */ d(rd, {
  transparent: !0,
  visible: !0,
  onRequestClose: n,
  children: /* @__PURE__ */ f(E, {
    bg: "white",
    borderBottomLeftRadius: {
      base: 0,
      lg: 24
    },
    borderTopLeftRadius: {
      base: 0,
      lg: 24
    },
    flex: 1,
    marginLeft: {
      base: 0,
      lg: "auto"
    },
    p: 2,
    width: {
      base: "100%",
      lg: "50%"
    },
    children: [/* @__PURE__ */ f(Qe, {
      alignItems: "center",
      justifyContent: "space-between",
      children: [/* @__PURE__ */ d(y, {
        color: "black",
        fontSize: "24",
        fontWeight: "500",
        lineHeight: "24",
        children: i
      }), /* @__PURE__ */ d(ba, {
        _focus: {
          bg: "transparent"
        },
        _hover: {
          bg: "transparent"
        },
        _pressed: {
          bg: "transparent"
        },
        bg: "transparent",
        height: "6",
        mr: 3,
        onPress: n,
        variant: "unstyled",
        width: "6",
        children: /* @__PURE__ */ d(Es, {})
      })]
    }), e]
  })
}));
no.displayName = "LayoutModal";
const Jm = /* @__PURE__ */ g(({
  onClose: e,
  params: n
}) => {
  const {
    t: i
  } = te(), {
    data: a,
    error: t,
    loading: l
  } = Yn(kd, {
    skip: n.type === "create",
    variables: {
      id: n.type === "edit" ? n.roomId : (
        /* impossible case — will be skipped */
        ""
      )
    }
  }), o = a == null ? void 0 : a.user.meet.get.space, s = n.type === "create" ? n.variant : gr(o), c = n.type === "create" ? i(s === "room" ? "ROOM.EDIT_ROOM.CREATE_ROOM" : "ROOM.EDIT_ROOM.CREATE_EVENT") : i(s === "room" ? "ROOM.EDIT_ROOM.EDIT_ROOM" : "ROOM.EDIT_ROOM.EDIT_EVENT");
  return /* @__PURE__ */ f(no, {
    onClose: e,
    title: c,
    children: [t != null ? /* @__PURE__ */ d(Qn, {
      error: t
    }) : null, l ? /* @__PURE__ */ d(jn, {
      size: "lg"
    }) : /* @__PURE__ */ d(Zl, {
      meetSpace: o,
      onSuccess: e,
      type: n.type,
      variant: s
    })]
  });
});
Jm.displayName = "EditMeetSpace";
const gS = (e) => e != null && e.createEvent ? {
  roomId: void 0,
  type: "create",
  variant: "event"
} : e != null && e.createRoom ? {
  roomId: void 0,
  type: "create",
  variant: "room"
} : e != null && e.edit ? {
  roomId: e.edit,
  type: "edit"
} : null;
var Bi = "/src/features/meet/focused-peer/model/index.ts";
const Hi = h({
  loc: {
    file: Bi,
    line: 12,
    column: 34
  },
  name: "eventMemberFocused",
  sid: "j8uxwn:/src/features/meet/focused-peer/model/index.ts:eventMemberFocused"
}), io = A({
  disallowAtomaticChange: !1,
  memberId: null
}, {
  loc: {
    file: Bi,
    line: 14,
    column: 25
  },
  name: "$focusedMemberId",
  sid: "-6q48vs:/src/features/meet/focused-peer/model/index.ts:$focusedMemberId"
});
io.on(Hi, (e, n) => n == null ? {
  disallowAtomaticChange: !0,
  memberId: null
} : e.memberId === n ? {
  disallowAtomaticChange: !0,
  memberId: null
} : {
  disallowAtomaticChange: !0,
  memberId: n
}).on(w({
  and: [{
    clock: Fe,
    source: {
      currentMeetSpaceMemberMe: dn
    },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    fn: (e, n) => ({
      ...e,
      peers: n
    })
  }],
  or: {
    loc: {
      file: Bi,
      line: 32,
      column: 4
    },
    sid: "hpgo9r:/src/features/meet/focused-peer/model/index.ts:"
  }
}), (e, n) => {
  var a, t;
  const i = n.peers.find((l) => l.id === e.memberId);
  if (e.disallowAtomaticChange || e.memberId != null && i == null)
    return e;
  if (e.memberId == null) {
    const l = n.peers.filter((o) => o.media.video === De.MediaVideoScreen);
    if (l.length === 1 && ((t = (a = n.currentMeetSpaceMemberMe) == null ? void 0 : a.peer) == null ? void 0 : t.id) !== l[0].id)
      return {
        disallowAtomaticChange: !0,
        memberId: l[0].id
      };
  }
  return e;
});
const hS = _e({
  and: [{
    focusedMemberId: io,
    members: ni,
    peers: Fe
  }, ({
    focusedMemberId: e,
    members: n,
    peers: i
  }) => {
    const a = i.find((t) => t.id === e.memberId);
    return e.memberId != null && a != null ? {
      member: n.ref.get(e.memberId),
      peer: a
    } : null;
  }],
  or: {
    loc: {
      file: Bi,
      line: 67,
      column: 28
    },
    name: "$focusedPeer",
    sid: "okq1as:/src/features/meet/focused-peer/model/index.ts:$focusedPeer"
  }
}), mt = /* @__PURE__ */ g(({
  meetSpace: e
}) => /* @__PURE__ */ d(nd, {
  mobileView: "full",
  textForCopy: Xt(e),
  view: {
    bg: "blue.50",
    color: "white"
  },
  children: /* @__PURE__ */ d(y, {
    color: "white",
    fontSize: "14",
    fontWeight: "500",
    lineHeight: "24",
    children: Yo(e)
  })
}));
mt.displayName = "InviteToCallButton";
const Qm = /* @__PURE__ */ g(({
  height: e,
  meetSpace: n,
  width: i
}) => {
  var t;
  const {
    t: a
  } = te();
  return /* @__PURE__ */ d(E, {
    display: {
      base: "none",
      lg: "flex"
    },
    h: e,
    p: {
      base: 1,
      lg: 2
    },
    w: i,
    children: /* @__PURE__ */ d(yi, {
      bg: "#141414",
      borderRadius: "12",
      flex: "1",
      children: (t = n.conditions) != null && t.join.link ? /* @__PURE__ */ f(ye, {
        children: [/* @__PURE__ */ d(y, {
          color: "white",
          fontSize: "24",
          fontWeight: "500",
          lineHeight: "24",
          mb: "5",
          textAlign: "center",
          children: a("CALL.INVITE_MESSAGE", {
            defaultValue: "Пригласите других участников"
          })
        }), /* @__PURE__ */ d(mt, {
          meetSpace: n
        })]
      }) : /* @__PURE__ */ d(y, {
        color: "gray.500",
        fontSize: "24",
        fontWeight: "500",
        lineHeight: "24",
        mb: "5",
        textAlign: "center",
        children: a("CALL.INVITE_MESSAGE_WAITING")
      })
    })
  });
});
Qm.displayName = "InviteToCallCard";
const zi = /* @__PURE__ */ g(({
  mobile: e
}) => /* @__PURE__ */ d(E, {
  left: 1,
  position: "absolute",
  top: 1,
  zIndex: 1,
  children: /* @__PURE__ */ d(Ma, {
    fill: ze.colors.yellow[500],
    height: "24",
    width: "24"
  })
}));
zi.displayName = "RaiseHandPopup";
const Zm = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "MeetUpdateReaction"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "emotion"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "gesture"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "rtc"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "reaction"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "emotion"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "emotion"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "gesture"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "gesture"
                    }
                  }
                }]
              }]
            }
          }]
        }
      }]
    }
  }]
}, eu = "_emojiContainer_jg6pi_1", nu = {
  emojiContainer: eu
}, iu = /* @__PURE__ */ g(() => {
  const [e] = Mn(Zm), n = md.emojis, i = _((a) => {
    e({
      variables: {
        emotion: a
      }
    });
  }, [e]);
  return /* @__PURE__ */ d(ha, {
    horizontal: !0,
    maxWidth: "900",
    w: "90%",
    children: /* @__PURE__ */ d("div", {
      className: nu.emojiContainer,
      children: /* @__PURE__ */ d(Qe, {
        space: "2",
        children: ed.emojis.map((a) => {
          const t = n[a];
          return t == null || t.version !== 1 ? null : /* @__PURE__ */ d(ao, {
            emoji: t.skins[0].native,
            onPress: () => i(t.skins[0].native)
          }, t.id);
        })
      })
    })
  });
});
iu.displayName = "MeetSpaceReaction";
const ao = /* @__PURE__ */ g(({
  emoji: e,
  ...n
}) => {
  const [i, a] = G(!1), t = L(() => i ? "rgba(255, 255, 255, .12)" : "rgba(255, 255, 255, 0.08)", [i]);
  return /* @__PURE__ */ d(le, {
    _focus: {
      bg: t
    },
    _hover: {
      bg: t
    },
    _pressed: {
      bg: t
    },
    alignItems: "center",
    bg: t,
    borderRadius: "32",
    h: "48px",
    justifyContent: "center",
    onHoverIn: () => a(!0),
    onHoverOut: () => a(!1),
    shadow: "1",
    w: "48px",
    ...n,
    children: /* @__PURE__ */ d(y, {
      fontSize: "xl",
      selectable: !1,
      children: e
    })
  });
});
ao.displayName = "Emojya";
const au = "_container_8i69q_1", tu = "_fly_8i69q_1", du = "_reaction_8i69q_12", lu = "_author_8i69q_18", Qi = {
  container: au,
  fly: tu,
  reaction: du,
  author: lu
}, ou = /* @__PURE__ */ g(() => {
  const e = D(Er), n = L(() => e.filter((i) => Date.now() - i.created.getTime() < Ld), [e]);
  return /* @__PURE__ */ d(Ys, {
    children: n.map((i) => /* @__PURE__ */ d(to, {
      authorId: i.author,
      left: i.left,
      reaction: i.reaction
    }, i.id.toString()))
  });
});
ou.displayName = "FlyingReactions";
const to = /* @__PURE__ */ g(({
  authorId: e,
  left: n,
  reaction: i
}) => {
  const {
    data: a,
    loading: t
  } = Yn(wi, {
    variables: {
      id: e
    }
  });
  return /* @__PURE__ */ f("div", {
    className: Qi.container,
    style: {
      left: n
    },
    children: [/* @__PURE__ */ d("div", {
      className: Qi.reaction,
      children: i
    }), e && !t ? /* @__PURE__ */ d("div", {
      className: Qi.author,
      children: tn(a == null ? void 0 : a.meet.member.get)
    }) : null]
  });
});
to.displayName = "R";
const su = /* @__PURE__ */ g(() => {
  var o;
  const e = D(dn), [n, {
    error: i,
    loading: a
  }] = Mn(dr), t = _(() => n({
    variables: {
      gesture: Tn
    }
  }), [n]), l = _(() => n({
    variables: {
      gesture: ""
    }
  }), [n]);
  return ((o = e == null ? void 0 : e.peer) == null ? void 0 : o.reaction.gesture) === Tn ? /* @__PURE__ */ d(un, {
    onPress: l,
    children: x("ROOM.GESTURES.LOWER_HAND", {
      defaultValue: "Опустить руку"
    })
  }) : /* @__PURE__ */ d(un, {
    onPress: t,
    children: x("ROOM.GESTURES.RAISE_HAND", {
      defaultValue: "Поднять руку"
    })
  });
});
su.displayName = "MenuItemRaiseHand";
const bS = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "RecordingTranscript"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "RecordingTranscriptType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "collected"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "transcript"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "duration"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "member_id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "text"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "time"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "nickname"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "user"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "id"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "nickname"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "end"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "exported"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recognized"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "size"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "start"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "submitted"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "summarized"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "summary"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "text"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "orders"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "name"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "orders"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "transcribed"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "debug"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "summary_request"
            }
          }]
        }
      }]
    }
  }]
}, yS = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "Recording"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "RecordingType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "collected"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "end"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "exported"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recognized"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "start"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "submitted"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "summarized"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "transcribed"
        }
      }]
    }
  }]
}, TS = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "query",
    name: {
      kind: "Name",
      value: "MeetRecordingsList"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "id"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "ID"
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "space"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "recording"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "list"
                    },
                    arguments: [{
                      kind: "Argument",
                      name: {
                        kind: "Name",
                        value: "id"
                      },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "id"
                        }
                      }
                    }],
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "Recording"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "Recording"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "RecordingType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "collected"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "end"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "exported"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recognized"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "start"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "submitted"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "summarized"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "transcribed"
        }
      }]
    }
  }]
}, MS = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "query",
    name: {
      kind: "Name",
      value: "MeetRecording"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "id"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "ID"
          }
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "recordingId"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "ID"
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "space"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "recording"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "get"
                    },
                    arguments: [{
                      kind: "Argument",
                      name: {
                        kind: "Name",
                        value: "id"
                      },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "id"
                        }
                      }
                    }, {
                      kind: "Argument",
                      name: {
                        kind: "Name",
                        value: "recording_id"
                      },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "recordingId"
                        }
                      }
                    }],
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "RecordingTranscript"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "RecordingTranscript"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "RecordingTranscriptType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "collected"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "transcript"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "duration"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "member_id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "text"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "time"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "nickname"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "user"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "id"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "nickname"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "end"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "exported"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recognized"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "size"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "start"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "submitted"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "summarized"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "summary"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "text"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "orders"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "name"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "orders"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "transcribed"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "debug"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "summary_request"
            }
          }]
        }
      }]
    }
  }]
}, ru = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "MeetRecordingStart"
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "space"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "recording"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "start"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
}, cu = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "MeetRecordingStop"
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "space"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "recording"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "stop"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
var di = "/src/features/meet/recording/model/index.ts";
const ut = h({
  loc: {
    file: di,
    line: 16,
    column: 36
  },
  name: "eventToggleRecording",
  sid: "-mwbf1o:/src/features/meet/recording/model/index.ts:eventToggleRecording"
}), kt = Di.map((e) => (e == null ? void 0 : e.recording_session_video) != null || (e == null ? void 0 : e.recording_session_audio) != null), lo = B(() => ne().mutate({
  mutation: ru
}), {
  loc: {
    file: di,
    line: 23,
    column: 25
  },
  name: "startRecordingFx",
  sid: "1oheuy:/src/features/meet/recording/model/index.ts:startRecordingFx"
}), oo = B(() => ne().mutate({
  mutation: cu
}), {
  loc: {
    file: di,
    line: 26,
    column: 24
  },
  name: "stopRecordingFx",
  sid: "-8mh06g:/src/features/meet/recording/model/index.ts:stopRecordingFx"
});
Ra.on([lo.failData, oo.failData], (e, n) => n instanceof ua ? [...e, ...n.graphQLErrors.map((i) => i.extensions.code === "not_authorized" && i.extensions.details === "permission denied" ? {
  id: vi("alert"),
  message: "Нет прав для включения записи",
  status: "error"
} : void 0).filter(Cn)] : [...e, {
  id: vi("alert"),
  message: String(n),
  status: "error"
}]);
w({
  and: [{
    clock: ut,
    source: kt,
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    filter: (e) => e,
    target: oo
  }],
  or: {
    loc: {
      file: di,
      line: 61,
      column: 0
    },
    sid: "-btv8vu:/src/features/meet/recording/model/index.ts:"
  }
});
w({
  and: [{
    clock: ut,
    source: kt,
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    filter: (e) => !e,
    target: lo
  }],
  or: {
    loc: {
      file: di,
      line: 68,
      column: 0
    },
    sid: "-bq0oqb:/src/features/meet/recording/model/index.ts:"
  }
});
const Ut = H.object({
  description: H.string().nullable().optional(),
  recordByDefault: H.boolean().nullish().optional(),
  title: H.string({
    required_error: x("VALIDATION.ERRORS.REQUIRED")
  }).min(1, {
    message: x("VALIDATION.ERRORS.AT_LEAST_ONE", {
      defaultValue: "Название должно содержать минимум один символ"
    })
  })
}).strict(), so = /* @__PURE__ */ g(({
  meetSpace: e
}) => {
  var c, k;
  const {
    control: n,
    formState: {
      isDirty: i
    },
    handleSubmit: a
  } = Rn({
    defaultValues: {
      description: e.description,
      recordByDefault: ((k = (c = e.conditions) == null ? void 0 : c.recording.enabled) == null ? void 0 : k.default) ?? !1,
      title: e.title ?? ""
    },
    mode: "all",
    resolver: Zn(Ut)
  }), [t, {
    error: l,
    loading: o
  }] = Mn(vd, {
    refetchQueries: [ud]
  }), s = _(async (u) => {
    const {
      description: v,
      recordByDefault: r,
      title: p
    } = Ut.parse(u);
    try {
      await t({
        variables: {
          conditions: {
            recording: {
              enabled: {
                default: r ?? !1
              }
            }
          },
          description: v,
          id: e.id,
          title: p
        }
      });
    } catch (m) {
      console.error(m);
    }
  }, [e.id, t]);
  return /* @__PURE__ */ f(ye, {
    children: [l ? /* @__PURE__ */ d(Qn, {
      error: l
    }) : null, /* @__PURE__ */ d(y, {
      color: "white",
      children: x("ROOM.EDIT.SHORT_ANNOTATION")
    }), /* @__PURE__ */ f(He, {
      isRequired: !0,
      mb: {
        base: 3,
        lg: 5
      },
      children: [/* @__PURE__ */ d(He.Label, {
        mb: "2",
        mt: 5,
        children: x("ROOM.EDIT.TITLE")
      }), /* @__PURE__ */ d(de, {
        control: n,
        name: "title",
        render: ({
          field: u,
          fieldState: {
            error: v
          }
        }) => /* @__PURE__ */ d(cn, {
          errorMessage: v == null ? void 0 : v.message,
          isInvalid: !!v,
          mb: 0,
          onBlur: u.onBlur,
          onChangeText: u.onChange,
          placeholder: x("ROOM.EDIT.TITLE_PLACEHOLDER"),
          value: u.value,
          withAnimation: !1
        })
      })]
    }), /* @__PURE__ */ f(He, {
      mb: {
        base: 3,
        lg: 5
      },
      children: [/* @__PURE__ */ d(He.Label, {
        mb: "2",
        children: x("ROOM.EDIT.DESCRIPTION")
      }), /* @__PURE__ */ d(de, {
        control: n,
        name: "description",
        render: ({
          field: u,
          fieldState: {
            error: v
          }
        }) => /* @__PURE__ */ d(cn, {
          errorMessage: v == null ? void 0 : v.message,
          isInvalid: !!v,
          mb: 0,
          onBlur: u.onBlur,
          onChangeText: u.onChange,
          placeholder: x("ROOM.EDIT.DESCRIPTION_PLACEHOLDER"),
          value: u.value ?? "",
          withAnimation: !1
        })
      })]
    }), /* @__PURE__ */ d(He, {
      mb: {
        base: 3,
        lg: 5
      },
      children: /* @__PURE__ */ f(He.Label, {
        justifyContent: "space-between",
        mb: "2",
        children: [x("ROOM.EDIT.RECORD_BY_DEFAULT"), /* @__PURE__ */ d(de, {
          control: n,
          name: "recordByDefault",
          render: ({
            field: u
          }) => /* @__PURE__ */ d(ds, {
            isChecked: u.value ?? !1,
            onValueChange: u.onChange
          })
        })]
      })
    }), /* @__PURE__ */ d(Qe, {
      children: /* @__PURE__ */ d(We, {
        disabled: !i,
        height: 7,
        loading: o,
        onPress: a(s),
        variant: "primary",
        children: x("ROOM.EDIT.BUTTON_APPLY")
      })
    })]
  });
});
so.displayName = "EditShortMeetSpaceSettings";
const mu = /* @__PURE__ */ g(({
  images: e,
  isOwner: n,
  meetSpace: i,
  onChange: a,
  value: t
}) => {
  const {
    t: l
  } = te();
  return /* @__PURE__ */ f(Ls, {
    style: $t.scroll,
    children: [n ? /* @__PURE__ */ d(so, {
      meetSpace: i
    }) : null, /* @__PURE__ */ d(yn, {
      borderTopColor: "white",
      borderTopWidth: 1,
      mt: 5,
      pt: 5,
      size: "md",
      children: l("ROOM.EDIT.VIDEO_SETTINGS_TITLE")
    }), /* @__PURE__ */ d(Mo, {
      small: !0,
      images: e,
      onChange: a,
      value: t
    }), /* @__PURE__ */ d(ld, {
      style: $t.inviteToCallButton,
      children: /* @__PURE__ */ d(mt, {
        meetSpace: i
      })
    })]
  });
});
mu.displayName = "MeetSpaceRuntimeSettings";
const $t = _n.create({
  inviteToCallButton: {
    marginTop: "auto"
  },
  scroll: {
    flex: 1,
    padding: ze.sizes[3]
  }
}), uu = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "query",
    name: {
      kind: "Name",
      value: "MeetFetchMemberList"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "limit"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "Int"
          }
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "cursor"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "member"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "list"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "cursor"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "cursor"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "limit"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "limit"
                    }
                  }
                }],
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "cursor"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "data"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetMember"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "User"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "UserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "attributes"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "settings"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatar"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "premium"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetMember"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetMemberType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions_deny"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions_grant"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "presence_state"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }]
    }
  }]
};
var ku = "/src/features/meet/user-list/model/index.ts";
const {
  $cursor: CS,
  $data: ro,
  eventLoadMore: Bt
} = Ko(uu, (e) => e.data.meet.member.list);
ro.reset(ke, fe);
const vu = Fe.map((e) => new Set(e.map((n) => n.id))), Su = _e({
  and: [{
    $data: ro,
    $peerIds: vu
  }],
  or: {
    loc: {
      file: ku,
      line: 27,
      column: 39
    },
    name: "$meetSpaceUsersListData",
    sid: "6s6adq:/src/features/meet/user-list/model/index.ts:$meetSpaceUsersListData"
  }
}).map(({
  $data: e,
  $peerIds: n
}) => e.filter((i) => !n.has(i.id))), pu = H.object({
  hadIssues: H.boolean(),
  issueDescription: H.string().nullable()
}).strict(), Nu = /* @__PURE__ */ g(({
  onClose: e
}) => {
  const n = hi(), i = D(Na), {
    control: a,
    handleSubmit: t
  } = Rn({
    defaultValues: {
      hadIssues: !1,
      issueDescription: ""
    }
  });
  return /* @__PURE__ */ d(rd, {
    onRequestClose: e,
    children: /* @__PURE__ */ f(ya, {
      hasCancel: !0,
      okButtonText: "ОК",
      onClose: e,
      onOk: t((o) => {
        const s = pu.parse(o), c = Et.captureMessage("User Feedback");
        Et.captureUserFeedback({
          comments: s.issueDescription ?? "",
          email: n == null ? void 0 : n.email,
          event_id: c,
          name: `userId:${n == null ? void 0 : n.id}, username:${n == null ? void 0 : n.username}, nickname:${((n == null ? void 0 : n.nickname) ?? "") !== "" ? n == null ? void 0 : n.nickname : i}`
        }), Nd(), e();
      }),
      title: "Оцените качество звонка",
      children: [/* @__PURE__ */ d(de, {
        control: a,
        name: "hadIssues",
        render: ({
          field: o
        }) => /* @__PURE__ */ f(E, {
          alignItems: "center",
          flexDirection: "row",
          my: 5,
          children: [/* @__PURE__ */ d(Us, {
            onValueChange: o.onChange,
            value: o.value
          }), /* @__PURE__ */ d(y, {
            ml: 4,
            children: " Во-время звонка возникли проблемы"
          })]
        })
      }), /* @__PURE__ */ d(de, {
        control: a,
        name: "issueDescription",
        render: ({
          field: o,
          fieldState: {
            error: s
          }
        }) => /* @__PURE__ */ d(ls, {
          autoCompleteType: "off",
          isInvalid: !!s,
          onBlur: o.onBlur,
          onChangeText: o.onChange,
          placeholder: "Опишите проблему",
          value: o.value ?? "",
          w: "100%"
        })
      }), /* @__PURE__ */ d(ba, {
        _text: {
          color: "black"
        },
        backgroundColor: "green.600",
        borderRadius: 500,
        mt: 4,
        onPress: () => pd(),
        variant: "subtle",
        children: "Вернуться к звонку"
      })]
    })
  });
});
Nu.displayName = "RateACallModal";
const fu = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "subscription",
    name: {
      kind: "Name",
      value: "User"
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "user"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "authentication"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "anonymous"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "authenticated"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "expiration"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "session_id"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "user"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "User"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "contact"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "add"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "User"
                    }
                  }]
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "remove"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "init"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "peer_id"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "meet"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "invite"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "add"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "invited"
                        }
                      }, {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "space"
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{
                            kind: "FragmentSpread",
                            name: {
                              kind: "Name",
                              value: "MeetSpace"
                            }
                          }]
                        }
                      }, {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "user"
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{
                            kind: "FragmentSpread",
                            name: {
                              kind: "Name",
                              value: "User"
                            }
                          }]
                        }
                      }]
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "remove"
                    }
                  }]
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "space"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "add"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpace"
                        }
                      }]
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "remove"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "User"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "UserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "attributes"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "settings"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatar"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "premium"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpaceConditionsContacts"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceConditionsContactsType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "password"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpace"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "description"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "owner"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "rtc_shard"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "schedule_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "started"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "state"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "terminated"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "title"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "transient"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_audio"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_video"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "vanity_name"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "conditions"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "draw"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "anyone"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "authenticated"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "join"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "link"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "recording"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "enabled"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "default"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "start"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "always"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "terminate"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "empty"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "manual"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "vote"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "cast"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "anyone"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "start"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
}, Fu = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "query",
    name: {
      kind: "Name",
      value: "UserMeetSpaces"
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "user"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "meet"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "list"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "invites"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "invited"
                        }
                      }, {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "space"
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{
                            kind: "FragmentSpread",
                            name: {
                              kind: "Name",
                              value: "MeetSpace"
                            }
                          }]
                        }
                      }, {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "user"
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{
                            kind: "FragmentSpread",
                            name: {
                              kind: "Name",
                              value: "User"
                            }
                          }]
                        }
                      }]
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "spaces"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "joined"
                        }
                      }, {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "space"
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{
                            kind: "FragmentSpread",
                            name: {
                              kind: "Name",
                              value: "MeetSpace"
                            }
                          }]
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "User"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "UserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "attributes"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "settings"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatar"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "premium"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpaceConditionsContacts"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceConditionsContactsType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "password"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpace"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "description"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "owner"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "rtc_shard"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "schedule_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "started"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "state"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "terminated"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "title"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "transient"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_audio"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_video"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "vanity_name"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "conditions"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "draw"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "anyone"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "authenticated"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "join"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "link"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "recording"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "enabled"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "default"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "start"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "always"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "terminate"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "empty"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "manual"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "vote"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "cast"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "anyone"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "start"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
var gu = "/src/features/user/model/index.ts";
const hu = A([], {
  loc: {
    file: gu,
    line: 6,
    column: 27
  },
  name: "$myContacts",
  sid: "z2ixy1:/src/features/user/model/index.ts:$myContacts"
}), Ht = W.object({
  deviceId: W.string()
}), bu = /* @__PURE__ */ g(() => {
  const {
    microphoneDevices: e,
    showNewAudioDeviceSelect: n
  } = D({
    microphoneDevices: Hr,
    showNewAudioDeviceSelect: al
  }), i = _((c) => {
    const k = Ht.parse(c);
    za(k);
  }, []), a = _(() => {
    il();
  }, []), t = pe((c) => c.length > 0 ? c[0].deviceId : void 0, void 0, e), {
    control: l,
    handleSubmit: o
  } = Rn({
    defaultValues: {
      deviceId: t
    },
    mode: "all",
    resolver: Zn(Ht)
  }), s = t != null;
  return n ? /* @__PURE__ */ d(ya, {
    hasCancel: s,
    okButtonText: "Продолжить",
    onClose: a,
    onOk: s ? o(i) : a,
    title: n.type === "new-device-connected" ? "Обнаружено новое аудиоустройство" : "Аудиоустройство отключено, выберите другое",
    children: qt((c) => c.length > 0 ? /* @__PURE__ */ d(de, {
      control: l,
      name: "deviceId",
      render: ({
        field: k,
        fieldState: {
          error: u
        }
      }) => /* @__PURE__ */ d(He, {
        isRequired: !0,
        isInvalid: u != null,
        mb: "3",
        children: /* @__PURE__ */ d(Bn, {
          color: "white",
          onPointerLeave: k.onBlur,
          onValueChange: k.onChange,
          placeholder: "Выберите устройство",
          selectedValue: k.value,
          children: c.map((v) => /* @__PURE__ */ d(Bn.Item, {
            color: "white",
            label: v.label,
            value: v.deviceId
          }, v.deviceId))
        })
      })
    }) : /* @__PURE__ */ d(y, {
      children: "Не обнаружено доступных микрофонов"
    }), () => /* @__PURE__ */ d(jn, {
      size: "lg"
    }), () => /* @__PURE__ */ d(jn, {
      size: "lg"
    }), (c) => /* @__PURE__ */ d(y, {
      children: String(c)
    }))(e)
  }, String(s)) : null;
});
bu.displayName = "AudioDeviceChangedModal";
var ji = "/src/features/video-call/speaking-analyzer/model/index.ts";
const vt = h("eventSpeakingUpdate", {
  loc: {
    file: ji,
    line: 7,
    column: 35
  },
  name: "eventSpeakingUpdate",
  sid: "-3d0v2m:/src/features/video-call/speaking-analyzer/model/index.ts:eventSpeakingUpdate"
}), St = h({
  loc: {
    file: ji,
    line: 11,
    column: 37
  },
  name: "eventMeSpeakingUpdate",
  sid: "fz3bcb:/src/features/video-call/speaking-analyzer/model/index.ts:eventMeSpeakingUpdate"
}), yu = Fe.map((e) => e.filter((n) => {
  var i, a;
  return (((i = n.stream) == null ? void 0 : i.getAudioTracks().length) ?? 0) > 0 && ((a = n.stream) == null ? void 0 : a.getAudioTracks().some((t) => t.enabled));
})), co = A(!1, {
  loc: {
    file: ji,
    line: 21,
    column: 27
  },
  name: "$meSpeaking",
  sid: "-6ln4ae:/src/features/video-call/speaking-analyzer/model/index.ts:$meSpeaking"
}).on(St, (e, n) => n).reset(ke), mo = A({
  ref: /* @__PURE__ */ new Map()
}, {
  loc: {
    file: ji,
    line: 25,
    column: 30
  },
  name: "$peersSpeaking",
  sid: "sybh3x:/src/features/video-call/speaking-analyzer/model/index.ts:$peersSpeaking"
}).on(vt, (e, n) => {
  if (e.ref.size !== n.length)
    return {
      ref: new Map(n.map((a) => [a.peerId, a.speaking]))
    };
  let i;
  for (const a of n)
    e.ref.get(a.peerId) !== a.speaking && (e.ref.set(a.peerId, a.speaking), i = !0);
  return i ? {
    ref: e.ref
  } : void 0;
});
wn([vt, St, co, mo], {
  log: "disabled"
});
const uo = -75, ko = 120, vo = 500;
function wS() {
  const e = D(yu), n = D(Te);
  ee(() => {
    if (process.env.VITE_NO_STATS !== "true") {
      if (
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        window.AudioContext ?? window.webkitAudioContext
      )
        return () => {
        };
      const i = n.sourceAudioTracks.audioContext;
      let a = null;
      const t = e.map((l) => {
        const o = i.createAnalyser();
        o.minDecibels = uo;
        const s = i.createMediaStreamSource(l.stream);
        return s.connect(o), {
          analyzer: o,
          peerId: l.id,
          streamNode: s
        };
      });
      if (t.length > 0) {
        const l = new Uint8Array(t[0].analyzer.frequencyBinCount);
        a = setInterval(() => {
          const s = t.map(({
            analyzer: c,
            peerId: k
          }) => {
            c.getByteFrequencyData(l);
            let u = !1;
            for (let v = 0; v < Math.min(50, l.length); v++)
              l[v] > ko && (u = !0);
            return {
              peerId: k,
              speaking: u
            };
          });
          vt(s);
        }, vo);
      }
      return () => {
        for (const {
          analyzer: l,
          streamNode: o
        } of t)
          l.disconnect(), o.disconnect();
        a && clearInterval(a);
      };
    } else
      return () => {
      };
  }, [n.sourceAudioTracks.audioContext, e]);
}
function ES() {
  const e = D(Te);
  ee(() => {
    if (process.env.VITE_NO_STATS !== "true") {
      let n, i, a;
      if (e.state !== "initial") {
        a = e.sourceAudioTracks.audioContext, i = a.createAnalyser(), i.minDecibels = uo, a.createMediaStreamSource(e.sourceAudioTracks.destinationStream).connect(i);
        const l = new Uint8Array(i.frequencyBinCount);
        n = setInterval(() => {
          if (i == null)
            return;
          i.getByteFrequencyData(l);
          let s = !1;
          for (let c = 0; c < Math.min(50, l.length); c++)
            l[c] > ko && (s = !0);
          St(s);
        }, vo);
      }
      return () => {
        n && clearInterval(n), i == null || i.disconnect();
      };
    } else
      return () => {
      };
  }, [e]);
}
var So = {}, pt = {};
Object.defineProperty(pt, "__esModule", { value: !0 });
var Tu = { radix: 1e3, unit: ["b", "kb", "Mb", "Gb", "Tb", "Pb", "Eb", "Zb", "Yb"] }, Mu = { radix: 1024, unit: ["b", "Kib", "Mib", "Gib", "Tib", "Pib", "Eib", "Zib", "Yib"] }, Cu = { radix: 1024, unit: ["b", "Kb", "Mb", "Gb", "Tb", "Pb", "Eb", "Zb", "Yb"] };
pt.SPECS = {
  si: Tu,
  iec: Mu,
  jedec: Cu
};
Object.defineProperty(So, "__esModule", { value: !0 });
var zt = pt;
function wu(e, n, i) {
  n === void 0 && (n = 1), e = Math.abs(e);
  for (var a = zt.SPECS[i] || zt.SPECS.jedec, t = a.radix, l = a.unit, o = 0; e >= t; )
    e /= t, ++o;
  return e.toFixed(n) + " " + l[o];
}
var Je = So.default = wu, Nt = "/src/features/video-call/stats/model/stats.ts";
const ft = h({
  loc: {
    file: Nt,
    line: 58,
    column: 33
  },
  name: "eventStatsUpdated",
  sid: "-lads7q:/src/features/video-call/stats/model/stats.ts:eventStatsUpdated"
}), Wi = A({
  ref: {
    myStats: {
      audio: void 0,
      video: void 0
    },
    peers: /* @__PURE__ */ new Map()
  }
}, {
  loc: {
    file: Nt,
    line: 60,
    column: 26
  },
  name: "$peerStats",
  sid: "-5y3ur8:/src/features/video-call/stats/model/stats.ts:$peerStats"
});
wn([Wi, ft], {
  log: "disabled"
});
Wi.on(w({
  and: [{
    clock: ft,
    source: {
      $peersByTrackId: Od
    },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    fn: (e, n) => ({
      peers: e,
      stats: n
    })
  }],
  or: {
    loc: {
      file: Nt,
      line: 66,
      column: 2
    },
    sid: "-nriy1n:/src/features/video-call/stats/model/stats.ts:"
  }
}), (e, n) => {
  var r, p, m, F;
  const i = /* @__PURE__ */ new Map(), a = /* @__PURE__ */ new Map(), t = /* @__PURE__ */ new Map(), l = /* @__PURE__ */ new Map(), o = /* @__PURE__ */ new Map(), s = /* @__PURE__ */ new Map();
  let c, k;
  n.stats.forEach((S) => {
    switch (S.type) {
      case "inbound-rtp": {
        S.kind === "video" ? i.set(S.id, S) : a.set(S.id, S);
        break;
      }
      case "remote-inbound-rtp": {
        S.kind === "video" ? t.set(S.localId, S) : l.set(S.localId, S);
        break;
      }
      case "outbound-rtp": {
        S.kind === "video" ? c = S : k = S;
        break;
      }
      case "remote-outbound-rtp": {
        S.kind === "video" ? o.set(S.id, S) : s.set(S.id, S);
        break;
      }
    }
  });
  for (const S of i.values()) {
    const N = n.peers.$peersByTrackId.ref.get(S.trackIdentifier), T = o.get(S.remoteId);
    if (N != null && T != null) {
      const b = e.ref.peers.get(N), M = (r = b == null ? void 0 : b.video) == null ? void 0 : r.inbound, C = ri(S.bytesReceived, S.timestamp, M == null ? void 0 : M.bytesReceived, M == null ? void 0 : M.timestamp), V = {
        computed: {
          lastPacketReceivedDelay: S.timestamp - (S.lastPacketReceivedTimestamp ?? 0),
          speed: C,
          speedKib: Je(C)
        },
        inbound: S,
        remoteOutbound: T
      };
      b == null ? e.ref.peers.set(N, {
        audio: void 0,
        video: V
      }) : e.ref.peers.set(N, {
        ...b,
        video: V
      });
    }
  }
  for (const S of a.values()) {
    const N = n.peers.$peersByTrackId.ref.get(S.trackIdentifier), T = s.get(S.remoteId);
    if (N != null && T != null) {
      const b = e.ref.peers.get(N), M = (p = b == null ? void 0 : b.audio) == null ? void 0 : p.inbound, C = ri(S.bytesReceived, S.timestamp, M == null ? void 0 : M.bytesReceived, M == null ? void 0 : M.timestamp), V = {
        computed: {
          lastPacketReceivedDelay: S.timestamp - (S.lastPacketReceivedTimestamp ?? 0),
          speed: C,
          speedKib: Je(C)
        },
        inbound: S,
        remoteOutbound: T
      };
      b == null ? e.ref.peers.set(N, {
        audio: V,
        video: void 0
      }) : e.ref.peers.set(N, {
        ...b,
        audio: V
      });
    }
  }
  let u, v;
  if (c != null) {
    const S = t.get(c.id), N = (m = e.ref.myStats.video) == null ? void 0 : m.outbound, T = ri(c.bytesSent, c.timestamp, N == null ? void 0 : N.bytesSent, N == null ? void 0 : N.timestamp);
    u = {
      computed: {
        speed: T,
        speedKib: Je(T)
      },
      outbound: c,
      remoteInbound: S
    };
  }
  if (k != null) {
    const S = l.get(k.id), N = (F = e.ref.myStats.audio) == null ? void 0 : F.outbound, T = ri(k.bytesSent, k.timestamp, N == null ? void 0 : N.bytesSent, N == null ? void 0 : N.timestamp);
    v = {
      computed: {
        speed: T,
        speedKib: Je(T)
      },
      outbound: k,
      remoteInbound: S
    };
  }
  return {
    ref: {
      myStats: {
        audio: v,
        video: u
      },
      peers: e.ref.peers
    }
  };
});
function ri(e, n, i, a) {
  return ((e ?? 0) - (i ?? 0)) / ((n - (a ?? 0)) / 1e3);
}
function _S() {
  const e = D(J);
  ee(() => {
    let n = null;
    return process.env.VITE_NO_STATS !== "true" && (n = setInterval(() => {
      e == null || e.getStats().then((a) => {
        ft(a);
      });
    }, 1e3)), () => {
      n != null && clearInterval(n);
    };
  }, [e]);
}
const po = /* @__PURE__ */ g(({
  peerId: e
}) => {
  const n = D(Wi).ref, i = D({
    connectionState: Sn,
    iceConnectionState: On,
    iceGatheringState: Xa,
    signalingState: In
  }), a = e != null ? n.peers.get(e) : n.myStats, t = JSON.stringify(i, null, 2);
  return /* @__PURE__ */ f(zn, {
    children: [/* @__PURE__ */ d(y, {
      children: t
    }), /* @__PURE__ */ d(y, {
      children: JSON.stringify({
        peerId: e,
        ...a
      }, null, 2)
    })]
  });
});
po.displayName = "StatsPopoverBody";
const Ft = /* @__PURE__ */ g(({
  peerId: e,
  showDetails: n,
  small: i
}) => {
  var k, u, v, r, p, m;
  const {
    t: a
  } = te(), t = D(xc), l = t === Ne.GREEN ? ze.colors.green[400] : t === Ne.YELLOW ? ze.colors.yellow[400] : ze.colors.red[400], o = D(Wi).ref, s = e != null ? o.peers.get(e) : null, c = e == null ? o.myStats : null;
  return /* @__PURE__ */ f(mn, {
    backgroundColor: "rgba(0,0,0,0.64)",
    borderRadius: "50%",
    bottom: i ? void 0 : 0,
    left: 0,
    position: "absolute",
    top: i ? 0 : void 0,
    children: [/* @__PURE__ */ d(he, {
      trigger: (F) => /* @__PURE__ */ d(Jn, {
        ...F,
        _focus: {
          bg: "rgba(255, 255, 255, 0.12)"
        },
        _hover: {
          bg: "rgba(255, 255, 255, 0.12)"
        },
        bg: "rgba(255, 255, 255, 0.08)",
        h: i ? "16px" : "36px",
        w: i ? "16px" : "36px",
        children: /* @__PURE__ */ d(_s, {
          color: l,
          fill: l
        })
      }),
      children: /* @__PURE__ */ f(he.Content, {
        children: [/* @__PURE__ */ d(he.Arrow, {}), /* @__PURE__ */ d(he.CloseButton, {}), /* @__PURE__ */ d(he.Header, {
          children: a("ROOM.CONNECTION_STATS.LABEL")
        }), /* @__PURE__ */ d(he.Body, {
          height: "2xl",
          overflow: "scroll",
          w: "100%",
          children: /* @__PURE__ */ d(po, {
            peerId: e
          })
        })]
      })
    }), n ? s != null ? /* @__PURE__ */ f(mn, {
      flexWrap: "nowrap",
      w: "max-content",
      children: [/* @__PURE__ */ f(y, {
        color: "white",
        children: ["a: ", Je(((k = s.audio) == null ? void 0 : k.computed.speed) ?? 0), ",", " ", jt((u = s.audio) == null ? void 0 : u.computed.lastPacketReceivedDelay)]
      }), /* @__PURE__ */ f(y, {
        color: "white",
        children: ["v: ", Je(((v = s.video) == null ? void 0 : v.computed.speed) ?? 0), ",", " ", jt((r = s.video) == null ? void 0 : r.computed.lastPacketReceivedDelay)]
      })]
    }) : c != null ? /* @__PURE__ */ f(mn, {
      flexWrap: "nowrap",
      w: "max-content",
      children: [/* @__PURE__ */ f(y, {
        color: "white",
        children: ["a: ", Je(((p = c.audio) == null ? void 0 : p.computed.speed) ?? 0)]
      }), /* @__PURE__ */ f(y, {
        color: "white",
        children: ["v: ", Je(((m = c.video) == null ? void 0 : m.computed.speed) ?? 0)]
      })]
    }) : null : null]
  });
});
Ft.displayName = "StatsPopover";
const jt = (e) => e == null ? "" : e < 1e3 ? `${Math.round(e)} мс` : `${Math.round(e / 1e3)} сек`, Eu = H.object({
  backgroundImage: H.union([H.string(), H.undefined()]),
  backgroundType: H.union([H.literal("blur"), H.literal("image"), H.literal("none")])
}), li = {
  "160x96": [160, 96],
  "256x144": [256, 144],
  "256x256": [256, 256],
  "640x360": [640, 360]
};
function _u(e, n) {
  switch (e) {
    case "meet":
      return n === "256x144" ? "segm_full_v679" : "segm_lite_v681";
    case "mlkit":
      return "selfiesegmentation_mlkit-256x256-2021_01_19-v1215.f16";
    default:
      throw new Error(`No TFLite file for this segmentation model: ${e}`);
  }
}
const Ve = String.raw;
function fn(e, n, i, a, t) {
  const l = Ru(e, n, i), o = e.getAttribLocation(l, "a_position");
  e.enableVertexAttribArray(o), e.bindBuffer(e.ARRAY_BUFFER, a), e.vertexAttribPointer(o, 2, e.FLOAT, !1, 0, 0);
  const s = e.getAttribLocation(l, "a_texCoord");
  return e.enableVertexAttribArray(s), e.bindBuffer(e.ARRAY_BUFFER, t), e.vertexAttribPointer(s, 2, e.FLOAT, !1, 0, 0), l;
}
function Ru(e, n, i) {
  const a = e.createProgram();
  if (e.attachShader(a, n), e.attachShader(a, i), e.linkProgram(a), !e.getProgramParameter(a, e.LINK_STATUS))
    throw new Error(`Could not link WebGL program: ${e.getProgramInfoLog(a)}`);
  return a;
}
function Pe(e, n, i) {
  const a = e.createShader(n);
  if (e.shaderSource(a, i), e.compileShader(a), !e.getShaderParameter(a, e.COMPILE_STATUS))
    throw new Error(`Could not compile shader: ${e.getShaderInfoLog(a)}`);
  return a;
}
function en(e, n, i, a, t = e.NEAREST, l = e.NEAREST) {
  const o = e.createTexture();
  return e.bindTexture(e.TEXTURE_2D, o), e.texParameteri(e.TEXTURE_2D, e.TEXTURE_WRAP_S, e.CLAMP_TO_EDGE), e.texParameteri(e.TEXTURE_2D, e.TEXTURE_WRAP_T, e.CLAMP_TO_EDGE), e.texParameteri(e.TEXTURE_2D, e.TEXTURE_MIN_FILTER, t), e.texParameteri(e.TEXTURE_2D, e.TEXTURE_MAG_FILTER, l), e.texStorage2D(e.TEXTURE_2D, 1, n, i, a), o;
}
async function xu(e, n, i, a, t, l, o, s) {
  const c = e.createBuffer();
  return e.bindBuffer(e.PIXEL_PACK_BUFFER, c), e.bufferData(e.PIXEL_PACK_BUFFER, s.byteLength, e.STREAM_READ), e.readPixels(n, i, a, t, l, o, 0), e.bindBuffer(e.PIXEL_PACK_BUFFER, null), await Du(e, e.PIXEL_PACK_BUFFER, c, 0, s), e.deleteBuffer(c), s;
}
async function Du(e, n, i, a, t, l, o) {
  const s = e.fenceSync(e.SYNC_GPU_COMMANDS_COMPLETE, 0);
  e.flush();
  const c = await Au(e, s);
  e.deleteSync(s), c !== e.WAIT_FAILED && (e.bindBuffer(n, i), e.getBufferSubData(n, a, t, l, o), e.bindBuffer(n, null));
}
function Au(e, n) {
  return new Promise((i) => {
    function a() {
      const t = e.clientWaitSync(n, 0, 0);
      if (t === e.WAIT_FAILED) {
        i(t);
        return;
      }
      if (t === e.TIMEOUT_EXPIRED) {
        requestAnimationFrame(a);
        return;
      }
      i(t);
    }
    requestAnimationFrame(a);
  });
}
function Iu(e, n, i, a, t, l) {
  const o = Ou(e, n, i, a, t, l), s = Vu(e, i, a, l);
  function c() {
    o.render(), s.render();
  }
  function k(v) {
    s.updateCoverage(v);
  }
  function u() {
    s.cleanUp(), o.cleanUp();
  }
  return {
    cleanUp: u,
    render: c,
    updateCoverage: k
  };
}
function Ou(e, n, i, a, t, l) {
  const o = Ve`#version 300 es

    precision highp float;

    uniform sampler2D u_inputFrame;
    uniform sampler2D u_personMask;
    uniform vec2 u_texelSize;

    in vec2 v_texCoord;

    out vec4 outColor;

    const float offset[5] = float[](0.0, 1.0, 2.0, 3.0, 4.0);
    const float weight[5] = float[](0.2270270270, 0.1945945946, 0.1216216216,
      0.0540540541, 0.0162162162);

    void main() {
      vec4 centerColor = texture(u_inputFrame, v_texCoord);
      float personMask = texture(u_personMask, v_texCoord).a;

      vec4 frameColor = centerColor * weight[0] * (1.0 - personMask);

      for (int i = 1; i < 5; i++) {
        vec2 offset = vec2(offset[i]) * u_texelSize;

        vec2 texCoord = v_texCoord + offset;
        frameColor += texture(u_inputFrame, texCoord) * weight[i] *
          (1.0 - texture(u_personMask, texCoord).a);

        texCoord = v_texCoord - offset;
        frameColor += texture(u_inputFrame, texCoord) * weight[i] *
          (1.0 - texture(u_personMask, texCoord).a);
      }
      outColor = vec4(frameColor.rgb + (1.0 - frameColor.a) * centerColor.rgb, 1.0);
    }
  `, s = 0.2, c = l.width * s, k = l.height * s, u = 1 / c, v = 1 / k, r = Pe(e, e.FRAGMENT_SHADER, o), p = fn(e, n, r, i, a), m = e.getUniformLocation(p, "u_inputFrame"), F = e.getUniformLocation(p, "u_personMask"), S = e.getUniformLocation(p, "u_texelSize"), N = en(e, e.RGBA8, c, k, e.NEAREST, e.LINEAR), T = en(e, e.RGBA8, c, k, e.NEAREST, e.LINEAR), b = e.createFramebuffer();
  e.bindFramebuffer(e.FRAMEBUFFER, b), e.framebufferTexture2D(e.FRAMEBUFFER, e.COLOR_ATTACHMENT0, e.TEXTURE_2D, N, 0);
  const M = e.createFramebuffer();
  e.bindFramebuffer(e.FRAMEBUFFER, M), e.framebufferTexture2D(e.FRAMEBUFFER, e.COLOR_ATTACHMENT0, e.TEXTURE_2D, T, 0), e.useProgram(p), e.uniform1i(F, 1);
  function C() {
    e.viewport(0, 0, c, k), e.useProgram(p), e.uniform1i(m, 0), e.activeTexture(e.TEXTURE1), e.bindTexture(e.TEXTURE_2D, t);
    for (let X = 0; X < 3; X++)
      e.uniform2f(S, 0, v), e.bindFramebuffer(e.FRAMEBUFFER, b), e.drawArrays(e.TRIANGLE_STRIP, 0, 4), e.activeTexture(e.TEXTURE2), e.bindTexture(e.TEXTURE_2D, N), e.uniform1i(m, 2), e.uniform2f(S, u, 0), e.bindFramebuffer(e.FRAMEBUFFER, M), e.drawArrays(e.TRIANGLE_STRIP, 0, 4), e.bindTexture(e.TEXTURE_2D, T);
  }
  function V() {
    e.deleteFramebuffer(M), e.deleteFramebuffer(b), e.deleteTexture(T), e.deleteTexture(N), e.deleteProgram(p), e.deleteShader(r);
  }
  return {
    cleanUp: V,
    render: C
  };
}
function Vu(e, n, i, a) {
  const t = Ve`#version 300 es

    in vec2 a_position;
    in vec2 a_texCoord;

    out vec2 v_texCoord;

    void main() {
      // Flipping Y is required when rendering to canvas
      gl_Position = vec4(a_position * vec2(1.0, -1.0), 0.0, 1.0);
      v_texCoord = a_texCoord;
    }
  `, l = Ve`#version 300 es

    precision highp float;

    uniform sampler2D u_inputFrame;
    uniform sampler2D u_personMask;
    uniform sampler2D u_blurredInputFrame;
    uniform vec2 u_coverage;

    in vec2 v_texCoord;

    out vec4 outColor;

    void main() {
      vec3 color = texture(u_inputFrame, v_texCoord).rgb;
      vec3 blurredColor = texture(u_blurredInputFrame, v_texCoord).rgb;
      float personMask = texture(u_personMask, v_texCoord).a;
      personMask = smoothstep(u_coverage.x, u_coverage.y, personMask);
      outColor = vec4(mix(blurredColor, color, personMask), 1.0);
    }
  `, {
    height: o,
    width: s
  } = a, c = Pe(e, e.VERTEX_SHADER, t), k = Pe(e, e.FRAGMENT_SHADER, l), u = fn(e, c, k, n, i), v = e.getUniformLocation(u, "u_inputFrame"), r = e.getUniformLocation(u, "u_personMask"), p = e.getUniformLocation(u, "u_blurredInputFrame"), m = e.getUniformLocation(u, "u_coverage");
  e.useProgram(u), e.uniform1i(v, 0), e.uniform1i(r, 1), e.uniform1i(p, 2), e.uniform2f(m, 0, 1);
  function F() {
    e.viewport(0, 0, s, o), e.useProgram(u), e.bindFramebuffer(e.FRAMEBUFFER, null), e.drawArrays(e.TRIANGLE_STRIP, 0, 4);
  }
  function S(T) {
    e.useProgram(u), e.uniform2f(m, T[0], T[1]);
  }
  function N() {
    e.deleteProgram(u), e.deleteShader(k), e.deleteShader(c);
  }
  return {
    cleanUp: N,
    render: F,
    updateCoverage: S
  };
}
function Pu(e, n, i, a, t, l) {
  const o = Ve`#version 300 es

    uniform vec2 u_backgroundScale;
    uniform vec2 u_backgroundOffset;

    in vec2 a_position;
    in vec2 a_texCoord;

    out vec2 v_texCoord;
    out vec2 v_backgroundCoord;

    void main() {
      // Flipping Y is required when rendering to canvas
      gl_Position = vec4(a_position * vec2(1.0, -1.0), 0.0, 1.0);
      v_texCoord = a_texCoord;
      v_backgroundCoord = a_texCoord * u_backgroundScale + u_backgroundOffset;
    }
  `, s = Ve`#version 300 es

    precision highp float;

    uniform sampler2D u_inputFrame;
    uniform sampler2D u_personMask;
    uniform sampler2D u_background;
    uniform vec2 u_coverage;
    uniform float u_lightWrapping;
    uniform float u_blendMode;

    in vec2 v_texCoord;
    in vec2 v_backgroundCoord;

    out vec4 outColor;

    vec3 screen(vec3 a, vec3 b) {
      return 1.0 - (1.0 - a) * (1.0 - b);
    }

    vec3 linearDodge(vec3 a, vec3 b) {
      return a + b;
    }

    void main() {
      vec3 frameColor = texture(u_inputFrame, v_texCoord).rgb;
      vec3 backgroundColor = texture(u_background, v_backgroundCoord).rgb;
      float personMask = texture(u_personMask, v_texCoord).a;
      float lightWrapMask = 1.0 - max(0.0, personMask - u_coverage.y) / (1.0 - u_coverage.y);
      vec3 lightWrap = u_lightWrapping * lightWrapMask * backgroundColor;
      frameColor = u_blendMode * linearDodge(frameColor, lightWrap) +
        (1.0 - u_blendMode) * screen(frameColor, lightWrap);
      personMask = smoothstep(u_coverage.x, u_coverage.y, personMask);
      outColor = vec4(frameColor * personMask + backgroundColor * (1.0 - personMask), 1.0);
    }
  `, {
    height: c,
    width: k
  } = l, u = k / c, v = Pe(e, e.VERTEX_SHADER, o), r = Pe(e, e.FRAGMENT_SHADER, s), p = fn(e, v, r, n, i), m = e.getUniformLocation(p, "u_backgroundScale"), F = e.getUniformLocation(p, "u_backgroundOffset"), S = e.getUniformLocation(p, "u_inputFrame"), N = e.getUniformLocation(p, "u_personMask"), T = e.getUniformLocation(p, "u_background"), b = e.getUniformLocation(p, "u_coverage"), M = e.getUniformLocation(p, "u_lightWrapping"), C = e.getUniformLocation(p, "u_blendMode");
  e.useProgram(p), e.uniform2f(m, 1, 1), e.uniform2f(F, 0, 0), e.uniform1i(S, 0), e.uniform1i(N, 1), e.uniform2f(b, 0, 1), e.uniform1f(M, 0), e.uniform1f(C, 0);
  let V = null;
  t != null && t.complete ? re(t) : t && t.addEventListener("load", () => {
    re(t);
  });
  function X() {
    e.viewport(0, 0, k, c), e.useProgram(p), e.activeTexture(e.TEXTURE1), e.bindTexture(e.TEXTURE_2D, a), V !== null && (e.activeTexture(e.TEXTURE2), e.bindTexture(e.TEXTURE_2D, V), e.uniform1i(T, 2)), e.bindFramebuffer(e.FRAMEBUFFER, null), e.drawArrays(e.TRIANGLE_STRIP, 0, 4);
  }
  function re(U) {
    V = en(e, e.RGBA8, U.naturalWidth, U.naturalHeight, e.LINEAR, e.LINEAR), e.texSubImage2D(e.TEXTURE_2D, 0, 0, 0, U.naturalWidth, U.naturalHeight, e.RGBA, e.UNSIGNED_BYTE, U);
    let ve = 0, sn = 0, Fn = U.naturalWidth, $e = U.naturalHeight;
    Fn / $e < u ? ($e = Fn / u, sn = (U.naturalHeight - $e) / 2) : (Fn = $e * u, ve = (U.naturalWidth - Fn) / 2);
    const qi = Fn / U.naturalWidth, Xi = $e / U.naturalHeight;
    ve /= U.naturalWidth, sn /= U.naturalHeight, e.uniform2f(m, qi, Xi), e.uniform2f(F, ve, sn);
  }
  function Q(U) {
    e.useProgram(p), e.uniform2f(b, U[0], U[1]);
  }
  function I(U) {
    e.useProgram(p), e.uniform1f(M, U);
  }
  function R(U) {
    e.useProgram(p), e.uniform1f(C, U === "screen" ? 0 : 1);
  }
  function Y() {
    e.deleteTexture(V), e.deleteProgram(p), e.deleteShader(r), e.deleteShader(v);
  }
  return {
    cleanUp: Y,
    render: X,
    updateBlendMode: R,
    updateCoverage: Q,
    updateLightWrapping: I
  };
}
function Lu(e, n, i, a, t, l, o, s) {
  const c = Ve`#version 300 es

    precision highp float;

    uniform sampler2D u_inputFrame;
    uniform sampler2D u_segmentationMask;
    uniform vec2 u_texelSize;
    uniform float u_step;
    uniform float u_radius;
    uniform float u_offset;
    uniform float u_sigmaTexel;
    uniform float u_sigmaColor;

    in vec2 v_texCoord;

    out vec4 outColor;

    float gaussian(float x, float sigma) {
      float coeff = -0.5 / (sigma * sigma * 4.0 + 1.0e-6);
      return exp((x * x) * coeff);
    }

    void main() {
      vec2 centerCoord = v_texCoord;
      vec3 centerColor = texture(u_inputFrame, centerCoord).rgb;
      float newVal = 0.0;

      float spaceWeight = 0.0;
      float colorWeight = 0.0;
      float totalWeight = 0.0;

      // Subsample kernel space.
      for (float i = -u_radius + u_offset; i <= u_radius; i += u_step) {
        for (float j = -u_radius + u_offset; j <= u_radius; j += u_step) {
          vec2 shift = vec2(j, i) * u_texelSize;
          vec2 coord = vec2(centerCoord + shift);
          vec3 frameColor = texture(u_inputFrame, coord).rgb;
          float outVal = texture(u_segmentationMask, coord).a;

          spaceWeight = gaussian(distance(centerCoord, coord), u_sigmaTexel);
          colorWeight = gaussian(distance(centerColor, frameColor), u_sigmaColor);
          totalWeight += spaceWeight * colorWeight;

          newVal += spaceWeight * colorWeight * outVal;
        }
      }
      newVal /= totalWeight;

      outColor = vec4(vec3(0.0), newVal);
    }
  `, [k, u] = li[l.inputResolution], {
    height: v,
    width: r
  } = s, p = 1 / r, m = 1 / v, F = Pe(e, e.FRAGMENT_SHADER, c), S = fn(e, n, F, i, a), N = e.getUniformLocation(S, "u_inputFrame"), T = e.getUniformLocation(S, "u_segmentationMask"), b = e.getUniformLocation(S, "u_texelSize"), M = e.getUniformLocation(S, "u_step"), C = e.getUniformLocation(S, "u_radius"), V = e.getUniformLocation(S, "u_offset"), X = e.getUniformLocation(S, "u_sigmaTexel"), re = e.getUniformLocation(S, "u_sigmaColor"), Q = e.createFramebuffer();
  e.bindFramebuffer(e.FRAMEBUFFER, Q), e.framebufferTexture2D(e.FRAMEBUFFER, e.COLOR_ATTACHMENT0, e.TEXTURE_2D, o, 0), e.useProgram(S), e.uniform1i(N, 0), e.uniform1i(T, 1), e.uniform2f(b, p, m), R(0), Y(0);
  function I() {
    e.viewport(0, 0, r, v), e.useProgram(S), e.activeTexture(e.TEXTURE1), e.bindTexture(e.TEXTURE_2D, t), e.bindFramebuffer(e.FRAMEBUFFER, Q), e.drawArrays(e.TRIANGLE_STRIP, 0, 4);
  }
  function R(ve) {
    ve *= Math.max(r / k, v / u);
    const sn = 0.66, $e = Math.max(1, Math.sqrt(ve) * sn), bt = ve, qi = $e > 1 ? $e * 0.5 : 0, Xi = Math.max(p, m) * ve;
    e.useProgram(S), e.uniform1f(M, $e), e.uniform1f(C, bt), e.uniform1f(V, qi), e.uniform1f(X, Xi);
  }
  function Y(ve) {
    e.useProgram(S), e.uniform1f(re, ve);
  }
  function U() {
    e.deleteFramebuffer(Q), e.deleteProgram(S), e.deleteShader(F);
  }
  return {
    cleanUp: U,
    render: I,
    updateSigmaColor: Y,
    updateSigmaSpace: R
  };
}
function Uu(e, n, i, a, t, l, o) {
  const s = Ve`#version 300 es

    precision highp float;

    uniform sampler2D u_inputSegmentation;

    in vec2 v_texCoord;

    out vec4 outColor;

    void main() {
      float segmentation = texture(u_inputSegmentation, v_texCoord).r;
      outColor = vec4(vec3(0.0), segmentation);
    }
  `, c = l._getOutputMemoryOffset() / 4, [k, u] = li[t.inputResolution], v = Pe(e, e.FRAGMENT_SHADER, s), r = fn(e, n, v, i, a), p = e.getUniformLocation(r, "u_inputSegmentation"), m = en(e, e.R32F, k, u), F = e.createFramebuffer();
  e.bindFramebuffer(e.FRAMEBUFFER, F), e.framebufferTexture2D(e.FRAMEBUFFER, e.COLOR_ATTACHMENT0, e.TEXTURE_2D, o, 0), e.useProgram(r), e.uniform1i(p, 1);
  function S() {
    e.viewport(0, 0, k, u), e.useProgram(r), e.activeTexture(e.TEXTURE1), e.bindTexture(e.TEXTURE_2D, m), e.texSubImage2D(e.TEXTURE_2D, 0, 0, 0, k, u, e.RED, e.FLOAT, l.HEAPF32, c), e.bindFramebuffer(e.FRAMEBUFFER, F), e.drawArrays(e.TRIANGLE_STRIP, 0, 4);
  }
  function N() {
    e.deleteFramebuffer(F), e.deleteTexture(m), e.deleteProgram(r), e.deleteShader(v);
  }
  return {
    cleanUp: N,
    render: S
  };
}
function $u(e, n, i, a, t, l) {
  const o = Ve`#version 300 es

    precision highp float;

    uniform sampler2D u_inputFrame;

    in vec2 v_texCoord;

    out vec4 outColor;

    void main() {
      outColor = texture(u_inputFrame, v_texCoord);
    }
  `, s = l._getInputMemoryOffset() / 4, [c, k] = li[t.inputResolution], u = c * k, v = Pe(e, e.FRAGMENT_SHADER, o), r = fn(e, n, v, i, a), p = e.getUniformLocation(r, "u_inputFrame"), m = en(e, e.RGBA8, c, k), F = e.createFramebuffer();
  e.bindFramebuffer(e.FRAMEBUFFER, F), e.framebufferTexture2D(e.FRAMEBUFFER, e.COLOR_ATTACHMENT0, e.TEXTURE_2D, m, 0);
  const S = new Uint8Array(u * 4);
  e.useProgram(r), e.uniform1i(p, 0);
  async function N() {
    e.viewport(0, 0, c, k), e.useProgram(r), e.bindFramebuffer(e.FRAMEBUFFER, F), e.drawArrays(e.TRIANGLE_STRIP, 0, 4);
    const b = xu(e, 0, 0, c, k, e.RGBA, e.UNSIGNED_BYTE, S);
    t.deferInputResizing || await b;
    for (let M = 0; M < u; M++) {
      const C = s + M * 3, V = M * 4;
      l.HEAPF32[C] = S[V] / 255, l.HEAPF32[C + 1] = S[V + 1] / 255, l.HEAPF32[C + 2] = S[V + 2] / 255;
    }
  }
  function T() {
    e.deleteFramebuffer(F), e.deleteTexture(m), e.deleteProgram(r), e.deleteShader(v);
  }
  return {
    cleanUp: T,
    render: N
  };
}
function Bu(e, n, i, a, t, l, o) {
  const s = Ve`#version 300 es

    precision highp float;

    uniform sampler2D u_inputSegmentation;

    in vec2 v_texCoord;

    out vec4 outColor;

    void main() {
      vec2 segmentation = texture(u_inputSegmentation, v_texCoord).rg;
      float shift = max(segmentation.r, segmentation.g);
      float backgroundExp = exp(segmentation.r - shift);
      float personExp = exp(segmentation.g - shift);
      outColor = vec4(vec3(0.0), personExp / (backgroundExp + personExp));
    }
  `, c = l._getOutputMemoryOffset() / 4, [k, u] = li[t.inputResolution], v = Pe(e, e.FRAGMENT_SHADER, s), r = fn(e, n, v, i, a), p = e.getUniformLocation(r, "u_inputSegmentation"), m = en(e, e.RG32F, k, u), F = e.createFramebuffer();
  e.bindFramebuffer(e.FRAMEBUFFER, F), e.framebufferTexture2D(e.FRAMEBUFFER, e.COLOR_ATTACHMENT0, e.TEXTURE_2D, o, 0), e.useProgram(r), e.uniform1i(p, 1);
  function S() {
    e.viewport(0, 0, k, u), e.useProgram(r), e.activeTexture(e.TEXTURE1), e.bindTexture(e.TEXTURE_2D, m), e.texSubImage2D(e.TEXTURE_2D, 0, 0, 0, k, u, e.RG, e.FLOAT, l.HEAPF32, c), e.bindFramebuffer(e.FRAMEBUFFER, F), e.drawArrays(e.TRIANGLE_STRIP, 0, 4);
  }
  function N() {
    e.deleteFramebuffer(F), e.deleteTexture(m), e.deleteProgram(r), e.deleteShader(v);
  }
  return {
    cleanUp: N,
    render: S
  };
}
function Hu(e, n, i, a, t, l, o) {
  const s = Ve`#version 300 es

    in vec2 a_position;
    in vec2 a_texCoord;

    out vec2 v_texCoord;

    void main() {
      gl_Position = vec4(a_position, 0.0, 1.0);
      v_texCoord = a_texCoord;
    }
  `, {
    height: c,
    width: k
  } = e, [u, v] = li[a.inputResolution], r = t.getContext("webgl2"), p = Pe(r, r.VERTEX_SHADER, s), m = r.createVertexArray();
  r.bindVertexArray(m);
  const F = r.createBuffer();
  r.bindBuffer(r.ARRAY_BUFFER, F), r.bufferData(r.ARRAY_BUFFER, new Float32Array([-1, -1, 1, -1, -1, 1, 1, 1]), r.STATIC_DRAW);
  const S = r.createBuffer();
  r.bindBuffer(r.ARRAY_BUFFER, S), r.bufferData(r.ARRAY_BUFFER, new Float32Array([0, 0, 1, 0, 0, 1, 1, 1]), r.STATIC_DRAW);
  const N = r.createTexture();
  r.bindTexture(r.TEXTURE_2D, N), r.texParameteri(r.TEXTURE_2D, r.TEXTURE_WRAP_S, r.CLAMP_TO_EDGE), r.texParameteri(r.TEXTURE_2D, r.TEXTURE_WRAP_T, r.CLAMP_TO_EDGE), r.texParameteri(r.TEXTURE_2D, r.TEXTURE_MIN_FILTER, r.NEAREST), r.texParameteri(r.TEXTURE_2D, r.TEXTURE_MAG_FILTER, r.NEAREST);
  const T = en(r, r.RGBA8, u, v), b = en(r, r.RGBA8, k, c), M = $u(r, p, F, S, a, l), C = a.model === "meet" ? Bu(r, p, F, S, a, l, T) : Uu(r, p, F, S, a, l, T), V = Lu(r, p, F, S, T, a, b, t), X = i.type === "blur" ? Iu(r, p, F, S, b, t) : Pu(r, F, S, b, n, t);
  async function re() {
    r.activeTexture(r.TEXTURE0), r.bindTexture(r.TEXTURE_2D, N), r.texImage2D(r.TEXTURE_2D, 0, r.RGBA, r.RGBA, r.UNSIGNED_BYTE, e.htmlElement), r.bindVertexArray(m), await M.render(), o(), l._runInference(), o(), C.render(), V.render(), X.render();
  }
  function Q(R) {
    if (V.updateSigmaSpace(R.jointBilateralFilter.sigmaSpace), V.updateSigmaColor(R.jointBilateralFilter.sigmaColor), i.type === "image") {
      const Y = X;
      Y.updateCoverage(R.coverage), Y.updateLightWrapping(R.lightWrapping), Y.updateBlendMode(R.blendMode);
    } else if (i.type === "blur")
      X.updateCoverage(R.coverage);
    else {
      const Y = X;
      Y.updateCoverage([0, 0.9999]), Y.updateLightWrapping(0);
    }
  }
  function I() {
    X.cleanUp(), V.cleanUp(), C.cleanUp(), M.cleanUp(), r.deleteTexture(b), r.deleteTexture(T), r.deleteTexture(N), r.deleteBuffer(S), r.deleteBuffer(F), r.deleteVertexArray(m), r.deleteShader(p);
  }
  return {
    cleanUp: I,
    render: re,
    updatePostProcessingConfig: Q
  };
}
function zu(e) {
  return new Worker(
    "/assets/timerWorker--7dR5ATP.js",
    {
      name: e == null ? void 0 : e.name
    }
  );
}
function ju() {
  const e = /* @__PURE__ */ new Map(), n = new zu();
  n.addEventListener("message", (o) => {
    const s = e.get(o.data.callbackId);
    s && (e.delete(o.data.callbackId), s());
  });
  let i = 1;
  function a(o, s) {
    const c = i++;
    return e.set(c, o), n.postMessage({
      callbackId: c,
      timeoutMs: s
    }), c;
  }
  function t(o) {
    e.has(o) && (n.postMessage({
      callbackId: o
    }), e.delete(o));
  }
  function l() {
    e.clear(), n.terminate();
  }
  return {
    clearTimeout: t,
    setTimeout: a,
    terminate: l
  };
}
function Wu(e, n, i, a) {
  const [t, l] = G(null), o = je(null), s = je(null), [c, k] = G(0), [u, v] = G([]);
  return ee(() => {
    if (a == null || e == null)
      return () => {
      };
    const r = 1e3 / i.targetFps;
    let p = 0, m = 0, F = 0, S = 0;
    const N = [];
    let T;
    const b = ju();
    if (i.pipeline !== "webgl2")
      throw new Error('segmentationConfig.pipeline !== "webgl2"');
    const M = Hu(e, o.current, n, i, s.current, a, X);
    async function C() {
      const Q = performance.now();
      V(), await M.render(), re(), T = b.setTimeout(C, Math.max(0, r - (performance.now() - Q)));
    }
    function V() {
      m = Date.now();
    }
    function X() {
      const Q = Date.now();
      N[F] = Q - m, m = Q, F++;
    }
    function re() {
      const Q = Date.now();
      N[F] = Q - m, S++, Q >= p + 1e3 && (k(S * 1e3 / (Q - p)), v(N), p = Q, S = 0), F = 0;
    }
    return C(), console.info("Animation started:", e, n, i), l(M), () => {
      b.clearTimeout(T), b.terminate(), M.cleanUp(), console.info("Animation stopped:", e, n, i), l(null);
    };
  }, [e, n, i, a]), {
    backgroundImageRef: o,
    canvasRef: s,
    durations: u,
    fps: c,
    pipeline: t
  };
}
function Gu(e) {
  const [n, i] = G(), [a, t] = G(), [l, o] = G(), [s, c] = G(!1);
  return ee(() => {
    async function k() {
      createTFLiteModule().then(i);
      try {
        const u = await createTFLiteSIMDModule();
        t(u), c(!0);
      } catch (u) {
        console.warn("Failed to create TFLite SIMD WebAssembly module.", u);
      }
    }
    k();
  }, []), ee(() => {
    async function k() {
      if (!n || s && !a || !s && e.backend === "wasmSimd" || e.model !== "meet" && e.model !== "mlkit")
        return;
      o(void 0);
      const u = e.backend === "wasmSimd" ? a : n;
      if (!u)
        throw new Error(`TFLite backend unavailable: ${e.backend}`);
      const v = _u(e.model, e.inputResolution);
      console.info("Loading tflite model:", v);
      const r = `/models/${v}.tflite`, p = await (await fetch(r)).arrayBuffer();
      console.info("Model buffer size:", p.byteLength);
      const m = u._getModelBufferMemoryOffset();
      console.info("Model buffer memory offset:", m), console.info("Loading model buffer..."), u.HEAPU8.set(new Uint8Array(p), m), console.info("_loadModel result:", u._loadModel(p.byteLength)), console.info("Input memory offset:", u._getInputMemoryOffset()), console.info("Input height:", u._getInputHeight()), console.info("Input width:", u._getInputWidth()), console.info("Input channels:", u._getInputChannelCount()), console.info("Output memory offset:", u._getOutputMemoryOffset()), console.info("Output height:", u._getOutputHeight()), console.info("Output width:", u._getOutputWidth()), console.info("Output channels:", u._getOutputChannelCount()), o(u);
    }
    k();
  }, [n, a, s, e.model, e.backend, e.inputResolution]), {
    isSIMDSupported: s,
    tflite: l
  };
}
const No = /* @__PURE__ */ g(() => {
  const e = D(ue), [n, i] = G(), [a, t] = G({
    backend: "wasm",
    deferInputResizing: !0,
    inputResolution: "160x96",
    model: "meet",
    pipeline: "webgl2",
    // 60 introduces fps drop and unstable fps on Chrome
    // targetFps: 65,
    targetFps: 30
  }), [
    l
    /* setPostProcessingConfig */
  ] = G({
    blendMode: "screen",
    coverage: [0.5, 0.75],
    jointBilateralFilter: {
      sigmaColor: 0.1,
      sigmaSpace: 1
    },
    lightWrapping: 0.3,
    smoothSegmentationMask: !0
  }), {
    isSIMDSupported: o,
    tflite: s
  } = Gu(a);
  ee(() => {
    t((S) => S.backend === "wasm" && o ? {
      ...S,
      backend: "wasmSimd"
    } : S);
  }, [o]);
  const c = L(() => e.state === "background-blur-initializing" || e.state === "background-blur-ready" ? {
    type: "blur"
  } : e.state === "background-image-initializing" || e.state === "background-image-ready" ? {
    type: "image",
    url: e.backgroundImage
  } : {
    type: "none",
    url: void 0
  }, [e]), {
    backgroundImageRef: k,
    canvasRef: u,
    // durations: [resizingDuration, inferenceDuration, postProcessingDuration],
    // fps,
    pipeline: v
  } = Wu(
    n,
    c,
    a,
    // props.bodyPix,
    s
  );
  ee(() => {
    i(void 0);
  }, []), ee(() => {
    v && v.updatePostProcessingConfig(l);
  }, [v, l]);
  const r = _((S) => {
    const N = S.target;
    i({
      height: N.videoHeight,
      htmlElement: N,
      width: N.videoWidth
    }), Yd(u);
  }, [u]), p = L(() => ({
    // border: "1px solid red",
    position: "absolute",
    // zIndex: 99,
    visibility: "hidden",
    zIndex: -1
  }), []), m = L(() => Gd(e), [e]), F = e.state === "background-image-initializing" || e.state === "background-image-ready" ? e.backgroundImage : void 0;
  return /* @__PURE__ */ f("div", {
    style: p,
    children: [/* @__PURE__ */ d("img", {
      ref: k,
      height: n == null ? void 0 : n.height,
      width: n == null ? void 0 : n.width,
      alt: "",
      src: F
    }, e.state === "background-image-initializing" || e.state === "background-image-ready" ? e.backgroundImage : ""), /* @__PURE__ */ d(Mi, {
      muted: !0,
      onLoadedData: r,
      stream: m
    }), /* @__PURE__ */ d("canvas", {
      ref: u,
      height: n == null ? void 0 : n.height,
      width: n == null ? void 0 : n.width
    }, a.pipeline)]
  });
});
No.displayName = "VirtualBackgroundViewerInner";
const qu = /* @__PURE__ */ g(() => {
  const e = D(ue), n = e.state === "initial" || e.state === "video-track-ended" ? null : e.state === "background-blur-initializing" || e.state === "background-blur-ready" ? e.enabled ? "blur" : null : (e.state === "background-image-initializing" || e.state === "background-image-ready") && e.enabled ? `image-${e.backgroundImage}` : null;
  return n != null ? /* @__PURE__ */ d(No, {}, n) : null;
});
qu.displayName = "VirtualBackgroundViewer";
const Xu = /* @__PURE__ */ g(() => {
  const [e, n] = G(!1), i = me(), a = _(() => {
    hd();
  }, []), t = D(An), l = L(() => e || t === ce.Votes ? "white" : i.colors.gray[400], [e, t, i.colors.gray]), o = L(() => e || t === ce.Votes ? "rgba(255, 255, 255, .12)" : "transparent", [t, e]), s = D(xr);
  return /* @__PURE__ */ f(We, {
    _focus: {
      bg: o
    },
    _hover: {
      bg: o
    },
    _pressed: {
      bg: o
    },
    bg: o,
    borderRadius: "32",
    h: "48px",
    onHoverIn: () => n(!0),
    onHoverOut: () => n(!1),
    onPress: a,
    shadow: "1",
    w: "48px",
    children: [/* @__PURE__ */ d(Ma, {
      fill: l
    }), s > 0 ? /* @__PURE__ */ d(E, {
      alignItems: "center",
      backgroundColor: "red.100",
      borderRadius: "50%",
      color: "white",
      justifyContent: "center",
      position: "absolute",
      right: -5,
      top: -5,
      w: "2ch",
      children: /* @__PURE__ */ d(y, {
        fontSize: 10,
        children: s
      })
    }) : null]
  });
});
Xu.displayName = "ControlActiveVotesToggle";
const Yu = "_container_1tydh_1", Ku = "_panel_1tydh_11", Ju = "_openPanel_1tydh_1", Qu = "_header_1tydh_40", Zu = "_footer_1tydh_47", ek = "_body_1tydh_51", nk = "_title_1tydh_58", ik = "_buttonClose_1tydh_65", ak = "_label_1tydh_87", tk = "_group_1tydh_95", dk = "_input_1tydh_99", lk = "_inputFocused_1tydh_112", ok = "_inputWrap_1tydh_117", sk = "_inputEmpty_1tydh_128", rk = "_inputNotEmpty_1tydh_136", ck = "_inputAdd_1tydh_140", Se = {
  container: Yu,
  panel: Ku,
  openPanel: Ju,
  header: Qu,
  footer: Zu,
  body: ek,
  title: nk,
  buttonClose: ik,
  label: ak,
  group: tk,
  input: dk,
  inputFocused: lk,
  inputWrap: ok,
  inputEmpty: sk,
  inputNotEmpty: rk,
  inputAdd: ck
}, Wt = H.object({
  answers: H.array(H.object({
    value: H.string()
  })).min(2),
  // conditions: z
  //   .object({
  //     terminate: z
  //       .object({
  //         completion: z.boolean(),
  //         majority: z.boolean(),
  //         time: z.date(),
  //       })
  //       .strict(),
  //   })
  //   .strict(),
  title: H.string().min(1)
}).strict(), mk = /* @__PURE__ */ g(() => D(Dr) ? /* @__PURE__ */ d(fo, {}) : null);
mk.displayName = "CreateVote";
const fo = /* @__PURE__ */ g(() => {
  const {
    t: e
  } = te(), n = _(() => {
    Wn(!1);
  }, []), {
    control: i,
    formState: {
      errors: a,
      isSubmitting: t,
      isValid: l
    },
    handleSubmit: o,
    register: s,
    setValue: c
  } = Rn({
    defaultValues: {
      answers: [{
        value: ""
      }],
      /* conditions: { terminate: {} }, */
      title: ""
    },
    mode: "all",
    resolver: Zn(Wt)
  }), {
    append: k,
    fields: u,
    remove: v
  } = cd({
    control: i,
    name: "answers"
  }), [r, {
    error: p,
    loading: m
  }] = Mn(Sd), F = !l || t, S = _(async (N) => {
    const T = Wt.parse(N);
    await r({
      variables: {
        cases: T.answers.filter((b) => b.value.trim() !== "").map((b) => b.value),
        conditions: {
          terminate: {
            completion: !0,
            majority: !0
          }
        },
        multiple: !1,
        title: T.title
      }
    }), Wn(!1);
  }, [r]);
  return /* @__PURE__ */ f("div", {
    className: Se.container,
    children: [/* @__PURE__ */ f("div", {
      className: Se.panel,
      children: [/* @__PURE__ */ f("div", {
        className: Se.header,
        children: [/* @__PURE__ */ d("div", {
          className: Se.title,
          children: e("ROOM.NEW_VOTING", {
            defaultValue: "Новое голосование"
          })
        }), /* @__PURE__ */ d("button", {
          className: Se.buttonClose,
          onClick: n,
          type: "button",
          children: /* @__PURE__ */ d(_t, {
            height: "10",
            width: "10"
          })
        })]
      }), /* @__PURE__ */ f("div", {
        className: Se.body,
        children: [/* @__PURE__ */ f("div", {
          className: Se.group,
          children: [/* @__PURE__ */ d("div", {
            className: Se.label,
            children: e("LABEL.QUESTION", {
              defaultValue: "Вопрос"
            })
          }), /* @__PURE__ */ d("div", {
            ...s("title"),
            contentEditable: !0,
            className: Ie(Se.input),
            onInput: (N) => {
              c("title", N.currentTarget.textContent ?? "", {
                shouldDirty: !0,
                shouldTouch: !0,
                shouldValidate: !0
              });
            },
            tabIndex: -1
          })]
        }), /* @__PURE__ */ d("div", {
          className: Se.label,
          children: e("LABEL.ANSWERS_VARIANTS", {
            defaultValue: "Варианты ответа"
          })
        }), u.map((N, T) => /* @__PURE__ */ f("div", {
          className: Se.inputWrap,
          children: [/* @__PURE__ */ d("div", {
            ...s(`answers.${T}.value`),
            contentEditable: !0,
            className: Ie(Se.input),
            onFocus: (b) => {
              T === u.length - 1 && k({
                value: ""
              }, {
                shouldFocus: !1
              });
            },
            onInput: (b) => {
              c(`answers.${T}.value`, b.currentTarget.textContent ?? "", {
                shouldDirty: !0,
                shouldTouch: !0,
                shouldValidate: !0
              });
            },
            tabIndex: 0
          }), /* @__PURE__ */ d("button", {
            className: Se.buttonClose,
            onClick: () => v(T),
            type: "button",
            children: /* @__PURE__ */ d(_t, {
              height: "10",
              width: "10"
            })
          })]
        }, N.id))]
      }), /* @__PURE__ */ d("div", {
        className: Se.footer,
        children: /* @__PURE__ */ d(We, {
          bg: F ? "#19328F" : "blue.50",
          disabled: F,
          onPress: o(S),
          variant: "primary",
          children: /* @__PURE__ */ d(y, {
            color: "white",
            fontSize: "14",
            fontWeight: "500",
            lineHeight: "24",
            opacity: F ? 0.3 : 1,
            children: e("BUTTON.CREATE", {
              defaultValue: "Создать"
            })
          })
        })
      })]
    }), p ? /* @__PURE__ */ d(Qn, {
      error: p,
      onOk: n
    }) : null]
  });
});
fo.displayName = "CreateVotingContainer";
const Fo = /* @__PURE__ */ g(({
  vote: e
}) => {
  const {
    currentMeetSpaceMyPermissions: n,
    pending: i
  } = D({
    currentMeetSpaceMyPermissions: ln,
    pending: xt.pending
  }), a = !n.has(Z.PermissionChannelVoteControl), t = _(() => {
    xt(e);
  }, [e]);
  return /* @__PURE__ */ d(Ns, {
    size: "xs",
    children: /* @__PURE__ */ d(un, {
      disabledText: i ? "загружается" : e.terminated != null ? "Голосование уже закончено" : a ? "Нет прав" : void 0,
      onPress: t,
      children: "Завершить голосование"
    })
  });
});
Fo.displayName = "VoteListItemMenu";
const uk = "_container_b0y1t_1", kk = "_author_b0y1t_8", vk = "_authorAvatar_b0y1t_14", Sk = "_content_b0y1t_20", pk = "_control_b0y1t_26", Nk = "_label_b0y1t_32", fk = "_controlRadio_b0y1t_44", Fk = "_controlAside_b0y1t_57", gk = "_checkbox_b0y1t_60", hk = "_controlResult_b0y1t_64", bk = "_controlResultLine_b0y1t_74", yk = "_iconCheck_b0y1t_81", Tk = "_controlContent_b0y1t_93", Mk = "_footer_b0y1t_98", Ck = "_menu_b0y1t_105", oe = {
  container: uk,
  author: kk,
  authorAvatar: vk,
  content: Sk,
  control: pk,
  label: Nk,
  controlRadio: fk,
  controlAside: Fk,
  checkbox: gk,
  controlResult: hk,
  controlResultLine: bk,
  iconCheck: yk,
  controlContent: Tk,
  footer: Mk,
  menu: Ck
}, go = /* @__PURE__ */ g(({
  vote: e
}) => {
  var s;
  const n = e.cases.reduce((c, k) => c + k.count, 0), i = !e.terminated && e.cases.every((c) => !c.me), {
    data: a,
    error: t,
    loading: l
  } = Yn(wi, {
    variables: {
      id: e.member
    }
  }), o = a == null ? void 0 : a.meet.member.get;
  return /* @__PURE__ */ f("div", {
    className: oe.container,
    children: [/* @__PURE__ */ d("div", {
      className: oe.menu,
      children: /* @__PURE__ */ d(Fo, {
        vote: e
      })
    }), o != null ? /* @__PURE__ */ f("div", {
      className: oe.author,
      children: [/* @__PURE__ */ d("div", {
        className: oe.authorAvatar,
        children: /* @__PURE__ */ d(Fa, {
          h: "32px",
          source: {
            uri: ((s = o.user) == null ? void 0 : s.avatar.uri) ?? gi(o.id)
          },
          w: "32px"
        })
      }), /* @__PURE__ */ d(y, {
        color: "text.100",
        fontSize: "14",
        fontWeight: "500",
        lineBreakMode: "tail",
        lineHeight: "24",
        numberOfLines: 1,
        children: tn(o)
      })]
    }) : null, /* @__PURE__ */ f("div", {
      className: oe.content,
      children: [/* @__PURE__ */ d(y, {
        color: "white",
        display: "flex",
        fontSize: "14",
        fontWeight: "500",
        lineHeight: "18",
        mb: "3",
        children: e.title
      }), i ? /* @__PURE__ */ d(ho, {
        vote: e
      }) : /* @__PURE__ */ d(yo, {
        totalVotes: n,
        vote: e
      }), /* @__PURE__ */ f("div", {
        className: oe.footer,
        children: [n === 0 ? /* @__PURE__ */ d(y, {
          color: "text.100",
          fontSize: "12",
          fontWeight: "500",
          lineHeight: "20",
          children: "нет голосов"
        }) : null, /* @__PURE__ */ d(y, {
          color: "text.100",
          fontSize: "12",
          fontWeight: "500",
          lineHeight: "20",
          ml: "auto",
          children: Jo(Kt(e.created))
        })]
      })]
    }), /* @__PURE__ */ d(Qn, {
      error: t
    })]
  });
});
go.displayName = "VoteListItem";
const ho = /* @__PURE__ */ g(({
  vote: e
}) => /* @__PURE__ */ d(ye, {
  children: e.cases.map((n, i) => /* @__PURE__ */ d(bo, {
    index: i,
    multiple: e.multiple,
    text: n.name,
    voteId: e.id
  }, `${e.id}_${n.name}`))
}));
ho.displayName = "Inputs";
const bo = /* @__PURE__ */ g(({
  index: e,
  multiple: n,
  text: i,
  voteId: a
}) => {
  const t = _(() => $d({
    cases: [e],
    voteId: a
  }), [e, a]);
  return /* @__PURE__ */ f("label", {
    className: Ie(oe.control, oe.label),
    children: [/* @__PURE__ */ d("input", {
      name: "name1",
      onChange: t,
      onSelect: t,
      type: "radio"
    }), /* @__PURE__ */ d("div", {
      className: oe.controlAside,
      children: /* @__PURE__ */ d("div", {
        className: Ie(oe.controlRadio, n && oe.checkbox)
      })
    }), /* @__PURE__ */ d("div", {
      className: oe.controlContent,
      children: /* @__PURE__ */ d(y, {
        color: "gray.400",
        fontSize: "14",
        fontWeight: "500",
        lineHeight: "20",
        children: i
      })
    })]
  });
});
bo.displayName = "AnswerInput";
const yo = /* @__PURE__ */ g(({
  totalVotes: e,
  vote: n
}) => /* @__PURE__ */ d(ye, {
  children: n.cases.map((i) => {
    const a = i.count, t = e > 0 ? Math.floor(a / e * 100) : 0;
    return /* @__PURE__ */ f("div", {
      className: Ie(oe.control, oe.result),
      children: [/* @__PURE__ */ d("div", {
        className: oe.controlAside,
        children: /* @__PURE__ */ d(y, {
          color: "text.100",
          fontSize: "12",
          fontWeight: "500",
          lineHeight: "20",
          children: `${t}%`
        })
      }), /* @__PURE__ */ f("div", {
        className: oe.controlContent,
        children: [/* @__PURE__ */ d(y, {
          color: "gray.400",
          fontSize: "14",
          fontWeight: "500",
          lineHeight: "20",
          children: i.name
        }), /* @__PURE__ */ f("div", {
          className: oe.controlResult,
          children: [i.me ? /* @__PURE__ */ d(Ks, {
            className: oe.iconCheck,
            height: "12",
            width: "12"
          }) : null, /* @__PURE__ */ d("div", {
            className: oe.controlResultLine,
            style: {
              width: `${t}%`
            }
          })]
        })]
      })]
    }, i.name);
  })
}));
yo.displayName = "Results";
const wk = /* @__PURE__ */ g(() => {
  const {
    t: e
  } = te(), n = D(Hd), i = _(({
    item: a
  }) => /* @__PURE__ */ d(go, {
    vote: a
  }), []);
  return /* @__PURE__ */ f(mn, {
    flex: 1,
    children: [/* @__PURE__ */ d(ba, {
      bg: "gray.200",
      mb: 3,
      onPress: () => {
        Wn(!0);
      },
      children: e("ROOM.CREATE_VOTING", {
        defaultValue: "Создать голосование"
      })
    }), /* @__PURE__ */ d(ga, {
      ItemSeparatorComponent: To,
      data: n,
      flex: 1,
      keyExtractor: Ek,
      onEndReached: Ud,
      renderItem: i,
      updateCellsBatchingPeriod: 1
    })]
  });
});
wk.displayName = "VotesPanel";
const Ek = (e) => e.id, To = /* @__PURE__ */ g(() => /* @__PURE__ */ d(E, {
  style: _k.separator
}));
To.displayName = "FlatListItemSeparator";
const _k = _n.create({
  separator: {
    backgroundColor: ze.colors.black,
    height: 2,
    width: "100%"
  }
}), Rk = H.object({
  defaultCamera: Jr
}).merge(Eu), xk = Rk.partial(), Dk = qt((e) => e.map((n) => /* @__PURE__ */ d(hn, {
  value: n.deviceId,
  children: n.label
}, n.deviceId)), () => /* @__PURE__ */ d(jn, {
  size: "sm"
}), () => /* @__PURE__ */ d(jn, {
  size: "sm"
}), (e) => /* @__PURE__ */ d(y, {
  color: "red.100",
  children: String(e)
})), Mo = /* @__PURE__ */ g(({
  images: e,
  onChange: n,
  small: i,
  value: a
}) => {
  var c, k, u;
  const [t, l] = G(((c = a.defaultCamera) == null ? void 0 : c.type) ?? "simple");
  ee(() => {
    jd();
  }, []);
  const o = D(Br), s = _((v) => {
    const r = xk.parse(v);
    n({
      ...a,
      ...r
    });
  }, [n, a]);
  return /* @__PURE__ */ f(E, {
    marginY: 3,
    children: [/* @__PURE__ */ f(E, {
      children: [/* @__PURE__ */ d(y, {
        fontSize: 16,
        mb: 2,
        children: x("SETTINGS.CAMERA.TYPE_SELECTION_LABEL")
      }), /* @__PURE__ */ d(hn.Group, {
        name: "camera_type",
        onChange: (v) => {
          l(v);
        },
        size: 1,
        value: t,
        children: /* @__PURE__ */ f(Mt, {
          alignItems: {
            base: "flex-start",
            md: "center"
          },
          direction: {
            base: "column",
            md: "row"
          },
          mb: 4,
          space: 4,
          w: "100%",
          children: [/* @__PURE__ */ d(hn, {
            value: "simple",
            children: x("SETTINGS.CAMERA.SELECTION_TYPE_SIMPLE")
          }), /* @__PURE__ */ d(hn, {
            value: "by_id",
            children: x("SETTINGS.CAMERA.SELECTION_TYPE_DETAILED")
          })]
        })
      })]
    }), t === "simple" ? /* @__PURE__ */ f(Bn, {
      onValueChange: (v) => s({
        defaultCamera: {
          facing: v,
          type: "simple"
        }
      }),
      placeholder: "Выберите камеру",
      selectedValue: ((k = a.defaultCamera) == null ? void 0 : k.type) === "simple" ? a.defaultCamera.facing : void 0,
      children: [/* @__PURE__ */ d(Bn.Item, {
        label: x("SETTINGS.CAMERA.FACING_USER"),
        value: "user"
      }), /* @__PURE__ */ d(Bn.Item, {
        label: x("SETTINGS.CAMERA.FACING_ENVIRONMENT"),
        value: "environment"
      })]
    }) : /* @__PURE__ */ d(E, {
      borderBottomColor: "gray.500",
      borderBottomStyle: "solid",
      borderBottomWidth: "1px",
      pb: 2,
      children: /* @__PURE__ */ d(hn.Group, {
        name: "camera_id",
        onChange: (v) => {
          s({
            defaultCamera: {
              id: v,
              type: "by_id"
            }
          });
        },
        size: 1,
        value: ((u = a.defaultCamera) == null ? void 0 : u.type) === "by_id" ? a.defaultCamera.id : void 0,
        children: /* @__PURE__ */ d(Mt, {
          children: Dk(o)
        })
      })
    }), /* @__PURE__ */ d(qd, {
      images: e,
      onChange: s,
      small: i,
      value: a
    })]
  });
});
Mo.displayName = "SettingsCameraForm";
const Ak = /* @__PURE__ */ g(() => {
  const e = {}, [n, i] = G(an());
  ee(() => {
    async function t() {
      try {
        const l = await Oe.enumerateDevices();
        i(be(l.filter((o) => o.kind === "audioinput")));
      } catch {
        i(ka("Нет доступа к устройствам"));
      }
    }
    t();
  }, []);
  const a = pe((t) => t, [], n);
  return /* @__PURE__ */ f(fs, {
    children: [/* @__PURE__ */ d(Fs, {
      children: "Микрофон"
    }), /* @__PURE__ */ d(E, {
      children: /* @__PURE__ */ d(hn.Group, {
        accessibilityLabel: "default microphone",
        name: "myRadioGroup",
        onChange: (t) => {
        },
        size: "0.5",
        value: e.defaultCamera,
        children: a.map((t, l) => /* @__PURE__ */ d(gs, {
          label: t.label === "" ? `Микрофон ${l + 1}` : t.label,
          noBorder: a.length - 1 === l,
          selected: e.defaultMic === t.deviceId,
          value: t.deviceId
        }, t.deviceId))
      })
    })]
  });
});
Ak.displayName = "SettingsMicrophone";
const Ik = /* @__PURE__ */ g((e) => /* @__PURE__ */ d(E, {
  bg: "#141414",
  borderRadius: "12",
  bottom: "100%",
  display: {
    base: "none",
    lg: "flex"
  },
  left: "0",
  mb: "1",
  position: "absolute",
  shadow: "1",
  w: "300px",
  zIndex: 1e3,
  ...e
}));
Ik.displayName = "UsersListDesktop";
const Ok = /* @__PURE__ */ g(({
  linkForCopy: e,
  textForCopy: n
}) => {
  const {
    width: i
  } = En(), a = L(() => ({
    bg: "blue.50",
    borderRadius: {
      base: 0,
      lg: "24"
    },
    color: "white",
    justifyContent: {
      base: "center",
      lg: "flex-start"
    },
    width: {
      base: i,
      lg: "auto"
    }
  }), [i]);
  return /* @__PURE__ */ d(E, {
    flexDirection: "row",
    justifyContent: "flex-start",
    p: {
      lg: 5
    },
    children: /* @__PURE__ */ d(nd, {
      mobileView: "full",
      textForCopy: e,
      view: a,
      children: /* @__PURE__ */ d(y, {
        color: "white",
        fontSize: "14",
        fontWeight: "500",
        lineHeight: "24",
        children: n
      })
    })
  });
});
Ok.displayName = "UsersListFooter";
const Xn = /* @__PURE__ */ g(({
  audioDisabled: e,
  avatarUri: n,
  hideControls: i,
  isOrganizer: a,
  nickname: t,
  onAudioClick: l,
  onVideoClick: o,
  permissions: s = /* @__PURE__ */ new Set(),
  videoDisabled: c
}) => {
  const k = pa({
    nickname: t
  }), u = L(() => Sa(n, 48), [n]);
  return /* @__PURE__ */ d(he, {
    trigger: (v) => /* @__PURE__ */ f(le, {
      ...v,
      alignItems: "center",
      flexDirection: "row",
      h: "60px",
      px: {
        base: 2,
        lg: 4
      },
      py: "6px",
      children: [/* @__PURE__ */ d(Fa, {
        bg: "rgba(255, 255, 255, 0.08)",
        h: "48px",
        source: u,
        w: "48px",
        children: /* @__PURE__ */ d(y, {
          color: "text.100",
          fontSize: 16,
          fontWeight: 400,
          children: k.toLocaleUpperCase()
        })
      }), /* @__PURE__ */ f(E, {
        flex: "1",
        pl: "3",
        children: [/* @__PURE__ */ d(y, {
          isTruncated: !0,
          color: "gray.400",
          fontSize: "14",
          fontWeight: "500",
          lineHeight: "20px",
          noOfLines: 1,
          children: t
        }), a && /* @__PURE__ */ d(y, {
          isTruncated: !0,
          color: "text.100",
          fontSize: "14",
          fontWeight: "500",
          lineHeight: "20px",
          noOfLines: 1,
          children: "Организатор"
        })]
      }), !i && /* @__PURE__ */ f(ye, {
        children: [/* @__PURE__ */ d(ca, {
          Icon: Ta,
          IconDisabled: dd,
          disabled: e ?? !1,
          hasPermission: s.has(Z.PermissionChannelMediaAudioSend),
          onClick: l
        }), /* @__PURE__ */ d(ca, {
          Icon: Rs,
          IconDisabled: xs,
          disabled: c ?? !1,
          hasPermission: s.has(Z.PermissionChannelMediaVideoSend),
          onClick: o
        })]
      })]
    }),
    children: /* @__PURE__ */ f(he.Content, {
      children: [/* @__PURE__ */ d(he.Arrow, {}), /* @__PURE__ */ d(he.CloseButton, {}), /* @__PURE__ */ d(he.Header, {
        children: "Информация"
      }), /* @__PURE__ */ f(he.Body, {
        children: [/* @__PURE__ */ d(yn, {
          size: "sm",
          children: "Доступы"
        }), /* @__PURE__ */ d(Co, {
          permissions: s
        })]
      })]
    })
  });
});
Xn.displayName = "UsersListItemView";
const Co = /* @__PURE__ */ g(({
  permissions: e
}) => {
  const {
    t: n
  } = te(), i = Qo(Z);
  return /* @__PURE__ */ d(zn, {
    children: i.map((a) => /* @__PURE__ */ d(E, {
      children: /* @__PURE__ */ d(y, {
        children: `${e.has(a) ? "✅" : "❌"} ${n(`BACKEND.PERMISSIONS.${a}`)}`
      })
    }, a))
  });
});
Co.displayName = "PermissionsList";
const ca = /* @__PURE__ */ g(({
  disabled: e,
  hasPermission: n,
  Icon: i,
  IconDisabled: a,
  onClick: t
}) => {
  const l = me();
  return /* @__PURE__ */ d(E, {
    w: "92px",
    children: /* @__PURE__ */ d(Ti, {
      onPress: t,
      children: e || !n ? /* @__PURE__ */ d(a, {
        fill: n ? l.colors.gray[400] : l.colors.red[400],
        height: "16",
        opacity: n ? 1 : 0.4,
        width: "16"
      }) : /* @__PURE__ */ d(i, {
        fill: l.colors.gray[400],
        height: "16",
        width: "16"
      })
    })
  });
});
ca.displayName = "DeviceControl";
const wo = /* @__PURE__ */ g(({
  canControlUser: e,
  isOrganizer: n,
  member: i,
  peer: a
}) => {
  var r;
  const t = D(Di), [l, {
    error: o,
    loading: s
  }] = Mn(tr), c = o, k = L(() => Cd(t == null ? void 0 : t.permissions, i), [i, t == null ? void 0 : t.permissions]), u = _(async () => {
    e && !s && (k.has(Z.PermissionChannelMediaAudioSend) ? await l({
      variables: {
        id: a.id,
        permissions_deny: [Z.PermissionChannelMediaAudioSend]
      }
    }) : await l({
      variables: {
        id: a.id,
        permissions_grant: [Z.PermissionChannelMediaAudioSend]
      }
    }));
  }, [e, s, a.id, k, l]), v = _(async () => {
    e && !s && (k.has(Z.PermissionChannelMediaVideoSend) ? await l({
      variables: {
        id: a.id,
        permissions_deny: [Z.PermissionChannelMediaVideoSend]
      }
    }) : await l({
      variables: {
        id: a.id,
        permissions_grant: [Z.PermissionChannelMediaVideoSend]
      }
    }));
  }, [e, s, a.id, k, l]);
  return /* @__PURE__ */ f(ye, {
    children: [/* @__PURE__ */ d(Xn, {
      audioDisabled: (a.media.audio ?? Si.MediaAudioOff) === Si.MediaAudioOff,
      avatarUri: ((r = i.user) == null ? void 0 : r.avatar.uri) ?? gi(a.id),
      isOrganizer: n,
      nickname: tn(i),
      onAudioClick: u,
      onVideoClick: v,
      permissions: k,
      videoDisabled: (a.media.video ?? De.MediaVideoOff) === De.MediaVideoOff
    }), c != null ? /* @__PURE__ */ d(Qn, {
      error: c
    }) : null]
  });
});
wo.displayName = "MeetMemberActivePeer";
const Vk = /* @__PURE__ */ g(() => {
  const {
    width: e
  } = En(), {
    currentMeetSpaceMembersExceptMe: n,
    video: i
  } = D({
    currentMeetSpaceMembersExceptMe: Va,
    video: ue
  }), a = D(dn), {
    meSpeaking: t,
    peersSpeaking: l
  } = D({
    meSpeaking: co,
    peersSpeaking: mo
  });
  return /* @__PURE__ */ d(ha, {
    horizontal: !0,
    height: {
      base: 96,
      lg: 112
    },
    children: /* @__PURE__ */ f(Qe, {
      alignItems: "center",
      justifyContent: "center",
      minWidth: e,
      px: {
        base: "4px",
        lg: "8px"
      },
      space: {
        base: "4px",
        lg: "8px"
      },
      children: [(a == null ? void 0 : a.peer) != null ? /* @__PURE__ */ d(ma, {
        member: a.member,
        myStream: zr(i),
        peer: a.peer,
        speaking: t
      }) : null, n.slice(1).filter((o) => o.peer != null).map((o) => /* @__PURE__ */ d(ma, {
        member: o.member,
        peer: o.peer,
        speaking: l.ref.get(o.peer.id) ?? !1
      }, o.member.id))]
    })
  });
});
Vk.displayName = "UsersListMobileWithPreviews";
const ma = /* @__PURE__ */ g(({
  member: e,
  myStream: n,
  peer: i,
  speaking: a
}) => {
  var v;
  const t = kn(), l = (i.media.video ?? De.MediaVideoOff) !== De.MediaVideoOff, o = L(() => ({
    style: {
      ...ci.rtcView,
      ...t === "base" ? ci.rtcViewBase : (
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        t === "lg" ? ci.rtcViewLG : {}
      ),
      ...l ? {} : ci.hidden
    }
  }), [t, l]), s = n ?? i.stream, c = _(() => {
    e != null && Hi(e.id);
  }, [e]), k = ((v = e == null ? void 0 : e.user) == null ? void 0 : v.avatar.uri) ?? "", u = L(() => k !== "" ? k : gi((e == null ? void 0 : e.id) ?? i.id), [e == null ? void 0 : e.id, i.id, k]);
  return /* @__PURE__ */ f(Eo, {
    children: [/* @__PURE__ */ d(E, {
      borderColor: a ? "blue.50" : "gray.800",
      borderRadius: "12",
      borderStyle: "solid",
      borderWidth: a ? "2" : "1",
      bottom: 0,
      left: 0,
      opacity: 1,
      position: "absolute",
      right: 0,
      top: 0,
      zIndex: 2
    }), /* @__PURE__ */ f(le, {
      flex: "1",
      onPress: c,
      testID: n != null ? "ULPItem-Pressable-me" : "ULPItem-Pressable",
      children: [i.reaction.gesture === Tn ? /* @__PURE__ */ d(zi, {}) : null, /* @__PURE__ */ d(Mi, {
        muted: s == null,
        objectFit: "contain",
        stream: Ze.OS === "web" ? s : void 0,
        streamURL: Ze.OS !== "web" ? s == null ? void 0 : s.toURL() : void 0,
        videoProps: o
      }), l ? null : /* @__PURE__ */ d(_o, {
        alt: pa(e),
        uri: u
      }), /* @__PURE__ */ d(Ro, {
        children: n != null ? Zo(e) : tn(e)
      })]
    })]
  });
});
ma.displayName = "ULPItem";
const Eo = /* @__PURE__ */ g((e) => /* @__PURE__ */ d(E, {
  alignItems: "center",
  bg: "#141414",
  borderRadius: "6",
  height: "96px",
  justifyContent: "center",
  position: "relative",
  width: {
    base: "96px",
    lg: "172px"
  },
  ...e
}));
Eo.displayName = "ULPContainer";
const _o = /* @__PURE__ */ g(({
  alt: e,
  uri: n
}) => /* @__PURE__ */ d(bi, {
  alt: e,
  bg: "#141414",
  borderRadius: {
    base: 12,
    lg: 6
  },
  h: {
    base: "96px",
    lg: "56px"
  },
  source: {
    uri: n
  },
  testID: "Image",
  w: {
    base: "96px",
    lg: "56px"
  }
}));
_o.displayName = "ULPImage";
const Ro = /* @__PURE__ */ g((e) => /* @__PURE__ */ d(y, {
  bg: "rgba(0, 0, 0, 0.64)",
  borderRadius: "16",
  bottom: {
    base: 1,
    lg: 2
  },
  color: "gray.400",
  ellipsizeMode: "tail",
  h: {
    base: "20px",
    lg: "32px"
  },
  left: {
    base: 1,
    lg: 2
  },
  lineHeight: {
    base: "20px",
    lg: "24px"
  },
  numberOfLines: 1,
  position: "absolute",
  px: {
    base: "6px",
    lg: "12px"
  },
  py: {
    base: 0,
    lg: 1
  },
  testID: "nickname",
  textAlign: "center",
  w: {
    base: "88px",
    // base: "64px",
    lg: "156px"
    // lg: "120px",
  },
  ...e
}));
Ro.displayName = "ULPNickname";
const ci = _n.create({
  hidden: {
    display: "none"
  },
  rtcView: {
    flex: 1,
    minHeight: 0
  },
  rtcViewBase: {
    height: 96,
    width: 96
  },
  rtcViewLG: {
    height: 56,
    width: 56
  }
}), Pk = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "query",
    name: {
      kind: "Name",
      value: "MeetSpaceVanityNames"
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "space"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "vanity_name"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "list"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "VanityName"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "User"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "UserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "attributes"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "settings"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatar"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "premium"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "VanityName"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "VanityNameType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "name"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "owner"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "space_id"
        }
      }]
    }
  }]
}, RS = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "MeetSpaceVanityNameRemove"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "name"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "String"
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "space"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "vanity_name"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "remove"
                    },
                    arguments: [{
                      kind: "Argument",
                      name: {
                        kind: "Name",
                        value: "name"
                      },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "name"
                        }
                      }
                    }]
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
}, Lk = /* @__PURE__ */ g(() => {
  const e = D(Ra);
  return e.length > 0 ? /* @__PURE__ */ d(zn, {
    left: 0,
    position: "absolute",
    right: 0,
    top: 0,
    children: e.map((n) => /* @__PURE__ */ d(Ct, {
      left: 0,
      position: "absolute",
      right: 0,
      status: n.status,
      top: 0,
      zIndex: 1,
      children: /* @__PURE__ */ d(zn, {
        flexShrink: 1,
        space: 2,
        w: "100%",
        children: /* @__PURE__ */ f(Qe, {
          flexShrink: 1,
          justifyContent: "space-between",
          space: 2,
          children: [/* @__PURE__ */ f(Qe, {
            flexShrink: 1,
            space: 2,
            children: [/* @__PURE__ */ d(Ct.Icon, {
              mt: "1"
            }), /* @__PURE__ */ d(y, {
              color: "gray.800",
              fontSize: "md",
              children: n.message
            })]
          }), /* @__PURE__ */ d(os, {
            _focus: {
              borderWidth: 0
            },
            _icon: {
              color: "coolGray.600"
            },
            icon: /* @__PURE__ */ d(Qt, {
              size: "3"
            }),
            onPress: () => {
              Fd(n.id);
            },
            variant: "unstyled",
            zIndex: 3
          })]
        })
      })
    }, String(n.message)))
  }) : null;
});
Lk.displayName = "Alerts";
const Gi = /* @__PURE__ */ g(({
  active: e,
  Icon: n,
  onPress: i
}) => {
  const [a, t] = G(!1), l = L(() => a || e ? "white" : ze.colors.gray[400], [e, a]), o = L(() => a || e ? "rgba(255, 255, 255, .12)" : "transparent", [e, a]);
  return /* @__PURE__ */ d(We, {
    _focus: {
      bg: o
    },
    _hover: {
      bg: o
    },
    _pressed: {
      bg: o
    },
    bg: o,
    borderRadius: "32",
    h: "48px",
    onHoverIn: () => t(!0),
    onHoverOut: () => t(!1),
    onPress: i,
    shadow: "1",
    w: "48px",
    children: /* @__PURE__ */ d(n, {
      fill: l,
      height: 24,
      width: 24
    })
  });
});
Gi.displayName = "CallRoomControlButton";
const Uk = /* @__PURE__ */ g(() => {
  const e = D(Yc), n = _(() => {
    ei();
  }, []), i = D(An);
  return /* @__PURE__ */ d(Gi, {
    Icon: e ? Ds : td,
    active: i === ce.Chat,
    onPress: n
  });
});
Uk.displayName = "ControlChatToggle";
const $k = /* @__PURE__ */ g(() => {
  const e = _(() => {
    Pa();
  }, []), n = D(Vd);
  return /* @__PURE__ */ d(Gi, {
    Icon: As,
    active: n,
    onPress: e
  });
});
$k.displayName = "ControlReaction";
const Bk = /* @__PURE__ */ g(() => {
  const e = _(() => {
    bd();
  }, []), n = D(An);
  return /* @__PURE__ */ d(Gi, {
    Icon: Is,
    active: n === ce.Chat,
    onPress: e
  });
});
Bk.displayName = "ControlSettingsToggle";
const Hk = /* @__PURE__ */ g(() => {
  const e = D(Fe), [n, i] = G(!1), a = me(), t = D(An), l = _(() => {
    gd();
  }, []), o = L(() => n || t === ce.UserList ? "white" : a.colors.gray[400], [t, n, a.colors.gray]), s = L(() => n || t === ce.UserList ? "rgba(255, 255, 255, .12)" : "transparent", [t, n]);
  return /* @__PURE__ */ d(E, {
    position: "relative",
    children: /* @__PURE__ */ f(Jn, {
      alignItems: "center",
      bg: s,
      flexDirection: "row",
      height: "12",
      onHoverIn: () => i(!0),
      onHoverOut: () => i(!1),
      onPress: l,
      px: "4",
      width: "auto",
      children: [
        /* @__PURE__ */ d(Os, {
          fill: o,
          height: "24",
          width: "24"
        }),
        e.length > 0 ? /* @__PURE__ */ d(y, {
          color: o,
          fontSize: "14",
          fontWeight: "500",
          ml: "2",
          children: e.length
        }) : /* @__PURE__ */ d(y, {})
        /* пустой элемент чтобы не вылетала typescript ошибка */
      ]
    })
  });
});
Hk.displayName = "ControlUsersListToggle";
const zk = /* @__PURE__ */ g(({
  meetSpace: e
}) => {
  const {
    t: n
  } = te(), {
    onCopy: i
  } = ss(), a = rs(), t = _(() => (a.show({
    description: n("ROOM.ROOM_LINK_COPIED_TO_CLIPBOARD")
  }), i(Xt(e))), [e, i, n, a]);
  return /* @__PURE__ */ d(un, {
    onPress: t,
    children: n("ROOM.COPY_LINK")
  });
});
zk.displayName = "MenuItemCopyRoomLink";
const jk = /* @__PURE__ */ g(({}) => {
  const {
    t: e
  } = te();
  return /* @__PURE__ */ d(un, {
    onPress: Wk,
    children: e("ROOM.CREATE_VOTING", {
      defaultValue: "Создать голосование"
    })
  });
});
jk.displayName = "MenuItemCreateVote";
const Wk = () => {
  Wn(!0);
}, Gk = /* @__PURE__ */ g(() => {
  const {
    t: e
  } = te(), {
    currentMeetSpaceMyPermissions: n,
    isRecording: i
  } = D({
    currentMeetSpaceMyPermissions: ln,
    isRecording: kt
  }), a = _(() => ut(), []);
  return n.has(Z.PermissionChannelControl) ? /* @__PURE__ */ d(un, {
    onPress: a,
    children: e(i ? "ROOM.RECORDING.MENU_ITEM_STOP" : "ROOM.RECORDING.MENU_ITEM_START")
  }) : null;
});
Gk.displayName = "MenuItemMeetRecording";
const qk = /* @__PURE__ */ g(({}) => {
  const {
    t: e
  } = te();
  return /* @__PURE__ */ d(un, {
    onPress: Xk,
    children: e("ROOM.ADD_RESPONSE", {
      defaultValue: "Отправить отклик"
    })
  });
});
qk.displayName = "MenuItemSendReaction";
const Xk = () => {
  Pa();
}, gt = /* @__PURE__ */ g(({
  hasAudioEnabled: e,
  hasVideoEnabled: n,
  small: i
}) => {
  const a = me();
  return /* @__PURE__ */ f(mn, {
    bottom: i ? void 0 : 2,
    position: "absolute",
    right: "2",
    top: i ? 2 : void 0,
    children: [/* @__PURE__ */ d(E, {
      alignItems: "center",
      bg: "rgba(0, 0, 0, 0.64)",
      borderRadius: "16",
      h: "32px",
      justifyContent: "center",
      w: "32px",
      children: e ? /* @__PURE__ */ d(Ta, {
        fill: a.colors.gray[500],
        height: "16",
        width: "16"
      }) : /* @__PURE__ */ d(dd, {
        fill: a.colors.gray[500],
        height: "16",
        width: "16"
      })
    }), /* @__PURE__ */ d(E, {
      alignItems: "center",
      bg: "rgba(0, 0, 0, 0.64)",
      borderRadius: "16",
      h: "32px",
      justifyContent: "center",
      w: "32px",
      children: n ? /* @__PURE__ */ d(ad, {
        fill: a.colors.gray[500],
        height: "16",
        width: "16"
      }) : /* @__PURE__ */ d(Vs, {
        fill: a.colors.gray[500],
        height: "16",
        width: "16"
      })
    })]
  });
});
gt.displayName = "PeerMediaIndicators";
const ht = /* @__PURE__ */ g(({
  children: e,
  fullscreen: n,
  height: i,
  onPress: a,
  speaking: t,
  width: l
}) => /* @__PURE__ */ d(le, {
  flex: n ? 1 : void 0,
  height: n ? "auto" : i,
  onPress: a,
  p: {
    base: 1,
    lg: 2
  },
  testID: "VideoContainer",
  width: n ? "auto" : l,
  children: /* @__PURE__ */ f(yi, {
    bg: "#141414",
    borderRadius: "12",
    flex: "1",
    position: "relative",
    zIndex: "3",
    children: [/* @__PURE__ */ d(E, {
      borderColor: t ? "blue.50" : "gray.800",
      borderRadius: "12",
      borderStyle: "solid",
      borderWidth: t ? "2" : "1",
      bottom: "0",
      left: "0",
      position: "absolute",
      right: "0",
      top: "0"
    }), e]
  })
}));
ht.displayName = "VideoContainer";
const Yk = /* @__PURE__ */ g(({
  cardHeight: e,
  cardWidth: n,
  enableVideoRendering: i,
  fullscreen: a,
  hasAudioEnabled: t,
  hasVideoEnabled: l,
  hideConnectionDetails: o,
  isHidden: s,
  memberId: c,
  peerId: k,
  reaction: u,
  small: v = !1,
  speaking: r,
  stream: p
}) => {
  var b;
  const {
    data: m
  } = Yn(wi, {
    skip: c == null,
    variables: {
      id: c
    }
  }), F = m == null ? void 0 : m.meet.member.get, S = _(() => {
    c != null && Hi(c);
  }, [c]), N = L(() => ({
    style: {
      ...mi.rtcView,
      ...l ? {} : mi.hidden
    }
  }), [l]), T = tn(F);
  return /* @__PURE__ */ f(ht, {
    fullscreen: a ?? !1,
    height: e,
    onPress: S,
    speaking: r,
    width: n,
    children: [(u == null ? void 0 : u.gesture) === Tn ? /* @__PURE__ */ d(zi, {}) : null, l ? null : /* @__PURE__ */ d(id, {
      nickname: T,
      uri: (b = F == null ? void 0 : F.user) == null ? void 0 : b.avatar.uri
      /* ?? makeGravatarUri(peerId) */
      // showName={!isMe}
    }), i && /* @__PURE__ */ d(
      Mi,
      {
        objectFit: "contain",
        stream: Ze.OS === "web" ? p : void 0,
        streamURL: Ze.OS !== "web" ? p.toURL() : void 0,
        style: [mi.rtcView, !l || s ? mi.hidden : void 0],
        videoProps: N
      }
    ), l && /* @__PURE__ */ d(E, {
      bottom: "2",
      flexDirection: "row",
      justifyContent: "center",
      left: v ? 0 : "48px",
      position: "absolute",
      right: v ? 0 : "48px",
      zIndex: "3",
      children: /* @__PURE__ */ d(y, {
        bg: "rgba(0, 0, 0, .64)",
        borderRadius: "32",
        color: "gray.400",
        ellipsizeMode: "tail",
        fontSize: "14",
        fontWeight: "500",
        lineHeight: "24",
        maxWidth: "100%",
        numberOfLines: 1,
        px: "4",
        py: "1",
        children: T
      })
    }), /* @__PURE__ */ d(Ft, {
      peerId: k,
      showDetails: !o && (l || t),
      small: v
    }), /* @__PURE__ */ d(gt, {
      hasAudioEnabled: t,
      hasVideoEnabled: l,
      small: v
    })]
  });
});
Yk.displayName = "PeerCard";
const mi = _n.create({
  hidden: {
    display: "none"
  },
  rtcView: {
    height: "100%",
    width: "100%"
  }
}), Kk = /* @__PURE__ */ g(({
  avatarUri: e,
  cardHeight: n,
  cardWidth: i,
  enableVideoRendering: a,
  fullscreen: t,
  hasAudioEnabled: l,
  hasVideoEnabled: o,
  hideConnectionDetails: s,
  isHidden: c,
  myPeer: k,
  nickname: u,
  small: v = !1,
  speaking: r,
  stream: p
}) => {
  const {
    t: m
  } = te(), F = me(), S = _(() => {
    (k == null ? void 0 : k.id) != null && Hi(k.id);
  }, [k == null ? void 0 : k.id]), N = L(() => ({
    style: {
      ...$n.rtcView,
      ...o ? {} : $n.hidden
    }
  }), [o]), T = u !== "" ? u : m("ROOM.ANONYMOUS_USER"), b = L(() => [$n.rtcView, !o || c ? $n.hidden : void 0], [o, c]);
  return /* @__PURE__ */ f(ht, {
    fullscreen: t ?? !1,
    height: n,
    onPress: S,
    speaking: r,
    width: i,
    children: [(k == null ? void 0 : k.reaction.gesture) === Tn ? /* @__PURE__ */ d(zi, {}) : null, o ? null : /* @__PURE__ */ d(id, {
      nickname: T,
      small: v,
      uri: e
      // showName={!isMe}
    }), a && /* @__PURE__ */ d(Mi, {
      muted: !0,
      objectFit: "contain",
      stream: Ze.OS === "web" ? p : void 0,
      streamURL: Ze.OS !== "web" ? p.toURL() : void 0,
      style: b,
      videoProps: N
    }), o && // <Text
    //   color="white"
    //   fontSize={{
    //     base: 20,
    //     lg: 24,
    //   }}
    //   fontWeight={500}
    //   lineHeight={24}
    //   m={6}
    //   textAlign="center"
    // >
    //   {nickname1}
    // </Text>
    /* @__PURE__ */ f(E, {
      bottom: "2",
      flexDirection: "row",
      justifyContent: "center",
      left: "48px",
      position: "absolute",
      right: "48px",
      zIndex: "3",
      children: [(k == null ? void 0 : k.reaction.gesture) === Tn ? /* @__PURE__ */ d(E, {
        mr: "2",
        children: /* @__PURE__ */ d(Ma, {
          fill: F.colors.gray[500],
          height: "16",
          style: $n.iconRaisedHand,
          width: "16"
        })
      }) : null, /* @__PURE__ */ d(y, {
        bg: "rgba(0, 0, 0, .64)",
        borderRadius: "32",
        color: "gray.400",
        ellipsizeMode: "tail",
        fontSize: "14",
        fontWeight: "500",
        lineHeight: "24",
        maxWidth: "100%",
        numberOfLines: 1,
        px: "4",
        py: "1",
        children: T
      })]
    }), /* @__PURE__ */ d(Ft, {
      showDetails: !s && (l || o),
      small: v
    }), /* @__PURE__ */ d(gt, {
      hasAudioEnabled: l,
      hasVideoEnabled: o,
      small: v
    })]
  });
});
Kk.displayName = "SelfPeerCard";
const $n = _n.create({
  hidden: {
    display: "none"
  },
  iconRaisedHand: {
    marginRight: 8
  },
  rtcView: {
    aspectRatio: 1,
    height: "100%",
    minHeight: 0,
    minWidth: 0,
    objectFit: "contain",
    transform: [{
      scaleX: -1
    }],
    width: "100%"
  }
}), xo = /* @__PURE__ */ g(({
  isOrganizer: e,
  member: n
}) => {
  var i;
  return /* @__PURE__ */ d(Xn, {
    hideControls: !0,
    avatarUri: ((i = n.user) == null ? void 0 : i.avatar.uri) ?? gi(n.id),
    isOrganizer: e,
    nickname: tn(n)
  });
});
xo.displayName = "MeetMemberInactive";
const Jk = /* @__PURE__ */ g(() => {
  var p;
  const {
    t: e
  } = te(), n = hi(), {
    anonymousNickname: i,
    audio: a,
    currentMeetSpace: t,
    currentMeetSpaceMembersExceptMe: l,
    currentMeetSpaceMyPermissions: o,
    meetSpaceUsersListData: s,
    video: c
  } = D({
    anonymousNickname: Na,
    audio: Te,
    currentMeetSpace: Di,
    currentMeetSpaceMembersExceptMe: Va,
    currentMeetSpaceMyPermissions: ln,
    meetSpaceUsersListData: Su,
    video: ue
  });
  ee(() => {
    Bt({
      distanceFromEnd: 5
    });
  }, []);
  const k = L(() => [{
    data: [n != null ? {
      me: n,
      type: "me"
    } : {
      anonymousNickname: i,
      type: "me_anon"
    }, ...l.filter((m) => m.member.presence_state === zo.MemberPresenceStateOnline && m.peer != null).map((m) => ({
      member: m.member,
      peer: m.peer,
      type: "peer"
    }))],
    title: `${e("ROOM.USERSLIST.MEMBERS_COUNT", {
      defaultValue: "Участников"
    })} (${l.length + 1})`
  }, {
    data: s.map((m) => ({
      member: m,
      type: "not_on_call"
    })),
    title: "Оффлайн"
  }], [n, i, l, e, s]), u = o.has(Z.PermissionChannelMediaControl), v = _(({
    item: m
  }) => {
    var F, S, N, T, b;
    return m.type === "me" ? /* @__PURE__ */ d(Xn, {
      audioDisabled: !a.enabled,
      avatarUri: m.me.avatar.uri,
      isOrganizer: m.me.id === ((F = t == null ? void 0 : t.owner) == null ? void 0 : F.id),
      nickname: m.me.nickname,
      onAudioClick: () => {
      },
      permissions: o,
      videoDisabled: c.state === "initial" || c.state === "video-track-ended" || !c.enabled
    }, m.me.id) : m.type === "me_anon" ? /* @__PURE__ */ d(Xn, {
      audioDisabled: !a.enabled,
      avatarUri: "",
      isOrganizer: !1,
      nickname: m.anonymousNickname ?? "",
      onAudioClick: () => {
      },
      permissions: o,
      videoDisabled: c.state === "initial" || c.state === "video-track-ended" || !c.enabled
    }, "anon") : m.type === "peer" ? /* @__PURE__ */ d(wo, {
      canControlUser: u,
      isOrganizer: ((S = m.member.user) == null ? void 0 : S.id) === ((N = t == null ? void 0 : t.owner) == null ? void 0 : N.id),
      member: m.member,
      peer: m.peer
    }, m.peer.id) : /* @__PURE__ */ d(xo, {
      isOrganizer: ((T = t == null ? void 0 : t.owner) == null ? void 0 : T.id) === ((b = m.member.user) == null ? void 0 : b.id),
      member: m.member
    });
  }, [a, u, (p = t == null ? void 0 : t.owner) == null ? void 0 : p.id, o, c]), r = L(() => ({
    currentMeetSpaceMembershipInfo: t
  }), [t]);
  return /* @__PURE__ */ d(E, {
    flex: "1",
    flexDirection: "column",
    py: "5",
    children: /* @__PURE__ */ d(cs, {
      extraData: r,
      keyExtractor: Qk,
      onEndReached: (m) => {
        Bt(m);
      },
      renderItem: v,
      renderSectionHeader: ({
        section: m
      }) => /* @__PURE__ */ d(y, {
        color: "text.100",
        fontSize: "14",
        fontWeight: "500",
        lineHeight: "24",
        my: "2",
        px: "4",
        children: m.title
      }),
      sections: k
    })
  });
});
Jk.displayName = "UsersList";
const Qk = (e, n) => ((e.type === "me" ? e.me.id : e.type === "me_anon" ? e.anonymousNickname : e.type === "not_on_call" ? e.member.id : e.peer.id) ?? "empty") + n;
var Me = "/src/processes/meet/index.ts";
const bn = h({
  loc: {
    file: Me,
    line: 44,
    column: 2
  },
  name: "eventUserSubscriptionAuthenticationChanged",
  sid: "-e8o6dl:/src/processes/meet/index.ts:eventUserSubscriptionAuthenticationChanged"
}), Do = h({
  loc: {
    file: Me,
    line: 46,
    column: 52
  },
  name: "eventUserSubscriptionMyPeerIdUpdated",
  sid: "b43djc:/src/processes/meet/index.ts:eventUserSubscriptionMyPeerIdUpdated"
});
Mr.on(Do, (e, n) => be(n));
const Ao = h({
  loc: {
    file: Me,
    line: 51,
    column: 49
  },
  name: "eventUserSubscriptionContactAdded",
  sid: "-98cxqg:/src/processes/meet/index.ts:eventUserSubscriptionContactAdded"
}), Io = h({
  loc: {
    file: Me,
    line: 52,
    column: 51
  },
  name: "eventUserSubscriptionContactRemoved",
  sid: "ji6wlc:/src/processes/meet/index.ts:eventUserSubscriptionContactRemoved"
});
w({
  and: [{
    clock: Hn,
    filter: (e) => e.status === va.Success,
    target: wa
  }],
  or: {
    loc: {
      file: Me,
      line: 54,
      column: 0
    },
    sid: "-lmsyem:/src/processes/meet/index.ts:"
  }
});
Jt.on(bn, (e, n) => n);
hu.on(Ao, (e, n) => [...e, n].sort((i, a) => i.nickname.localeCompare(a.nickname))).on(Io, (e, n) => e.filter((i) => i.id !== n));
const Zk = B(() => ne().subscribe({
  query: fu
}).subscribe({
  error: (e) => {
    bn(ka(e));
  },
  next: (e) => {
    var n, i, a, t, l;
    e.data != null && (((n = e.data.user.authentication) == null ? void 0 : n.anonymous) != null && bn(be({
      type: "anonymous"
    })), ((i = e.data.user.authentication) == null ? void 0 : i.authenticated) != null && bn(be({
      type: "authenticated",
      user: e.data.user.authentication.authenticated.user
    })), e.data.user.init != null && Do(e.data.user.init.peer_id), ((a = e.data.user.contact) == null ? void 0 : a.add) != null && Ao(e.data.user.contact.add), ((t = e.data.user.contact) == null ? void 0 : t.remove) != null && Io(e.data.user.contact.remove), ((l = e.data.user.meet) == null ? void 0 : l.space) != null);
  },
  start: () => {
    bn(Bo());
  }
}), {
  loc: {
    file: Me,
    line: 73,
    column: 39
  },
  name: "subscribeToUserEventsFx",
  sid: "-q802y8:/src/processes/meet/index.ts:subscribeToUserEventsFx"
}), xS = $o(Zk.doneData, null, {
  loc: {
    file: Me,
    line: 116,
    column: 33
  },
  name: "$userSubscription",
  sid: "gu2fbt:/src/processes/meet/index.ts:$userSubscription"
}), Oo = B(() => ne().query({
  fetchPolicy: "network-only",
  query: Fu
}).then((e) => e.data.user.meet.list), {
  loc: {
    file: Me,
    line: 121,
    column: 37
  },
  name: "fetchUserMeetSpacesFx",
  sid: "-lsu3gk:/src/processes/meet/index.ts:fetchUserMeetSpacesFx"
});
w({
  and: [{
    clock: bn,
    filter: (e) => pe((n) => n.type === "authenticated", !1, e),
    target: Oo
  }],
  or: {
    loc: {
      file: Me,
      line: 126,
      column: 0
    },
    sid: "-jts7lo:/src/processes/meet/index.ts:"
  }
});
Oa.on(Oo.doneData, (e, n) => be({
  ref: pe((i) => (n.spaces.forEach((a) => {
    i.ref.set(a.space.id, a.space);
  }), i.ref), /* @__PURE__ */ new Map(), e)
}));
const ev = w({
  and: [{
    clock: xa,
    source: {
      currentMeetSpaceMemberMe: dn
    },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    fn: (e, n) => {
      var i;
      return ((i = n.remove) == null ? void 0 : i.members) != null ? n.remove.members.find((a) => {
        var t, l;
        return a.id === ((l = (t = e.currentMeetSpaceMemberMe) == null ? void 0 : t.member) == null ? void 0 : l.id);
      }) ?? null : null;
    }
  }],
  or: {
    loc: {
      file: Me,
      line: 147,
      column: 44
    },
    name: "eventSubscriptionDisconnectReceived",
    sid: "3ugf1v:/src/processes/meet/index.ts:eventSubscriptionDisconnectReceived"
  }
}).filter({
  fn: Cn
});
w({
  and: [{
    clock: ev,
    source: {
      audio: Te,
      peers: Fe,
      rtcPeerConnection: J,
      video: ue
    },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    fn(e, n) {
      return {
        ...e,
        exitReason: nv(n.source)
      };
    },
    target: Ue
  }],
  or: {
    loc: {
      file: Me,
      line: 163,
      column: 0
    },
    sid: "-hz93sz:/src/processes/meet/index.ts:"
  }
});
w({
  and: [{
    clock: pi,
    source: {
      audio: Te,
      peers: Fe,
      rtcPeerConnection: J,
      video: ue
    },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    fn(e, n) {
      return {
        ...e,
        exitReason: n
      };
    },
    target: Ue
  }],
  or: {
    loc: {
      file: Me,
      line: 181,
      column: 0
    },
    sid: "-h29g7b:/src/processes/meet/index.ts:"
  }
});
function nv(e) {
  return e.ban != null ? {
    message: e.ban.reason,
    type: Ke.Ban
  } : e.kick != null ? {
    message: e.kick.reason,
    type: Ke.Kick
  } : e.conflict != null ? {
    message: e.conflict.device_id != null ? x("ROOM.EXIT_REASON.DEVICE_CONFLICT") : e.conflict.user_id != null ? x("ROOM.EXIT_REASON.USER_CONFLICT") : x("ROOM.EXIT_REASON.GENERIC_DISCONNECT"),
    type: Ke.DisconnectedFromBackend
  } : {
    message: x("ROOM.EXIT_REASON.GENERIC_DISCONNECT"),
    type: Ke.DisconnectedFromBackend
  };
}
export {
  xr as $activeVotesCount,
  Ra as $alerts,
  An as $asidePanel,
  Te as $audio,
  lc as $audioMode,
  dc as $audioPending,
  $l as $chatContextMenuOpened,
  Yc as $chatHasNewMessages,
  El as $chatMessages,
  Sn as $connectionState,
  dS as $controlsOpened,
  Di as $currentMeetSpace,
  dn as $currentMeetSpaceMe,
  ni as $currentMeetSpaceMembers,
  Va as $currentMeetSpaceMembersExceptMe,
  ln as $currentMeetSpaceMyPermissions,
  Nn as $currentMessage,
  xn as $currentSpaceId,
  ro as $data,
  Ii as $devices,
  Ei as $fatalErrors,
  Fl as $fileAttachments,
  Qa as $fileAttachmentsInternal,
  hS as $focusedPeer,
  On as $iceConnectionState,
  Xa as $iceGatheringState,
  Ya as $iceServers,
  SS as $isOffering,
  kt as $isRecording,
  Dr as $isVoteCreateFormVisible,
  xc as $linkQuality,
  oS as $loading,
  co as $meSpeaking,
  CS as $meetSpaceUserListCursor,
  Su as $meetSpaceUsersListData,
  Oa as $meetSpacesBySpaceId,
  Ia as $meetSpacesMembershipBySpaceId,
  Ad as $meetSubscriptionStatus,
  Jc as $messageDeleteId,
  Hr as $microphoneDevices,
  hu as $myContacts,
  Mr as $myPeerId,
  Od as $peerIdByTrackId,
  Wi as $peerStats,
  Fe as $peers,
  mo as $peersSpeaking,
  yu as $peersWithActiveAudioTrack,
  Ui as $receivedMessages,
  Vl as $receivedMessagesEditedAt,
  pr as $roomExitReason,
  Vd as $roomReactionPanelOpened,
  Er as $roomReactions,
  J as $rtcPeerConnection,
  fi as $selectedBackgroundType,
  Ua as $selectedCamera,
  ac as $selectedMicrophone,
  tc as $selectedSpeakers,
  FS as $sendingMessage,
  al as $showNewAudioDeviceSelect,
  cr as $showRateACallModal,
  kS as $showVirtualBackgroundCanvas,
  In as $signalingState,
  uS as $speakerDevices,
  wl as $unconfirmedMessages,
  xS as $userSubscription,
  ue as $video,
  Br as $videoDevices,
  Zr as $videoIsOn,
  el as $videoPending,
  Hd as $votes,
  Oi as $webrtcInitialized,
  vS as $webrtcUserFriendlyConnectionStatus,
  Lk as Alerts,
  ce as AsidePanelItem,
  Fi as AttachedFile,
  bu as AudioDeviceChangedModal,
  Ar as CallsView,
  Mm as ChatAddAttachments,
  jm as ChatBody,
  st as ChatBodyPanel,
  Pc as ChatDeleteMessageDocument,
  Vc as ChatEditMessageDocument,
  la as ChatItemText,
  oa as ChatItemTime,
  Gm as ChatMessageDelete,
  zl as ChatMessageEditPreview,
  Pv as ChatMessageEmbedFragmentDoc,
  Vv as ChatMessageEmbedPreviewFragmentDoc,
  Uv as ChatMessageFragmentDoc,
  ct as ChatMessageItem,
  qm as ChatMobileHead,
  Ym as ChatPanel,
  Km as ChatPanelHead,
  Lc as ChatReactToMessageDocument,
  At as ChatSendMessageDocument,
  Uc as ChatUnreactToMessageDocument,
  dt as ContextMenuOverlay,
  Xu as ControlActiveVotesToggle,
  NS as ControlAudio,
  fS as ControlCamera,
  Dc as ControlChangeCamera,
  Uk as ControlChatToggle,
  Ir as ControlHangup,
  Ac as ControlMode,
  $k as ControlReaction,
  Oc as ControlScreenShare,
  Bk as ControlSettingsToggle,
  Hk as ControlUsersListToggle,
  mk as CreateVote,
  La as DEFAULT_CAMERA,
  Ld as EMOJI_LIFETIME,
  Jm as EditMeetSpace,
  so as EditShortMeetSpaceSettings,
  Ov as EmbeddedChatMessageFragmentDoc,
  wi as FetchMemberByIdDocument,
  jl as FilePicker,
  ou as FlyingReactions,
  jv as IceServersFragmentDoc,
  mt as InviteToCallButton,
  Qm as InviteToCallCard,
  Ne as LinkQuality,
  Kv as MeeetEventSpaceFragmentDoc,
  ar as MeetDocument,
  $v as MeetEventChatFragmentDoc,
  Lv as MeetEventChatMessageEditFragmentDoc,
  Hv as MeetEventMemberFragmentDoc,
  Wv as MeetEventRtcFragmentDoc,
  qv as MeetEventSelfFragmentDoc,
  eS as MeetEventUserFragmentDoc,
  iS as MeetEventVoteFragmentDoc,
  sr as MeetFetchVotesDocument,
  wo as MeetMemberActivePeer,
  Bv as MeetMemberFragmentDoc,
  Gv as MeetMemberSpaceFragmentDoc,
  ud as MeetMySpacesListDocument,
  MS as MeetRecordingDocument,
  ru as MeetRecordingStartDocument,
  cu as MeetRecordingStopDocument,
  TS as MeetRecordingsListDocument,
  kd as MeetSpaceByIdOrVanityNameDocument,
  Xv as MeetSpaceConditionsContactsFragmentDoc,
  Ke as MeetSpaceExitReason,
  Yv as MeetSpaceFragmentDoc,
  Zv as MeetSpaceLeaveDocument,
  tr as MeetSpaceMemberControlDocument,
  Pr as MeetSpaceMinHeightLG,
  iu as MeetSpaceReaction,
  Qv as MeetSpaceRemoveDocument,
  mu as MeetSpaceRuntimeSettings,
  vd as MeetSpaceSaveDocument,
  or as MeetSpaceTerminateDocument,
  RS as MeetSpaceVanityNameRemoveDocument,
  Pk as MeetSpaceVanityNamesDocument,
  dr as MeetUpdateRtcReactionDocument,
  rr as MeetVoteCastDocument,
  nS as MeetVoteFragmentDoc,
  Sd as MeetVoteStartDocument,
  aS as MeetVoteTerminateDocument,
  zk as MenuItemCopyRoomLink,
  jk as MenuItemCreateVote,
  Gk as MenuItemMeetRecording,
  su as MenuItemRaiseHand,
  qk as MenuItemSendReaction,
  ot as MessageInputPanel,
  Ql as MessagesList,
  lr as MyMeetCalendarDocument,
  $r as PasswordModal,
  Yk as PeerCard,
  gt as PeerMediaIndicators,
  zi as RaiseHandPopup,
  Nu as RateACallModal,
  yS as RecordingFragmentDoc,
  bS as RecordingTranscriptFragmentDoc,
  ir as RequestChatHistoryDocument,
  Vr as RoomChatWidth,
  Or as RoomControlsHeight,
  Yi as RoomReactionHeight,
  zv as RtcMemberFragmentDoc,
  Kk as SelfPeerCard,
  Xr as SendAnswerDocument,
  Gr as SendIceCandidateDocument,
  qr as SendOfferDocument,
  Mo as SettingsCameraForm,
  Ak as SettingsMicrophone,
  P as SourceAudioTrackType,
  Kr as SourceAudioTracks,
  Ft as StatsPopover,
  Xd as UpdateMyMediaStatusDocument,
  fu as UserDocument,
  Fu as UserMeetSpacesDocument,
  Jk as UsersList,
  Ik as UsersListDesktop,
  Ok as UsersListFooter,
  Xn as UsersListItemView,
  Vk as UsersListMobileWithPreviews,
  Jv as VanityNameFragmentDoc,
  ht as VideoContainer,
  qu as VirtualBackgroundViewer,
  go as VoteListItem,
  wk as VotesPanel,
  Be as WebRTCConnectionStatus,
  ml as addIceCandidateFx,
  on as audioStateReducerFx,
  Eu as backgroundSettingsSchema,
  $d as castVoteFx,
  Vi as closeRTCPeertConnectionFx,
  yr as closeSubscriptionFx,
  Jr as defaultCameraSchema,
  at as deleteMessageFx,
  xt as endVoteFx,
  Nr as eventAsidePanelClosed,
  Ba as eventAudioModeChanged,
  Ha as eventAudioTrackEnded,
  Nd as eventCallRatedSuccessfully,
  lS as eventCallViewFullToggle,
  et as eventChatMessageReacted,
  Tl as eventChatMessageUnreacted,
  it as eventChatScrollEndReached,
  Ul as eventCloseContextMenu,
  ur as eventControlsToggle,
  Dl as eventCurrentMesageChanged,
  ia as eventCurrentMesageCursorPositionChanged,
  Al as eventCurrentMesageEmojiInserted,
  zd as eventDeviceListChanged,
  jd as eventDeviceListInitialized,
  kr as eventError,
  Li as eventFileAttachmentsAdded,
  vl as eventFileAttachmentsRemoved,
  Sl as eventFileAttachmentsReset,
  pl as eventFileAttachmentsUploadFailed,
  Nl as eventFileAttachmentsUploadFinished,
  fl as eventFileAttachmentsUploadProgressUpdated,
  Ea as eventGraphqlSubscriptionComplete,
  mr as eventGraphqlSubscriptionStarted,
  Fd as eventHideAlert,
  Bt as eventLoadMoreMeetUsers,
  Ud as eventLoadMoreVotes,
  St as eventMeSpeakingUpdate,
  fe as eventMeetSpaceCleanup,
  fd as eventMeetSpaceExitFinished,
  ke as eventMeetSpaceJoinRequested,
  wa as eventMeetSpaceJoinedSuccessfully,
  pi as eventMeetSpaceLeave,
  pd as eventMeetSpaceRestoreCall,
  tS as eventMeetSpaceTerminate,
  Rd as eventMeetSubscriptionChatReceived,
  xa as eventMeetSubscriptionMemberReceived,
  wd as eventMeetSubscriptionRtcIceCandidateReceived,
  Da as eventMeetSubscriptionRtcInitReceived,
  xi as eventMeetSubscriptionRtcMemberReceived,
  br as eventMeetSubscriptionRtcMidiEventsReceived,
  Ed as eventMeetSubscriptionRtcSDPReceived,
  hr as eventMeetSubscriptionSelfInitReceived,
  Ri as eventMeetSubscriptionSelfReceived,
  xd as eventMeetSubscriptionSpaceReceived,
  Hn as eventMeetSubscriptionStatusUpdated,
  _d as eventMeetSubscriptionVoteReceived,
  Hi as eventMemberFocused,
  Hl as eventMessageDeleteReset,
  Bl as eventMessageDeleteSelected,
  bl as eventMessageEditReset,
  gl as eventMessageEditSelected,
  hl as eventMessageReplySelected,
  nl as eventMicrophoneIconPressed,
  qa as eventNegotiationNeeded,
  _a as eventNewAlert,
  za as eventNewAudioDeviceSelected,
  il as eventNewAudioDeviceSelectionCancelled,
  Tc as eventOfferingStateUpdated,
  aa as eventOpenContextMenu,
  Wa as eventRTCConnectionStateUpdated,
  Ga as eventRTCIceConnectionStateUpdated,
  rl as eventRTCIceGatheringStateUpdated,
  sl as eventRTCSignalingStateUpdated,
  Ll as eventResetChatHasNewMessages,
  Kc as eventResetContextMenu,
  Dd as eventRestartSubscription,
  Cr as eventRoomReactionPanelReset,
  Pa as eventRoomReactionPanelToggle,
  wr as eventRoomReactionReset,
  Pd as eventRoomReactionUpdate,
  Jd as eventScreenSharingIconPressed,
  Cl as eventSendMessage,
  ic as eventSourceAudioElementUpdated,
  nc as eventSourceTrackUpdated,
  vt as eventSpeakingUpdate,
  ft as eventStatsUpdated,
  xl as eventSubscriptionChatEditMessagesReceived,
  qc as eventSubscriptionChatReactionCountReceived,
  Xc as eventSubscriptionChatReactionSelfReceived,
  _l as eventSubscriptionChatRemoveMessagesReceived,
  Rl as eventSubscriptionChatSentMessagesReceived,
  Qd as eventSwitchToNextCamera,
  Kd as eventToggleCameraOnOff,
  ei as eventToggleChat,
  Pl as eventToggleChatHasNewMessages,
  ut as eventToggleRecording,
  bd as eventToggleSettings,
  gd as eventToggleUsersList,
  hd as eventToggleVotes,
  bn as eventUserSubscriptionAuthenticationChanged,
  Ao as eventUserSubscriptionContactAdded,
  Io as eventUserSubscriptionContactRemoved,
  Do as eventUserSubscriptionMyPeerIdUpdated,
  $a as eventVideoTrackEnded,
  Yd as eventVirtualBackgroundCreated,
  Wn as eventVoteCreateFormVisible,
  _i as fetchMeetSpaceByIdOrVanityNameFx,
  Gn as fetchMessagesFromHistoryFx,
  Oo as fetchUserMeetSpacesFx,
  Ai as fetchVotesFx,
  kl as formatMessage,
  Gd as getCameraStreamFromVideoState,
  gr as getRoomVariant,
  gS as getShowModalInitialState,
  zr as getVirtualStreamFromVideoState,
  Ue as hangupFx,
  Cd as mergePermissions,
  rS as postVoteFx,
  ul as processSDPFx,
  Pi as recreateRTCPeerConnectionAfterReconnectFx,
  cl as restartGraphQLConnectionFx,
  Vn as sendChatMessageFx,
  Mc as sendIceCandidateFx,
  yl as sendMessageReactionFx,
  Ml as sendMessageUnreactionFx,
  nt as sendMessageUploadFilesFx,
  na as sendOfferFx,
  vr as subscribeToFatalError,
  Id as subscribeToMeetFx,
  Zk as subscribeToUserEventsFx,
  Zd as switchToNextCameraFx,
  sS as terminateMeetSpaceFx,
  Ja as updateAVStateFx,
  mS as useCardDimensions,
  _S as useConnectionStats,
  pS as useIsFullyConnected,
  ES as useMeSpeakingAnalyzer,
  cS as useMeetScreenDimensions,
  wS as useSpeakingAnalyzer,
  Ge as videoStateReducerFx
};
